import React from 'react';
import { Col, Row } from 'reactstrap';


export default function AvailableDetails() {
    return (
        <Row>
            <br />
            <Col sm={5} className="mt-4 mt-lg-0">
                <h5 className="toposhop_info_box-title">
                    Openingstijden</h5>

                <p><strong>Maandag-Vrijdag</strong> : 08.30 tot 16.00u<br />
                    <strong>Zaterdag-Zondag</strong> : Gesloten
                    <br />
                    <strong>Bezoekadres</strong>:<br />Mon Plaisir 104, 4879 AT  Etten-Leur, Nederland<br /></p>
            </Col>
            <Col sm={5} className="mt-4 mt-lg-0">
                <h5 className="toposhop_info_box-title">
                Email / Telefoon</h5>
                <p><strong>Email</strong> : info@toposchoonmaak.nl<br />
                    <strong>Telefonisch</strong> : +31 (0)76 30  339 80<br />
                    <strong>KvK</strong> : 81194153<br />
                    <strong>BTW nr.</strong> : NL861980426B01<br />
                    </p>
            </Col>
        </Row>            
    );
}
