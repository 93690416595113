import React from "react";
import { Col, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";

export default class MainUserContactClass extends React.Component {


    state = {
        id: this.props.contactdata.id,
        firstName: this.props.contactdata.firstName,
        lastName: this.props.contactdata.lastName,
        email: this.props.contactdata.email,
        phone: this.props.contactdata.phone,
        validate: {
            emailState: ''
        },
    };


    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }

    handleChange(newState) {
        this.setState(newState, () => {
            if (this.props.onChanged)
                this.props.onChanged(this.state);
        });
    }

    render() {
        return (
            <Form>
                <FormGroup row>
                    <Label for="firstname" sm={2}>Voornaam</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="firstname"
                            value={this.state.firstName}
                            onChange={event => this.handleChange({ firstName: event.target.value })}
                            placeholder="Voornaam" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="lastname" sm={2}>Achternaam</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="lastname"
                            value={this.state.lastName}
                            onChange={event => this.handleChange({ lastName: event.target.value })}
                            placeholder="Achternaam" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="email" sm={2}>Email</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="email"
                            value={this.state.email}
                            placeholder="Email" disabled />
                        <FormFeedback invalid>
                            Email veld mag niet leeg zijn en dient ook te voldoen aan de specificaties van een email adres.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="phone" sm={2}>Telefoon</Label>
                    <Col sm={6}>
                        <Input type="text" id="phone" value={this.state.phone}
                            onChange={(event) => { this.handleChange({ phone: event.target.value })} } placeholder="Telefoon nummer of mobiel nummer" />
                    </Col>
                </FormGroup>
                <br />
            </Form>
        )
    }
}
