import React, { useState, useEffect, useCallback } from 'react';
import { Col, Carousel, CarouselItem } from 'reactstrap';
import Axios from '../../context/axios';
import topofront from '../../assets/images/banners/NewTopoFront.jpg';
import './frontpage.css';

const init = async (setItems) => {
    try {
        let getUrl = `/api/product/sliders`;
        const response = await Axios(getUrl);
        if (response.status === 200) {
            if (response.data.length > 0) {
                //console.log("Updating list with images received");
                setItems(response.data);
                //console.log(response.data);
            } else {
                console.log("No images received");
            }              
        }
    } catch (e) {
        console.error("Error fetching data:", e);
    }
}

function TopSlider(props) {
    const userDetails = JSON.parse(sessionStorage.getItem("tokens"));
    const [items, setItems] = useState([{ id: 100, Image: topofront, Description: 'Slide 1', Title: 'Slide 1' }]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    useEffect(() => {
        init(setItems);
    }, []);

    const next = useCallback(() => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }, [animating, activeIndex, items.length]);

    const previous = useCallback(() => {
        if (animating) return;
        const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(prevIndex);
    }, [animating, activeIndex, items.length]);

    const slides = items.map((item) => (
        <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.id}
        >
            <img src={item.image} width="100%" height="550px" className="custom-rounded-img" alt={item.name} />
        </CarouselItem>
    ));

    return (
        <Col className="custom-col">
            {userDetails &&
                <h4>Welkom (terug) <strong>{userDetails.firstName}</strong> bij TOPO. We zijn u graag van dienst.</h4>
            }
            {(items.length > 1) &&
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    ride="carousel" // Enables the auto-play behavior
                    interval={5000} // Delays cycling in milliseconds
                    className="carousel-fade custom-col" 
                >
                    {slides}
                </Carousel>    
            }
            {(items.length === 1) &&
                 <img src={topofront} width="100%" height="50%" className="custom-rounded-img" alt="TOPO" />
            }
        </Col>
    );
}

export default React.memo(TopSlider);
