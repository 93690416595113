import React, { useState, useEffect } from "react";
import { Col, Row, Label, Input } from 'reactstrap';
import MainUserContact from '../MainUserContact';
import Axios from "../../../context/axios";

export default function AdminSessionsList() {
    //const [sessions, setSessions] = useState("");
    const [prefSessions, setPrefSessions] = useState([]);
    const [prefContactDetails, setprefContactDetails] = useState([]);
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [evn] = useState("24.03.1");
    
    useEffect(() => {
        async function init() {
            try {
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl1 = '/api/prefadmin/admmuc/' + xd;
                const response1 = await Axios.get(runUrl1, axiosConfig);
                if (response1.status === 200) {
                    setprefContactDetails(response1.data);
                }

                let runUrl2 = '/api/prefadmin/admses/' + xd;
                const response2 = await Axios.get(runUrl2, axiosConfig);
                if (response2.status === 200) {
                    setPrefSessions(response2.data);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);

    // Functions belonging to : SessionsList
    function ProtectedFilteredSessions(data) {
        let result = [];
        if (data) {
            result = data;
        }
        return result;
    }

    const filteredSessions = ProtectedFilteredSessions(prefSessions);
   
    return (
        <div>
            <Col>
                <Row>
                    <MainUserContact cactdata={prefContactDetails} />
                </Row>
            </Col>
            <Col>
                <Row>
                    <br />
                </Row>
                <Row>
                    <hr />
                </Row>
                
            </Col>
            <Col>
                <Row>
                    <p>Uw active sessie code : {userDetails.jwttoken.substr(userDetails.jwttoken.length - 15)}</p>
                </Row>
            </Col>
            <Col>
                <Row >
                    <Col md={2}><Label><b>IP Adres</b></Label></Col>
                    <Col md={4}><Label><b>Sessie Code</b></Label></Col>
                    <Col md={3}><Label><b>Aangemaakt</b></Label></Col>
                    <Col md={3}><Label><b>Verloopt</b></Label></Col>
                </Row>
                {filteredSessions.map((sl) => (
                    <Row key={sl.id}>
                        <Col md={2}><Input type="text" value={sl.createdByIp} disabled /></Col>
                        <Col md={4}><Input type="text" value={sl.token} disabled /></Col>
                        <Col md={3}><Input type="text" value={sl.created} disabled /></Col>
                        <Col md={3}><Input type="text" value={sl.expires} disabled /></Col>
                    </Row>
                ))
                }
            </Col>
            <Col>
                <Row>
                    <br />
                </Row>
                <Row>
                    <br />
                    <hr />
                    <br />
                </Row>
            </Col>
            <Col>
                <Row>
                    <p><b>WebShop v4.3</b> (build March 2024 / Engine {`${evn}`})</p>
                </Row>
            </Col>
        </div>
    );
}
