import React from 'react';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';
import { FcGoogle, FcPicture } from "react-icons/fc";

export default class MaintainProductDetailClass extends React.Component {
    state = {
        id: Number(this.props.editProduct.id),
        plu: this.props.editProduct.plu,
        name: this.props.editProduct.name,
        description: this.props.editProduct.description,
        brand: this.props.editProduct.brand,
        price: this.props.editProduct.price,
        category: this.props.editProduct.category,
        subcategory: this.props.editProduct.subcategory,
        promotion: (this.props.editProduct.promotion === 1 ? "1" : "2"),
        promotionprice: this.props.editProduct.promotionprice,
        producttype: this.props.editProduct.productType,
        defaultsku: this.props.editProduct.defaultsku,
        active: (this.props.editProduct.active === 1 ? "1" : "2"),
        instock: (this.props.editProduct.inStock === 1 ? "1" : "2"),
        sortingorder: (this.props.editProduct.sortingOrder),
        errors: {
            name: '',
            brand: '',
            price: ''
        },
        isFormValid: false
    }

    handleChange = (newState) => {
        if (newState !== undefined) {
            this.setState(newState, () => {
                this.validateForm();
                if (this.props.onChanged)
                    this.props.onChanged(this.state);
            });
        } else {
            this.validateForm();
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.handleChange({ [name]: value });
    }

    validateForm = () => {
        const { name, brand, price } = this.state;
        let errors = {};
        let isValid = true;

        if (!name || !name.trim()) {
            errors.name = 'Naam is een verplicht veld. Voer aub een naam in.';
            isValid = false;
        }

        if (!brand || !brand.trim()) {
            errors.brand = 'Merk is een verplicht veld. Voer aub een merk in.';
            isValid = false;
        }

        if (!price) {
            errors.price = 'Prijs is een verplicht veld';
            isValid = false;
        } else {
            // Price format validation (dot as decimal separator)
            const priceRegex = /^\d+(?:.\d{1,2})?$/;
            if (!priceRegex.test(price)) {
                errors.price = 'Voer een correcte prijs in (gebruik punt als decimaalteken, bijv. 10.99)';
                isValid = false;
            }
        }

        this.setState({ errors, isFormValid: isValid });
    }

    render() {
        let originalString = this.props.editProduct.name;
        let searchString = (originalString !== undefined ? originalString.replace(/ /g, '+') : "");
        let searchUrl = "https://www.google.com/search?q=" + searchString;

        return (
            <Form>
                <FormGroup row className="slimFormRow">
                    <Label for="plu" sm={3}>Artikel Nummer</Label>
                    <Col sm={6}>
                        <Input type="text" id="plu" name="plu" value={this.state.plu}
                            onChange={this.handleInputChange}
                            placeholder="Artikel Nummer" />
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="name" sm={3}>Naam {" => "}  
                        <a href={searchUrl} target="_blank" rel="noopener noreferrer">Zoeken op Google{" "}<FcGoogle /><FcPicture /></a>
                    </Label>
                    <Col sm={6}>
                        <Input type="text" id="name" name="name" value={this.state.name}
                            onChange={this.handleInputChange}
                            placeholder="Naam" />
                        {this.state.errors.name && <div className="text-danger">{this.state.errors.name}</div>}
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="description" sm={3}>Omschrijving</Label>
                    <Col sm={6}>
                        <Input type="textarea" id="description" name="description" value={this.state.description}
                            onChange={this.handleInputChange}
                            placeholder="Omschrijving" />
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="brand" sm={3}>Merk</Label>
                    <Col sm={6}>
                        <Input type="text" id="brand" name="brand" value={this.state.brand}
                            onChange={this.handleInputChange}
                            placeholder="Merk" />
                        {this.state.errors.brand && <div className="text-danger">{this.state.errors.brand}</div>}
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="price" sm={3}>Prijs</Label>
                    <Col sm={3}>
                        <Input
                            type="text"
                            id="price"
                            name="price"
                            value={this.state.price}
                            onChange={this.handleInputChange}
                            placeholder="Prijs (bijv. 10.99)"
                            required
                        />
                        {this.state.errors.price && <div className="text-danger">{this.state.errors.price}</div>}
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="category" sm={3}>Categorie</Label>
                    <Col sm={6}>
                        <Input type="select" id="category" name="category" value={this.state.category}
                            onChange={this.handleInputChange}
                            placeholder="Categorie">
                            <option value="">Alle</option>
                            <option value="Afvalzakken">Afvalzakken</option>
                            <option value="Diensten">Diensten</option>
                            <option value="Machines">Machines</option>
                            <option value="Materialen">Materialen</option>
                            <option value="Middelen">Middelen</option>
                            <option value="Occasion">Occasion</option>
                            <option value="Pads">Pads</option>
                            <option value="Papier">Papier</option>
                            <option value="Onderdelen">Onderdelen</option>
                            <option value="Dispensers">Dispensers</option>
                         </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="promotion" sm={3}>Aanbieding</Label>
                    <Col sm={3}>
                        <Input type="select" id="promotion" name="promotion" value={this.state.promotion}
                            onChange={this.handleInputChange} placeholder="Aanbieding" >
                            <option value="1">Ja</option>
                            <option value="2">Nee</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="producttype" sm={3}>Product Type</Label>
                    <Col sm={3}>
                        <Input type="select" id="producttype" name="producttype" value={this.state.producttype}
                            onChange={this.handleInputChange} placeholder="Product Type" >
                            <option value="Product">Product</option>
                            <option value="Renting">Verhuur</option>
                            <option value="Service">Diensten</option>
                            <option value="Occasion">Occasion</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="active" sm={3}>Product actief?</Label>
                    <Col sm={3}>
                        <Input type="select" id="active" name="active" value={this.state.active}
                            onChange={this.handleInputChange} placeholder="Actief" >
                            <option value="1">Ja</option>
                            <option value="2">Nee</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="sortingorder" sm={3}>Sorteervolgorde</Label>
                    <Col sm={3}>
                        <Input type="select" id="sortingorder" name="sortingorder" value={this.state.sortingorder}
                            onChange={this.handleInputChange} placeholder="Sorteervolgorde" >
                            <option value="10">Bovenaan (10)</option>
                            <option value="9">Heel Hoog</option>
                            <option value="8">Best Hoog</option>
                            <option value="7">Meer Aandacht(7)</option>
                            <option value="6">Aandacht</option>
                            <option value="5">Hoog gemiddeld (5)</option>
                            <option value="4">Gemiddeld</option>
                            <option value="3">Minder gemiddeld</option>
                            <option value="2">Lager (3)</option>
                            <option value="1">Laag</option>
                            <option value="0">Onderaan (0)</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="instock" sm={3}>Voorrading?</Label>
                    <Col sm={3}>
                        <Input type="select" id="instock" name="instock" value={this.state.instock}
                            onChange={this.handleInputChange} placeholder="Voorrading" >
                            <option value="1">Ja</option>
                            <option value="2">Nee</option>
                        </Input>
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}