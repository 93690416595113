import React, { useState, useEffect } from "react";
import { Col, Row, Table, TabContent, NavLink, Nav, NavItem, TabPane, Spinner, Button, Label, Input, Collapse } from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';
import Axios from '../../context/axios';
import { MdContentCopy, MdShoppingCart, MdPeople } from "react-icons/md";
import { FiMap, FiHome, FiUser, FiArchive, FiList, FiSettings } from "react-icons/fi";
import { ToastContainer, toast } from 'react-toastify';
import MainUserAddress from './MainUserAddress';
import MainUserCustomer from './MainUserCustomer';
import MainUserContact from './MainUserContact';
import classnames from 'classnames';
//import jwt from "jsonwebtoken";

export default function MainUserPreferences() {
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [portalOverride, setPortalOverride] = useState(JSON.parse(sessionStorage.getItem("portalswitch")));
    const [prefAddresses, setPrefAddresses] = useState([]);
    const [prefCustomerDetails, setprefCustomerDetails] = useState([]);
    const [prefContactDetails, setprefContactDetails] = useState([]);
    const [prefOrders, setprefOrders] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [copiedOrderList, setCopiedOrderList] = useState(false);
    const [copiedOrder, setCopiedOrder] = useState(false);
    const [loading, setLoading] = useState(true);
    const [infoX, setInfoX] = useState("");
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    // Tokenrefresh... an example.
    // https:/ / stackoverflow.com / questions / 51646853 / automating - access - token - refreshing - via - interceptors -in -axios / 66288792#66288792

    useEffect(() => {
        async function init() {
            try {
                setLoading(false);

                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl = '/api/prefuser/' + xd;
                const response = await Axios.get(runUrl, axiosConfig);
                if (response.status === 200) {
                    setResponseData(response.data);
                    setLoading(false);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);


    useEffect((e) => {
        setLoading(true);
        if (responseData !== undefined) {
            
            setPrefAddresses(responseData.addresses);
            setprefCustomerDetails(responseData.customer);
            setprefContactDetails(responseData.contact);
            setprefOrders(responseData.historicalOrders);
            setOrderList(responseData.templateOrders);
            setInfoX(responseData.info);
            setLoading(false);           
        }
    }, [responseData]);


    async function updateQuantity(e, id, sku, quantity, oldquantity) {
        const elementsIndex = orderList.findIndex(element => element.id === id);
        const newArray = [...orderList];
        newArray[elementsIndex] = { ...newArray[elementsIndex], items: Number(e.target.value) };
        setOrderList(newArray);
    }

    async function onCopyListToBasket(e) {
        setCopiedOrderList(true);
        let chkCount = orderList.reduce((total, line) => total + line.items, 0);
        if (chkCount > 0) {
            let jsonList = JSON.stringify({ orderList });
            var data = {
                Order: jsonList,
                SessionId: sessionStorage.sessionId,
                Action: 'O'
            }
            callOrderFunction('Bestellijst', data);
        }
        setCopiedOrderList(false);
    }

    async function onCopyToBasket(e) {
        setCopiedOrder(true);
        let orderNo = e.target.value;

        if (orderNo !== undefined) {
            console.log(`Copy order ${orderNo} to cart.`);

            var data = {
                Order: orderNo,
                SessionId: sessionStorage.sessionId,
                Action: 'H'
            }
            callOrderFunction(`Historische order ${orderNo}`, data);

        } else {
            setCopiedOrder(false);
        }
        setCopiedOrder(false);
    }



    function activatePortalSwitch(portalAbbrevation) {
        if (portalAbbrevation === "G") {
            sessionStorage.setItem('portal', 'G');
            setPortalOverride('G');
            <Navigate to='/gportal' replace />
        }
        if (portalAbbrevation === "A") {
            setPortalOverride('A');
            <Navigate to='/aportal' replace />
        }
    }

    async function callOrderFunction(name, data) {
        try {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
            let sessionId = sessionStorage.sessionId;

            if ((userDetails !== null)) {
                let actionname = "gekopieerd";

                sessionStorage.sessionId = sessionId;
                let result = await Axios.post('/api/cart/order', data, axiosConfig);
                if (result.status === 200) {
                    sessionStorage.sessionId = result.data.sessionId;
                    toast.info(`${name} ${actionname}.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                } else {
                    toast.error(`${name} niet ${actionname}.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            }
        } catch (ex) {
            console.log(ex);
            let actionname = "Probleem bij het kopieeren van";
            toast.error(`${actionname} ${name}.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        }
    };


    function FavoritesList() {
        return (

            <div>
                <Col>
                    <Row className="sidefilter">
                        <p>Voer de aantallen in bij uw favoriete artikelen en druk dan op de "Bestellen" knop. Hiermee worden de artikelen waar er meer dan 1 van zijn geselecteerd doorgezet naar het winkelmand.<br />Supersnel bestellen als u regelmatig hetzelfde of met net andere aantallen bestelt.</p>
                    </Row>
                    <Row className="sidefilter">
                        <Button color="primary" disabled={copiedOrderList} onClick={(e) => { onCopyListToBasket(e) }} >Bestellen</Button>
                        {(copiedOrderList === true) && <Button color="danger" onClick={(e) => { setCopiedOrderList(false) }}>Reset</Button>}
                    </Row>
                    <Row >
                        {(copiedOrderList === true) && <p><br />De orderlijst is gekopieerd. Ververs het scherm als u nogmaals een kopie van de bestellijst wil doen.</p>}
                        <br />
                    </Row>
                    <Row >
                        <Col md={3}><Label><b>Artikel No</b></Label></Col>
                        <Col md={4}><Label><b>Product Naam</b></Label></Col>
                        <Col md={2}><Label><b>Prijs in EUR</b></Label></Col>
                        <Col md={2}><Label><b>Aantal</b></Label></Col>
                    </Row>
                    {orderList.map((ol) => (
                        <Row key={ol.id}>
                            <Col md={3}><Input type="text" value={ol.plu} disabled /></Col>
                            <Col md={4}><Input type="text" value={ol.name} disabled /></Col>
                            <Col md={2}><Input className="text-md-right" type="text" value={`${Number(ol.price).toFixed(2)}`} disabled /></Col>
                            <Col md={2}>
                                <Input className="text-md-right" type="number" name="quantity" value={ol.items}
                                    onChange={(e) => updateQuantity(e, ol.id, ol.artNo)}
                                />
                            </Col>
                        </Row>
                    ))
                    }
                </Col>
            </div>
        );
    }

    const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default

    // Functions belonging to : HistoricalGroupOrdersList
    function ProtectedReduceItems(data) {
        let result = [];
        if (data) { result = data.reduce((total, item) => total + item.items, 0); }
        return result;
    }

    function ProtectedReduceTotals(data) {
        let result = [];
        if (data) { result = data.reduce((total, item) => total + item.total, 0); }
        return result;
    }

    function ProtectedFilteredOrders(data) {
        let result = [];
        if (data) {
            result = data
        }
        return result;
    }

    const filteredOrders = ProtectedFilteredOrders(prefOrders);
    const numItemsInSelection = ProtectedReduceItems(filteredOrders);
    const TotalCostItemsInSelection = ProtectedReduceTotals(filteredOrders);
   
    function HistoricalOrdersList() {
        return (
            <div>
                <Row>
                    <p>Hieronder vindt U de laatste bestellingen die bij TOPO zijn gedaan. Klik op de regel voor de details van de bestelling.<br />Achter iedere regel staat een knop waarmee u de bestelling inhoud kunt inkijken.</p>
                </Row>
                <Row >
                    <Col md={2}><Label><b>Order Nummer</b></Label></Col>
                    <Col md={3}><Label><b>Besteldatum</b></Label></Col>
                    <Col md={1}><Label><b>Artikel(en)</b></Label></Col>
                    <Col md={2}><Label><b>Totaal in EUR</b></Label></Col>
                    <Col md={3}><Label><b>Acties</b></Label></Col>

                </Row>
                {filteredOrders.map((ord) => (
                    <Row key={ord.id}>
                        <Col md={2}><Label>{ord.orderNumber}</Label></Col>
                        <Col md={3}><Label>{ord.orderDate}</Label></Col>
                        <Col md={1}>{ord.items}</Col>
                        <Col md={2}><Label>{Number(ord.total).toFixed(2)}</Label></Col>
                        <Col md={3}><Button color="primary" size="sm" onClick={() => {
                            if (Number(ord.id) === toggleQuestion) { setToggequestion(0); }
                            else { setToggequestion(Number(ord.id)); }
                        }}>{toggleQuestion !== Number(ord.id) ? "Toon details" : "Verberg details"}</Button>{" "}
                            <Button color="warning" size="sm" value={ord.orderNumber} disabled={copiedOrder} onClick={(e) => { onCopyToBasket(e) }} ><MdContentCopy /> naar <MdShoppingCart /></Button>
                        </Col>

                        <Collapse className="orderdetails" isOpen={toggleQuestion === Number(ord.id) ? true : false}>
                            <Col >

                                <Table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>PLU / Naam</th>
                                            <th>Aantal</th>
                                            <th>Prijs</th>
                                            <th>Totaal in EUR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ord.orderLine.map((item) => (
                                            <tr key={item.id} >
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{`${Number(item.items).toFixed(0)}`}</td>
                                                <td>{`${Number(item.price).toFixed(2)}`}</td>
                                                <td>{`${Number(item.subtotal).toFixed(2)}`}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <p>
                                    {(ord.notes.length > 1) && < i ><b>Order notities:</b><br />{ord.notes}</i>}
                                    <br />
                                    <i><b>Besteld door:</b> {ord.orderer}<br />
                                        <b>Factuuradres:</b> {ord.orderBilling}<br />
                                        <b>Bezorgadres:</b> {ord.orderDelivery}</i></p><br />
                            </Col>
                        </Collapse>
                    </Row>
                ))}
                
                <Row >
                    <Col md={2}></Col>
                    <Col md={2}><Label><b>Totalen over alle orders</b></Label></Col>
                    <Col md={1}></Col>
                    <Col md={1}><Label><b>{`${Number(numItemsInSelection).toFixed(0)}`}</b></Label></Col>
                    <Col md={2}><Label><b>{`${Number(TotalCostItemsInSelection).toFixed(2)}`} EUR</b></Label></Col>
                    <Col md={2}></Col>
                </Row>
            </div>
        );
    }



    function UserPortal() {

        return (

            <Col >

                <Nav tabs className="navtabsleft">
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} ><b><FiList />{" "}Bestellijst</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiArchive />{" "}Bestellingen</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} ><b><FiMap />{" "}Adressen</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }} ><b><FiHome />{" "}Facturatie gegevens</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }} ><b><FiUser />{" "}Contact</b></NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" className="colcontainer">
                        <Row>
                            <Col>
                                {(orderList) ?
                                    <Row>
                                        <FavoritesList />
                                    </Row>
                                    :
                                    <Row>
                                        < br />
                                        <h6>U heeft geen bestellijst, wilt u dat we er een voor uw aanmaken? Neemt U dan contact met ons op.</h6>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2" className="colcontainer">
                        <Row>
                            <Col>
                                {(prefOrders) ?
                                    <Row>
                                        <HistoricalOrdersList />
                                    </Row>
                                    :
                                    <Row>
                                        <div>
                                            < br />
                                            <h5>Geen eerdere bestellingen. Heeft u al eens bij ons besteld ? </h5>
                                        </div>
                                        <div>
                                            <Link to="/products"><Button color="primary">Zoeken op producten in de TOPO shop</Button></Link>
                                        </div>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3" className="colcontainer">
                        <Row>
                            <Col>

                                {(prefAddresses) ?
                                    <Row>
                                        < br />
                                        <MainUserAddress adrdata={prefAddresses} />
                                    </Row>
                                    :
                                    <Row>
                                        < br />
                                        <h5>Geen voorkeurs adressen. Heeft u al eens bij ons besteld ?</h5>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="4" className="colcontainer">
                        <Row>
                            <Col>
                                <Row >
                                    <p>Hieronder kunt uw klantgegevens bijwerken.</p>
                                </Row>
                                {(prefCustomerDetails) ?
                                    
                                    <Row>
                                        < br />
                                        <MainUserCustomer cusdata={prefCustomerDetails} onChanged={handleCustomerUpdated} />
                                    </Row>
                                    :
                                    <Row>
                                        < br />
                                        <h5>Geen voorkeurs factuurgegevens. Heeft u al eens bij ons besteld ?</h5>
                                    </Row>
                                }
                            </Col>

                        </Row>
                    </TabPane>
                    <TabPane tabId="5" className="colcontainer">
                        <Row>
                            <Col>
                                {(prefContactDetails) ?
                                    <Row>
                                        < br />
                                        <MainUserContact cactdata={prefContactDetails}  />
                                    </Row>
                                    :
                                    <Row>
                                        < br />
                                        <h5>Geen voorkeurs contactgegevens. Heeft u al eens bij ons besteld ?</h5>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>

                <br />
                <hr />
            </Col>

        );
    }

    
    function handleCustomerUpdated(newCustomerDetails) {
        setprefCustomerDetails(newCustomerDetails);
    }
    
    function Portal() {
        if (portalOverride !== "U") {
            if (infoX === "GroupUser") { return (<Navigate to='/gportal' replace />); }
        }
        if (portalOverride === "G") {
            return (<Navigate to='/gportal' replace />);
        }
        if (portalOverride === "A") {
            return (<Navigate to='/aportal' replace />);
        }
    
        return (
            <div>
                <Col className="colcontainer">
                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} />
                    <Col>
                        <Row>
                            <h5>Beste {userDetails.firstName}{" "}{userDetails.lastName}, we zijn blij u (weer) hier te zien. {(infoX === "GroupUser" || infoX === "Admin") && <Button color="primary" size="sm" outline onClick={(e) => { activatePortalSwitch("G"); }}><MdPeople /></Button>}{"  "}{(infoX === "Admin") && <Button color="danger" size="sm" outline onClick={(e) => { activatePortalSwitch("A"); }}><FiSettings /></Button>}</h5>
                            
                            <br />
                        </Row>
                            <Row className="sm=12 centercontainer">
                            <br />
                            <p>Op deze pagina kunt u uw historische bestellingen zien, uw bestellijst voorbereiden, uw bedrijfgegevens, factuur- en bezorgadressen onderhouden.<br />Alles om uw bestelproces zo snel en efficient mogelijk te maken, u heeft namelijk wel meer te doen. <br /><br />Vergeet u ook niet een goed idee met ons te delen om uw ervaring bij TOPO Webshop nog prettiger te maken.</p>
                        </Row>
                            
                        {(loading) ?
                            <Col>
                                <Link color="primary" to="/user_portal" className="btn btn-primary">Ophalen informatie</Link>
                                <Spinner size="sm" color="secondary" /><p>We halen uw gegevens op, een momentje geduld aub...</p>
                            </Col>
                            :
                            <Col>
                                <UserPortal />
                            </Col>
                        }

                    </Col>
                </Col>
            </div>
        );
    }




    if (userDetails !== null) {
        return (
            <Portal />
        )
    }
    else {
        return <Navigate to="/login" replace />;
    }

    
}
