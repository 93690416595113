import React from "react";
import { Col, Row, Form, FormGroup, Input, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class MaintainSliderClass extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.editslider.id,
            name: props.editslider.name,
            description: props.editslider.description,
            image: props.editslider.image,
            startdate: props.editslider.startdate,
            enddate: props.editslider.enddate,
            active: props.editslider.active
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleChange(key, value) {
        
        this.setState({ [key]: value }, () => this.props.onChanged({...this.state}));
    }

    handleDateChange(key, date) {
        this.setState({ [key]: date }, () => this.props.onChanged({...this.state}));
    }

    handleDrop(e) {
        e.preventDefault();

        let allowedTypes = ['image/jpeg']; // Allowed file types
        const file = e.dataTransfer.files[0];
        if (allowedTypes.includes(file.type))
        {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.handleChange('image', e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }

    allowDrop(event) {
        event.preventDefault();
    }

    render() {
        return (
            <Form>
                <Row>
                    <Col sm={6}>
                        <FormGroup row>
                            <Label for="name" sm={4}>Naam</Label>
                            <Col sm={8}>
                                <Input type="text" id="name" value={this.state.name}
                                    onChange={event => this.handleChange('name', event.target.value)}
                                    placeholder="Naam" />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="description" sm={4}>Omschrijving</Label>
                            <Col sm={8}>
                                <Input type="text" id="description" value={this.state.description}
                                    onChange={event => this.handleChange('description', event.target.value)}
                                    placeholder="Omschrijving" />
                            </Col>
                        </FormGroup>
                       
                    </Col>

                    <Col sm={6}>
                        <div onDrop={this.handleDrop} onDragOver={this.allowDrop} style={{ border: '1px solid black', minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {this.state.image ? <img src={this.state.image} alt="Slider" style={{ maxWidth: '100%', maxHeight: '200px' }} /> : 'Drop an image here'}
                        </div>
                    </Col>
                </Row>
                <br />
            </Form>
        );
    }
}

/*
 <FormGroup row>
                            <Label for="startdate" sm={4}>Start Datum</Label>
                            <Col sm={8}>
                                <DatePicker
                                    selected={this.state.startdate}
                                    onChange={date => this.handleDateChange('startdate', date)}
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Start Datum"
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="enddate" sm={4}>Eind Datum</Label>
                            <Col sm={8}>
                                <DatePicker
                                    selected={this.state.enddate}
                                    onChange={date => this.handleDateChange('enddate', date)}
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Eind Datum"
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Label for="active" sm={4}>Actief</Label>
                            <Col sm={8}>
                                <Input type="select" id="active" value={this.state.active}
                                    onChange={event => this.handleChange('active', event.target.value)}
                                    placeholder="Actief">
                                    <option value={true}>Ja</option>
                                    <option value={false}>Nee</option>
                                </Input>
                            </Col>
                        </FormGroup>

*/