import React, { useState, useEffect } from "react";
import { Col, Row, Table, TabContent, NavLink, Nav, NavItem, TabPane, Button, Label, Input, Collapse } from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';
import Axios from '../../../context/axios';
import { FiUser, FiArchive, FiList } from "react-icons/fi";
import { MdPerson, MdPeople, MdSettingsBackupRestore } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import MainUserCustomer from '../MainUserCustomer';
import Spinner from '../../../components/Spinner';
//import MainCustomer from '';
//import MainUserContact from '../MainUserContact';
import classnames from 'classnames';


export default function MainGroupPreferences() {
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    //const [prefAddresses, setPrefAddresses] = useState([]);
    const [prefCustomerDetails, setprefCustomerDetails] = useState([]);
    //const [prefContactDetails, setprefContactDetails] = useState([]);
    const [prefOrders, setprefOrders] = useState([]);
    //const [prefSessions, setSessions] = useState([]);
    const [prefSessions] = useState([]);
    const [uniqueLocations, setuniqueLocations] = useState([],"");
    //const [orderList, setOrderList] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productAnalytics, setProductAnalytics] = useState([]);
    //const [portalAbbrevation, setPortalAbbrevation] = useState([]);
    //const [infoX, setInfoX] = useState("");
    //const [sessions, setCntSessions] = useState("");
    const [activeTab, setActiveTab] = useState('1');
    const [location, setLocation] = useState("");

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    // Tokenrefresh... an example.
    // https:/ / stackoverflow.com / questions / 51646853 / automating - access - token - refreshing - via - interceptors -in -axios / 66288792#66288792

    useEffect(() => {
        async function init() {
            try {
                setLoading(false);
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl = '/api/prefgroupuser/' + xd;
                const response = await Axios.get(runUrl, axiosConfig);
                
                if (response.status === 200) {
                    setResponseData(response.data);
                    setLoading(false);
                }
            } catch (e) {
                //return (<Navigate to='/login' />);
            } 
        }
        init();
    }, []);

    useEffect((e) => {
        setLoading(true);

        if (responseData !== undefined) {
            //setPrefAddresses(responseData.addresses);
            setprefCustomerDetails(responseData.customer);
            //setprefContactDetails(responseData.contact);
            setprefOrders(responseData.historicalOrders);
            //setOrderList(responseData.templateOrders);          
            //setInfoX(responseData.info);
            setuniqueLocations([...new Set(responseData.historicalOrders.map(order => order.location))]);
            setLoading(false);

        }
    }, [responseData]);

    function SessionsGroupsList() {
        return (

            <div>
                <Col>
                    <Row >
                        <p>Uw active sessie code : {userDetails.jwttoken.substr(userDetails.jwttoken.length - 15)}</p>
                    </Row>
                </Col>
                <Col>
                    <Row >
                        <Col md={2}><Label><b>IP Adres</b></Label></Col>
                        <Col md={4}><Label><b>Sessie Code</b></Label></Col>
                        <Col md={3}><Label><b>Aangemaakt</b></Label></Col>
                        <Col md={3}><Label><b>Verloopt</b></Label></Col>
                    </Row>
                    {prefSessions.map((sl) => (
                        <Row key={sl.id}>
                            <Col md={2}><Input type="text" value={sl.createdByIp} disabled /></Col>
                            <Col md={4}><Input type="text" value={sl.token} disabled /></Col>
                            <Col md={3}><Input type="text" value={sl.created} disabled /></Col>
                            <Col md={3}><Input type="text" value={sl.expires} disabled /></Col>
                        </Row>
                    ))
                    }
                </Col>
            </div>
        );
    }




    const [toggleQuestion, setToggleQuestion] = useState(0); //1 is the default id to be opened by default

    /*
    const complexFilteredOrders = data => {
        //if (location && data.location !== location) return false;
        return true;
    } 
    */

    //const filteredOrders = prefOrders.filter(complexFilteredOrders);
    const filteredOrders = prefOrders; //.filter(complexFilteredOrders);

    const numItemsInSelection = filteredOrders.reduce((total, item) => total + item.items, 0);
    const TotalCostItemsInSelection = filteredOrders.reduce((total, item) => total + item.total, 0);
   

    function HistoricalGroupOrdersList() {
        return (
            <div>
                <Row className="sidefilter">
                    <p>Hieronder vindt U de laatste bestellingen die u bij TOPO heeft gedaan. Klik op de regel voor de details van de bestelling.<br />Achter iedere regel staat een knop waarmee u de bestelling inhoud kunt kopieeren naar uw winkelmand.</p>
                </Row>
                <Row >
                    <br />
                    <div className="bbar">
                        <Button value="" className="topbuttonbar" outline color="success" size="lg"
                            onClick={() => setuniqueLocations("")} > <MdSettingsBackupRestore /></Button>

                        {uniqueLocations.map((location, key) => (
                            <Button key={key} value={location} className="topbuttonbar" color="primary" size="lg" outline onClick={(e) => FlipLocation(e)} >{location}</Button>
                        ))}
                    </div>
                    <hr />
                </Row>
                <Row >
                    <Col md={2}><Label><b>Locatie</b></Label></Col>
                    <Col md={2}><Label><b>Order Nummer</b></Label></Col>
                    <Col md={2}><Label><b>Besteldatum</b></Label></Col>
                    <Col md={1}><Label><b>Artikel(en)</b></Label></Col>
                    <Col md={2}><Label><b>Totaal in EUR</b></Label></Col>
                    <Col md={2}><Label><b>Acties</b></Label></Col>

                </Row>
                {prefOrders.map((ord) => (
                    <Row key={ord.id}>
                        <Col md={2}><Label>{ord.location}</Label></Col>
                        <Col md={2}><Label>{ord.orderNumber}</Label></Col>
                        <Col md={2}><Label>{ord.orderDate}</Label></Col>
                        <Col md={1}><Label>{ord.items}</Label></Col>
                        <Col md={2}><Label>{Number(ord.total).toFixed(2)}</Label></Col>
                        <Col md={2}><Button color="primary" size="sm" onClick={() => {
                            if (Number(ord.id) === toggleQuestion) { setToggleQuestion(0); }
                            else { setToggleQuestion(Number(ord.id)); }
                        }}>{toggleQuestion !== Number(ord.id) ? "Toon details" : "Verberg details"}</Button>{" "}
                        </Col>

                        <Collapse className="orderdetails" isOpen={toggleQuestion === Number(ord.id) ? true : false}>
                            <Col >

                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>PLU / Naam</th>
                                            <th>Aantal</th>
                                            <th>Prijs</th>
                                            <th>Totaal in EUR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ord.orderLine.map((item) => (
                                            <tr key={item.id} >
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{`${Number(item.items).toFixed(0)}`}</td>
                                                <td>{`${Number(item.price).toFixed(2)}`}</td>
                                                <td>{`${Number(item.subtotal).toFixed(2)}`}</td>
                                            </tr>
                                        ))}
                                        
                                    </tbody>
                                </Table>

                                <p>
                                    {(ord.notes.length > 1) && < i ><b>Order notities:</b><br />{ord.notes}</i>}
                                    <br />
                                    <i><b>Besteld door:</b> {ord.orderer}<br />
                                        <b>Factuuradres:</b> {ord.orderBilling}<br />
                                        <b>Bezorgadres:</b> {ord.orderDelivery}</i></p><br />
                            </Col>
                        </Collapse>
                    </Row>
                ))}
                <Row >
                    <Col md={2}></Col>
                    <Col md={2}><Label><b>Totalen over alle orders</b></Label></Col>
                    <Col md={1}></Col>
                    <Col md={1}><Label><b>{`${Number(numItemsInSelection).toFixed(0)}`}</b></Label></Col>
                    <Col md={2}><Label><b>{`${Number(TotalCostItemsInSelection).toFixed(2)}`} EUR</b></Label></Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                    <br />

                </Row>

            </div>
        );
    }

    
    function FlipLocation(e) {
        if (location === "") { setLocation(e.target.value); }
        else {
            if (location === e.target.value) {
                setLocation("");
            } else { setLocation(e.target.value); }
        }
    }
/*
{uniqueLocations.map((category, key) => (
                            <Button key={key} value={location} className="topbuttonbar" color="primary" size="lg" outline
                                onClick={(e) => FlipLocation(e)} >{category}</Button>
                        ))}
    */

    function TopGroupProductsList() {

        const result = Object.values(productAnalytics.reduce((r, o) => {
            r[o.product] = r[o.product] || { productId: o.product, productName: o.productName, hits: 0, auth: 0, anom: 0 };
            r[o.product].hits += o.hits;
            if (o.userType === "0") { r[o.product].anom += o.hits; }
            else { r[o.product].auth += o.hits; }
            return r;
        }, {}));
        result.sort((a, b) => (a.hits > b.hits) ? -1 : 1)

        return (

            <div>
                <Col>
                    <Row >
                        <p>Op deze pagina vindt U een overzicht van de meest <b><u>bestelde</u></b> producten van uw organisatie bij TOPO. 
                            <br />U kunt op de locatie knoppen drukken om te filteren per locatie.</p>
                    </Row>
                    <Row >
                        
                    </Row>
                    <Row >
                        <br />
                        <hr />
                    </Row>
                    <Row >
                        <Col md={2}><Label><b>Locatie</b></Label></Col>
                        <Col md={2}><Label><b>Artikel No</b></Label></Col>
                        <Col md={4}><Label><b>Product Naam</b></Label></Col>
                        <Col md={2}><Label><b>Prijs</b></Label></Col>
                        <Col md={1}><Label><b>Totaal</b></Label></Col>
                    </Row>
                    {result.map((sl) => (
                        <Row key={sl.productId}>
                            <Col md={2}><Input type="text" value={sl.productId} disabled /></Col>
                            <Col md={2}><Input type="text" value={sl.hits} disabled /></Col>
                            <Col md={4}><Input type="text" value={sl.productName} disabled /></Col>
                            <Col md={2}><Input type="text" value={sl.anom} disabled /></Col>
                            <Col md={1}><Input type="text" value={sl.hits} disabled /></Col>
                        </Row>
                    ))
                    }
                </Col>
            </div>
        );
    }



    function activatePortalSwitch(portalAbbrevation) {

        

        if (portalAbbrevation === "U") {
            sessionStorage.setItem('portalswitch', JSON.stringify(portalAbbrevation));
            toast.info(`Door naar de User Portal....`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            Navigate('/portal');
        }
        if (portalAbbrevation === "G") {
            sessionStorage.setItem('portalswitch', JSON.stringify(portalAbbrevation));
            toast.info(`Door naar de Groep Portal....`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            Navigate('/portal');
        }


    }



                            


    function Portal() {
        return (
            <div>
                <Col className="colcontainer">
                    {(loading) && <Spinner /> }

                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} />
                    <Col>
                        <Row>
                            <h5>Beste {userDetails.firstName}{" "}{userDetails.lastName}, welkom in uw groep portaal.</h5>
                            <span>
                                <button className="btn btn-outline-primary" onClick={(e) => { activatePortalSwitch("U"); }}><MdPerson />{" "} </button>
                                <button className="btn btn-outline-primary" onClick={(e) => { activatePortalSwitch("G"); }}><MdPeople />{" "} </button>
                            </span>
                        </Row>
                        <Row>
                            <br />
                        </Row>
                        <Row>
                            <p>Vanuit deze pagina kunt u de basisgegevens uzelf en uw collega's van deze webshop onderhouden.<br />Vergeet u ook niet een goed idee met ons te delen om uw ervaring nog prettiger te maken.</p>
                        </Row>
                    </Col>
                    <Col>
                        <Nav tabs className="navtabsleft">
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} ><b><FiUser />{" "}Recente orders</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiArchive />{" "}Analyse Producten</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} ><b><FiList />{" "}Onderhoud Accounts</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }} ><b><FiUser />{" "}Contact & Sessies</b></NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>



                            <TabPane tabId="1" className="colcontainer">
                                <Row>
                                    <Col>
                                        {(prefOrders) ?
                                            <Row>
                                                <HistoricalGroupOrdersList />
                                            </Row>
                                            :
                                            <Row>
                                                <div>
                                                    < br />
                                                    <h5>Geen eerdere bestellingen. Heeft u al eens bij ons besteld ? </h5>
                                                </div>
                                                <div>
                                                    <Link to="/products"><Button color="primary">Zoeken op producten in de TOPO shop</Button></Link>
                                                </div>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2" className="colcontainer">
                                <Row>
                                    <Col>
                                        <Row>
                                            <p>Analyse</p>
                                            <TopGroupProductsList />
                                            <h5>Geen Analyse Producten beschikbaar. Heeft u al eens bij ons besteld ?</h5>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3" className="colcontainer">
                                <Row>
                                    <Col>
                                        <Row>
                                            {(prefCustomerDetails) ?
                                                <Row>
                                                    < br />
                                                    <MainUserCustomer cusdata={prefCustomerDetails} />
                                                </Row>
                                                :
                                                <Row>
                                                    < br />
                                                    <h5>Geen gekoppelde group accounts. Neemt u aub contact met ons op om dit te configuren.</h5>
                                                </Row>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4" className="colcontainer">
                                <Row>
                                    <Col>
                                        <Row>
                                            {(prefSessions) ?
                                                <Row>
                                                    < br />
                                                    <SessionsGroupsList />
                                                </Row>
                                                :
                                                <Row>
                                                    < br />
                                                    <h5>Geen gekoppelde sessie informatie. </h5>
                                                </Row>
                                            }
                                            
                                            
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                        <br />
                        <hr />
                    </Col>
                </Col>
            </div>
        );
    }


    if (userDetails !== null) {
        return (
            <Portal />
        );
    }
    else {
        return <Navigate to='/login' replace />;
    }
            
}

