import React, { useState, useEffect } from "react";
import { Col, Row, Label, Input } from 'reactstrap';
import Axios from "../../../context/axios";

export default function TopSearchedList() {
    const [userAnalytics, setUserAnalytics] = useState([]);
    
    useEffect(() => {
        async function init() {
            try {
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl = '/api/prefadmin/topsea/' + xd;
                const response = await Axios.get(runUrl, axiosConfig);
                if (response.status === 200) {
                    setUserAnalytics(response.data);
                    //console.log("TopSearchedList");
                    //console.log(response.data);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);


    // Functions belonging to : SearchProductsList
    function ProtectedFilteredSearchProductsList(data) {
        let result = [];
        if (data) {
            result = Object.values(data.reduce((r, o) => {
                r[o.id] = r[o.id] || { productId: o.id, artno: o.product, productName: o.productName, hits: 0, auth: 0, anom: 0 };
                r[o.id].hits += o.hits;
                if (o.userType === "Anonymous") { r[o.id].anom += o.hits; }
                else { r[o.id].auth += o.hits; }
                return r;
            }, {}));
            result.sort((a, b) => (a.hits > b.hits) ? -1 : 1)
        }
        return result;
    }

    const filteredSearchProductsList = ProtectedFilteredSearchProductsList(userAnalytics);


    return (
        <div>
        <Col>
            <Row>
                <p>Er komen volcontinue (potentiele) klanten op uw webshop kijken. Maar waar kijken ze dan naar? Dat is de vraag
                    <br />die iedere ecommerce ondernemer zich afvraagt. Deze pagina toont dus welke producten het meest bekeken zijn,
                    <br />en door wat voor gebruikers (anoniem of ingelogd). Het is bij uw volgende klanten contact ook iets wat u kunt
                    <br />gebruiken of met uw klanten in gesprek te gaan en meer of andere producten te verkopen.
                    <br />
                    <br />Vanuit ervaring weten we dat producten die bekeken zijn door aangemeldde klanten, sneller zullen worden besteld.
                </p>
            </Row>
            <Row >
                <Col md={2}><Label><b>Code</b></Label></Col>
                <Col md={6}><Label><b>Product Gezien</b></Label></Col>
                <Col md={1}><Label><b>Totaal</b></Label></Col>
                <Col md={1}><Label><b>Anoniem</b></Label></Col>
                <Col md={1}><Label><b>Aangemeld</b></Label></Col>
            </Row>
            {filteredSearchProductsList.map((sl) => (
                <Row key={sl.productId}>
                    <Col md={2}><Input type="text" value={sl.artno} disabled /></Col>
                    <Col md={6}><Input type="text" value={sl.productName} disabled /></Col>
                    <Col md={1}><Input type="text" value={sl.hits} disabled /></Col>
                    <Col md={1}><Input type="text" value={sl.anom} disabled /></Col>
                    <Col md={1}><Input type="text" value={sl.auth} disabled /></Col>

                </Row>
            ))
            }
        </Col>
    </div>
    );

}


