import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Label, Input } from 'reactstrap';
import Spinner from '../Spinner';
import Axios from '../../context/axios';
import { ToastContainer, toast } from 'react-toastify';
import { MdDelete } from "react-icons/md";
import '../../custom.css';

export default function DetailsCart() {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(true);
    const [notes, setNotes] = useState("");
    //const [validSession, setValidSession] = useState(sessionStorage.getItem('sessionId'));

    useEffect(() => {

        async function getCart() {
            if (updating) {
                let sID = sessionStorage.getItem('sessionId');
                if (sID !== null) {
                    if (sID.length >= 32) {
                        const response = await Axios(`/api/cart?sessionId=${sessionStorage.sessionId}`);
                        if (response.status === 200) {
                            setItems(response.data.items);
                            const numItemsInCart = items.reduce((total, item) => total + item.quantity, 0);
                            sessionStorage.setItem("cartitems", numItemsInCart);
                        } else {
                            setItems([]);
                        }
                    } else { setItems([]); }
                } else { setItems([]); console.log("Empty cart"); }
                setUpdating(false);
            }
            setLoading(false);
        }
        getCart()
    }, [items, updating]);

    function changedNotes(e) {
        setNotes(e.target.value);
    }

    

    async function CheckOut() {
        setLoading(true);
        if (sessionStorage.getItem('sessionId') !== null) {
            let txtaction = '@' + notes 
            let request = { productId: 0, skuId: 0, quantity: 0, action: txtaction };
            let sessionId = sessionStorage.sessionId;
            if (sessionId) { request.sessionId = sessionId; }
            let response = await Axios.post('/api/cart', request);
            if (response.status === 200) {
                setLoading(false);
            }
        }

        setLoading(false);
        //window.location.replace("/checkout");
    }

    async function RemoveItem(e, id) {
        setLoading(true);
        await Axios.delete(`/api/cart/${sessionStorage.sessionId}/lines/${id}`);

        if (sessionStorage.getItem('sessionId') !== null) {
            const response = await Axios(`/api/cart?sessionId=${sessionStorage.sessionId}`);
            if (response.status === 200) {
                setItems(response.data.items);
                const numItemsInCart = items.reduce((total, item) => total + item.quantity, 0);
                sessionStorage.setItem("cartitems", numItemsInCart);
                setUpdating(true);
            }
            else { setItems([]); }
        }
        else { setItems([]); }

        setLoading(false);
    }


    async function updateQuantity(e, id, sku, quantity, oldquantity) {
        let numItemsInCart = 0;
        setLoading(true);
        if ((quantity !== null) || (quantity !== oldquantity)) {
            if (quantity <= 0) { quantity = 0; }
            let request = { productId: Number(id), skuId: Number(sku), quantity: Number(quantity), action: "U" };
            let sessionId = sessionStorage.sessionId;
            if (sessionId) { request.sessionId = sessionId; }
            
            let response = await Axios.post('/api/cart', request);
            if (response.status === 200)
            {
                if (sessionStorage.getItem('sessionId') !== null) {
                    const response = await Axios(`/api/cart?sessionId=${sessionStorage.sessionId}`);
                    if (response.status === 200) {
                        setItems(response.data.items);
                        numItemsInCart = items.reduce((total, item) => total + item.quantity, 0);
                        sessionStorage.setItem("cartitems", numItemsInCart);
                        setUpdating(true);
                    }
                    else { setItems([]); }
                }
            }
            else { setItems([]); }
        }
        sessionStorage.setItem("cartitems", numItemsInCart);
        setLoading(false);
        toast.info('Winkelwagen aangepast', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
    }

    if (loading) return <Spinner />;

    const numItemsInCart = items.reduce((total, item) => total + item.quantity, 0);
    const TotalCostItemsInCart = items.reduce((total, item) => total + (item.quantity * item.price), 0);

    return (
        <Col className="customcart-col">
            <Row >
                <p><i>TOPO Schoonmaak is een groothandel, wij verkopen en leveren alleen aan bedrijven en alle prijzen op de website zijn exclusief BTW.</i></p>
                <h2>Welkom bij uw winkelwagen</h2>
                <p>U heeft {numItemsInCart} producten geselecteerd</p>
            </Row>
            <Row className="customcarttable-col">
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '13%' }}>Artikel No</th>
                            <th style={{ width: '50%' }}>Product Naam</th>
                            <th style={{ textAlign: 'right', width: '10%' }}>Prijs</th>
                            <th style={{ textAlign: 'right', width: '10%' }}>Aantal</th>
                            <th style={{ textAlign: 'right', width: '12%' }}>Subtotaal</th>
                            <th style={{ textAlign: 'right', width: '15%' }}>Verwijder</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => (
                            <tr key={item.skuId}>
                                <td><Input type="text" value={item.artNo} disabled /></td>
                                <td><Input type="text" value={item.name} disabled /></td>
                                <td style={{ textAlign: 'right' }}><Input style={{ textAlign: 'right' }} type="text" value={`€ ${Number(item.price).toFixed(2)}`} disabled /></td>
                                <td style={{ textAlign: 'right' }}>
                                    <Input style={{ textAlign: 'right' }} type="number" name="quantity" value={item.quantity}
                                        onChange={(e) => updateQuantity(e, item.productId, item.skuId, e.target.value)}
                                    />
                                </td>
                                <td style={{ textAlign: 'right' }}><Input style={{ textAlign: 'right' }} type="text" value={`€ ${Number(item.quantity * item.price).toFixed(2)}`} disabled /></td>
                                <td style={{ textAlign: 'right' }}><Button outline color="white" value={item.productId} onClick={(e) => { RemoveItem(e, item.productId) }}><MdDelete size="30" color="red" /></Button></td>
                            </tr>
                        ))}
                        <tr>
                            <td></td>
                            <td></td>
                            <td ><Input style={{textAlign: 'right', fontWeight:'bold'}} type="text" value="Totaalbedrag" disabled /></td>
                            
                            <td></td>
                            <td ><Input style={{ textAlign: 'right', fontWeight:'bold' }} type="text" value={`€ ${Number(TotalCostItemsInCart).toFixed(2)}`} disabled /></td>
                        </tr>
                    </tbody>
                </table>
            
            </Row>
            <Row >
                <br />
            </Row>

            <Row >
                <h6>U ziet hier een totaal, zonder mogelijke afleverkosten.</h6>
                <br />
            </Row>

            <Row >
                <br />
                <Label for="NotesLabel">Mocht u nog specifieke vragen en/of opmerkingen hebben, graag deze hier(onder) invoeren.</Label>
                <Input type="textarea" name="notestext" id="notestext" value={notes} onChange={(e) => changedNotes(e)}/>
                <br />
                <br />
            </Row>

            <Row >
                <br />
                <Button className='btn btn-success' width="50px" onClick={(e) => { CheckOut() }}>Opslaan notitie</Button>
                <br />
            </Row>
            <Row >
                <br />
                <br />
            </Row>
            <Row >
                <Button href='/checkout' className='btn-primary' block>Bestellen</Button>
                <br />
            </Row>
            
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
        </Col>

    );
}


/*
<Col className="custom-col">
            <Col>
                
                <Row >
                    <p><i>TOPO Schoonmaak is een groothandel, wij verkopen en leveren alleen aan bedrijven en alle prijzen op de website zijn exclusief BTW.</i></p><br/>
                </Row>
                <Row >
                    <h2>Welkom bij uw winkelwagen</h2><br />
                </Row>
                <Row >
                    <p>U heeft {numItemsInCart} producten geselecteerd</p>
                </Row>
            </Col>
            <Row>
                <Col md={2}><Label><b>Artikel No</b></Label></Col>
                <Col md={4}><Label><b>Product Naam</b></Label></Col>
                <Col md={2}><Label><b>Prijs</b></Label></Col>
                <Col md={1}><Label><b>Aantal</b></Label></Col>
                <Col md={2}><Label><b>Subtotaal</b></Label></Col>
                <Col md={1}><Label><b>Verwijder</b></Label></Col>
            </Row>
            {items.map((item) => (
                <Row key={item.skuId}>
                    <Col md={2}><Input type="text" value={item.artNo} disabled /></Col>
                    <Col md={4}><Input type="text" value={item.name} disabled /></Col>
                    <Col md={2}><Input className="text-md-right" type="text" value={`€ ${Number(item.price).toFixed(2)}`} disabled /></Col>
                    <Col md={1}>
                        <Input className="text-md-right" type="number" name="quantity" value={item.quantity}
                            onChange={(e) => updateQuantity(e, item.productId, item.skuId, e.target.value)}
                        />
                    </Col>
                    <Col md={2}><Input className="text-md-right" type="text" value={`€ ${Number(item.quantity * item.price).toFixed(2)}`} disabled /></Col>
                    <Col md={1}><Button outline color="white" value={item.productId} onClick={(e) => { RemoveItem(e, item.productId) }}><MdDelete size="30" color="red" /></Button></Col>
                </Row>
            ))}
            <Row>
                <Col md={2}></Col>
                <Col md={4}><Input className="text" style={{fontWeight:'bold'}} type="text" value="Totaalbedrag" disabled /></Col>
                <Col md={2}></Col>
                <Col md={1}></Col>
                <Col md={2}><Input className="text-md-right" style={{fontWeight:'bold'}} type="text" value={`€ ${Number(TotalCostItemsInCart).toFixed(2)}`} disabled /></Col>
            </Row>
            <Row>
                <Col>
                    <br />
                    <h6>U ziet hier een totaal, zonder mogelijke afleverkosten.</h6>
                </Col>
            </Row>
            <Row>
                <Col >
                    <br />
                </Col>
            </Row>
            <Row>
                <Col >
                    <Label for="NotesLabel">Mocht u nog specifieke vragen en/of opmerkingen hebben, graag deze hier(onder) invoeren.</Label>
                    <Input type="textarea" name="notestext" id="notestext" value={notes} onChange={(e) => changedNotes(e)}/>
                    <br />
                    <Button className='btn btn-success' onClick={(e) => { CheckOut() }}>Opslaan notitie</Button>
                </Col>
            </Row>
            <Row>
                <Col >
                    <br />
                </Col>
            </Row>
            <Row >
                <Button href='/checkout' className='btn-primary' block>Bestellen</Button>
                <br />
            </Row>
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
        </Col>
*/