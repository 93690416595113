import React from 'react';
import { OccasionList } from './OccasionList';

export default function OccasionPage () {

    return (
        <div>
            <OccasionList />
        </div>
    );
}

