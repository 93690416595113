import React from 'react';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';

export default class MaintainMediaClass extends React.Component {
    
    state = {
        id: Number(this.props.editmedia.id),
        mediatype: Number(this.props.editmedia.mediaType),
        medianame: this.props.editmedia.mediaName,
        medialocation: this.props.editmedia.mediaLocation,
        productid: Number(this.props.editmedia.productId)
    };

    handleChange(newState) {
        this.setState(newState, () => {
            if (this.props.onChanged)
                this.props.onChanged(this.state);
        });
    }

    render() {
        return (
            <Form >
                <FormGroup row className="slimFormRow">
                    <Label for="color" sm={3}>Type</Label>
                    <Col sm={6}>
                        <Input type="select" id="mediatype" value={this.state.mediatype}
                            onChange={event => this.handleChange({ mediatype: event.target.value })}
                            placeholder="Type">
                            <option value="0">Plaatje-Foto</option>
                            <option value="1">Document</option>
                            <option value="2">Film-Video</option>
                            <option value="9">Veiligheidsblad</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="medianame" sm={3}>Media Omschrijving</Label>
                    <Col sm={6}>
                        <Input type="text" id="medianame" value={this.state.medianame}
                            onChange={event => this.handleChange({ medianame: event.target.value })}
                            placeholder="Omschrijving" />
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="medialocation" sm={3}>Media Web Locatie</Label>
                    <Col sm={6}>
                        <Input type="text" id="medialocation" value={this.state.medialocation}
                            onChange={event => this.handleChange({ medialocation: event.target.value })}
                            placeholder="Media Locatie" />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}