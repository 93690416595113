import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/auth";
import { Container } from 'reactstrap';
import Home from "./components/Home/Home";
import Header from './components/Header';
import Footer from './components/Footer';
import ProductDetails from './components/Products/ProductDetails';
import ProductPage from './components/Products/ProductPage';
import PerCategoryList from './components/Products/PerCategoryList';
import ProductCategoriesPage from './components/Products/ProductCategoriesPage';
import PromotionPage from './components/Products/PromotionPage';
import ServicesPage from './components/Products/ServicesPage';
import ServicesDetails from './components/Products/ServicesDetails';
import RentingPage from './components/Products/RentingPage';
import RentingDetails from './components/Products/RentingDetails';
import OccasionPage from './components/Products/OccasionPage';
import OccasionDetails from './components/Products/OccasionDetails';
import Login from './components/Login/Login';
import Activation from './components/Login/Activation';
import Logout from './components/Logout/Logout';
import Register from './components/Register/Register';
import Cart from './components/Cart/Cart';
import CheckoutPage from './components/Checkout/CheckoutPage';
import About from './components/About/About';
import LoggedOnUser from './components/User/LoggedOnUser';
import LoggedOnAdmin from './components/Products/LoggedOnAdmin';
import MaintainDetails from './components/Products/MaintainProductDetails';
import ResetPassword from "./components/Login/ResetPassword";
import MainGroupPreferences from "./components/User/Group/MainGroupPreference";
import MainAdminPreferences from "./components/User/Admin/MainAdminPreferences";
import Survey from "./components/Cart/Survey";
//import Init from "./components/Init";
import CheckoutSuccess from './components/Checkout/CheckoutResult';

function App() {
    const existingTokens = JSON.parse(sessionStorage.getItem("tokens"));
    const [authTokens, setAuthTokens] = useState(existingTokens);

    const setTokens = (data) => {
        sessionStorage.setItem("tokens", JSON.stringify(data));
        setAuthTokens(data);
    }


    return (
        <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>

            <div className="bodylayout">
                <Header />
                <Container className="pagecontainer">
                    <Routes>
                        <Route exact path='/' element={<Home/>} />
                        <Route path='/product/:id' element={<ProductDetails/>} />
                        <Route path='/products' element={<ProductPage/>} />
                        <Route path='/product/category/:id' element={<PerCategoryList/>} />
                        <Route path='/productcategories' element={<ProductCategoriesPage/>} />
                        <Route path='/promotions' element={<PromotionPage/>} />
                        <Route path='/service/:id' element={<ServicesDetails/>} />
                        <Route path='/services' element={<ServicesPage/>} />
                        <Route path='/rent/:id' element={<RentingDetails/>} />
                        <Route path='/renting' element={<RentingPage/>} />
                        <Route path='/occasion/:id' element={<OccasionDetails/>} />
                        <Route path='/occasions' element={<OccasionPage/>} />
                        <Route path='/about' element={<About/>} />
                        <Route path='/cart' element={<Cart/>} />
                        <Route path='/home' element={<Home/>} />
                        <Route path='/checkout' element={<CheckoutPage/>} />
                        <Route path='/login' element={<Login/>} />
                        <Route path='/logout' element={<Logout/>} />
                        <Route path='/register' element={<Register/>} />
                        <Route path='/activation/:id' element={<Activation/>} />
                        <Route path='/activation' element={<Activation/>} />
                        <Route path='/activatie' element={<Activation/>} />
                        <Route path='/survey' element={<Survey/>} />
                       
                        <Route path='/checkoutsuccess' element={<CheckoutSuccess/>} />
                        <Route path='/resetpassword' element={<ResetPassword/>} />
                        <Route path='/user' element={authTokens !== null ? <LoggedOnUser/> : (<Navigate to="/login" />)} />
                        <Route path='/gportal' element={authTokens !== null ? <MainGroupPreferences/> : (<Navigate to="/login" />)} />
                        <Route path='/aportal' element={authTokens !== null ? <MainAdminPreferences/> : (<Navigate to="/login" />)} />
                        <Route path='/maintain/:id' element={authTokens !== null ? <MaintainDetails/> : (<Navigate to="/login" />)} />
                        <Route path='/maintenance' element={authTokens !== null ? <LoggedOnAdmin/> : (<Navigate to="/login" />)} />
                    </Routes>
                </Container>
                <Footer />
            </div>
        </AuthContext.Provider>
    );
}

export default App;
/*
 <Route path='/iinniitt' element={<Init/>} />
*/


/*

                    <Route exact path='/gportal' element={<PrivateRoute />} >
                        <PrivateRoute path='/gportal' element={MainGroupPreferences} />
                    </Route>
                    <Route exact path='/aportal' element={<PrivateRoute />} >
                        <PrivateRoute path='/aportal' element={MainAdminPreferences} />
                    </Route>

                    <Route exact path='/maintain/:id' element={<PrivateRoute />} >
                        <PrivateRoute path='/maintain/:id' element={MaintainDetails} />
                    </Route>
                    <Route exact path='/maintenance' element={<PrivateRoute />} >
                        <PrivateRoute path='/maintenance' element={LoggedOnAdmin} />
                    </Route>
*/