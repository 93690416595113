import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardImg, CardBody, CardTitle, Button, Form, FormGroup, Label, Media } from 'reactstrap';
import Axios from '../../context/axios';
import Spinner from '../Spinner';
import { MdAddShoppingCart, MdTrendingFlat, MdTransferWithinAStation, MdPlayArrow } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../custom.css'


export function ServicesList() {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");


    useEffect(() => {
        async function init() {
            try {
                const response = await Axios('/api/services');
                if (response.status === 200) {
                    setProducts(response.data.products);
                } else {
                    throw response;
                }
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        }
        init();
    }, []);


    function setItemCount(addcnt) {
        let itemCount = 0;
        let itemAddCount = (isNaN(addcnt) ? 0 : parseInt(addcnt));
        itemCount = parseInt(sessionStorage.getItem("cartitems"));
        if (isNaN(itemCount)) {
            sessionStorage.setItem("cartitems", itemAddCount.toString());
        }
        else {
            itemCount += itemAddCount;
            sessionStorage.setItem("cartitems", itemCount.toString());
        }
    }

    async function AddToCart(e) {
        e.preventDefault();
        let request = { productId: Number(e.target.value), quantity: Number(1) };
        let sessionId = sessionStorage.sessionId;
        if (sessionId) { request.sessionId = sessionId; }
        let result = await Axios.post('/api/cart', request);
        sessionStorage.sessionId = result.data.sessionId;
        setItemCount(1);
        toast.info('(weer) een dienst toegevoegd', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
    }

    /*
    function renderProduct(p) {
        return (
            <Card key={p.id} className="servicecard">
                <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
                <Link to={`/service/${p.id}`} style={{ textDecoration: 'none', color: "black" }}>
                <CardBody>
                    
                        <CardImg top width="100%" center="true" className="product-img" src={p.images[0]} alt="Card image cap" />
                        <CardTitle><b>{p.name}</b></CardTitle>
                        <CardText color="black">{p.description}</CardText>
                    
                </CardBody>
                </Link>
                {p.promotion === 1 ?
                    <Button color="warning" width="75%" value={p.id} onClick={(e) => { AddToCart(e); }} ><MdAddShoppingCart />{" "}Toevoegen{" "}<br/>{" "}{" � "}{Number(p.price).toFixed(2)}{" "} EUR per uur</Button>
                    :
                    <Button color="primary" width="75%" value={p.id} onClick={(e) => { AddToCart(e); }} ><MdAddShoppingCart />{" "}Toevoegen{" "}<br />{" "}{" "}{Number(p.price).toFixed(2)}{" "} EUR per uur</Button>
                }
            </Card>
        );
    }
*/

    function renderProduct(p) {
        return (
        
          <Card key={p.id}  className="servicecard" width="100%" >
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
            <Row >
                
                <Col width="25%">
                    <div className="text-center">
                        <CardImg alt={p.name} src={p.images[0]} width="100%"/>
                    </div>
                </Col>
                <Col width="75%">
                    <CardBody>
                        <Col>
                            <CardTitle tag="h5" className="product-title"><b>{p.name}</b></CardTitle>
                        </Col>
                        <Col>
                            {(p.largeDescription.length > 0) ?
                                <Col>
                                        {p.largeDescription.map((ld) => (
                                            <Row key={ld.sequence}>
                                                {(ld.displayType === 0) && <br />}
                                                {(ld.displayType === 1) && <h6><b>{ld.infoText}</b></h6>}
                                                {(ld.displayType === 2) && <p>{ld.infoText}</p>}
                                                {(ld.displayType === 3) && <h6>{ld.infoText}</h6>}
                                                {(ld.displayType === 4) && <p><b>{ld.infoText}</b></p>}
                                                {(ld.displayType === 5) && <p>{" - "}{ld.infoText}</p>}
                                                {(ld.displayType === 6) && <p><i>{ld.infoText}</i></p>}
                                                {(ld.displayType === 20) && <Media object src={ld.infoImage} alt="img" />}
                                            </Row>
                                        ))}
                                </Col>
                                :
                                <Col>
                                    <br />
                                    <h6>Er is hier geen verdere product informatie.</h6>
                                </Col>
                            }
                        </Col>
                    </CardBody>
                </Col>
                
            </Row>
            <br />
           
            {/* Button is positioned absolutely */}
            <Button width="95%"
              color={p.promotion === 1 ? "warning" : "primary"}
              className="m-0" // Remove margin if it was set before
              value={p.id}
              onClick={(e) => { AddToCart(e); }}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%'
              }}
            >
              <MdAddShoppingCart />{" "}Toevoegen{" "}<MdTrendingFlat />{" "}€{" "}{Number(p.price).toFixed(2)}
            </Button>
          </Card>
        );
      }



    const complexFiltered = data => {
        if (searchValue && !data.name.toLowerCase().includes(searchValue)) { return false };
        if (data.active !== 1) { return false };

        return true;
    } 

    const filteredProducts = products.filter(complexFiltered);
    const availableProductCount = filteredProducts.length;
    const availableProductText = (availableProductCount === 1 ? "gevonden artikel" : "gevonden artikelen");

    if (error) throw error;
    if (loading) return <Spinner />;

    return (
        <div>
            <Row >
                <h2 ><MdTransferWithinAStation size="35" />{" "}Diensten</h2><br />
                <p><b><i>TOPO Schoonmaak</i></b><i> Groothandel levert ook specialitische diensten voor uw schoonmaak activiteiten.</i></p>
            </Row>
            <Row className="custom-col">
                <Row><br /></Row> 
                <Row>
                    <Col>
                        <Form inline>
                            <FormGroup>
                                <Label for="Zoeken" className="mr-sm-2"><b>Zoeken</b></Label>
                                <input id="searchValue" type="text" value={searchValue} onChange={e => { setSearchValue(e.target.value.toLowerCase()); }} />
                                <Label for="Results" className="mr-sm-2"> <MdPlayArrow />{" "} ({availableProductCount} {availableProductText})</Label>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Row>

            <Row >
                {filteredProducts.map(renderProduct)}
                <br />
                <Row>
                    <br /><br />
                </Row> 
            </Row>


        </div>
    );
}
//
/*
 
 <div>
            <Row className="colcontainer">
                <Row>
                    <Col>
                        <p><b>Zoeken</b></p>
                        <input id="searchValue" type="text" value={searchValue} onChange={e => { setSearchValue(e.target.value.toLowerCase()); }} />
                    </Col>
                </Row>
            </Row>
            <Row className="colcontainer">
                <center><p>({availableProductCount} {availableProductText})</p></center>
                <hr />
            </Row>
            <Row className="colcontainer">
                {filteredProducts.map(renderProduct)}
            </Row>
            <Row className="colcontainer">
                <br />
            </Row>

        </div>
 

*/

/*
function renderProduct(p) {
    return (
        <Card key={p.id} className="productcard">
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
            <CardBody >
                <Link to={`/product/${p.id}`} style={{ textDecoration: 'none', color: "black" }}>
                    <CardImg top width="100%" center="true" className="product-img" src={require(`../../assets/images/${p.images[0]}`)} alt="Card image cap" />
                    <CardTitle><b>{p.name}</b></CardTitle>
                    <CardText color="black">{p.description}</CardText>
                </Link>
            </CardBody>
            {p.promotion ?
                <Button color="warning" width="75%" value={p.id} onClick={(e) => { AddToCart(e); }} ><MdAddShoppingCart />{" "}Toevoegen{" "}<MdTrendingFlat />{" "}<strong>�{" "}{Number(p.price).toFixed(2)}</strong></Button>
                :
                <Button color="primary" width="75%" value={p.id} onClick={(e) => { AddToCart(e); }} ><MdAddShoppingCart />{" "}Toevoegen{" "}<MdTrendingFlat />{" "}<strong>�{" "}{Number(p.price).toFixed(2)}</strong></Button>
            }
        </Card>
    );
}



    render() {
        return (
            <Col className="sm=12 centercontainer">
                <div className="row">
                    {this.state.products.map(product =>
                        <div key={product.id} className="col-4">
                            <ServiceCard product={product} />
                        </div>
                    )}
                </div>
                <div>
                    <p> </p>
                </div>
            </Col>
        );
    }
}
*/