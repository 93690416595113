import axios from "axios";

//const baseURL = 'https://previewwebshoptopoapi.azurewebsites.net';
//const baseURL = 'https://localhost:5000';
const newLocal = 'https://webshoptopoapi.azurewebsites.net';

const instance = axios.create({
    baseURL:  newLocal
});

export default instance;

