import React from "react";
import { Col, Form, FormGroup, Input, Label} from "reactstrap";

export default class MainUserContactpwd extends React.Component {

    state = {
        existing: '',
        newfirst: '',
        newlast: '',
        validate: {
            samenew: ''
        },
    };

    handleChange(newState) {
        this.setState(newState, () => {
            if (this.props.onChanged)
                this.props.onChanged(this.state);
        });
    }

    render() {
        return (
            <Form>
                <FormGroup row>
                    <Label for="existing" sm={2}>Huidig</Label>
                    <Col sm={6}>
                        <Input
                            type="password"
                            id="firstname"
                            value={this.state.existing}
                            onChange={event => this.handleChange({ existing: event.target.value })}
                            placeholder="Huidig wachtwoord ter validatie" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="newfirst" sm={2}>Nieuw</Label>
                    <Col sm={6}>
                        <Input
                            type="password"
                            id="newfirst"
                            value={this.state.newfirst}
                            onChange={event => this.handleChange({ newfirst: event.target.value })}
                            placeholder="Nieuwe wachtwoord" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="newlast" sm={2}>Validatie</Label>
                    <Col sm={6}>
                        <Input
                            type="password"
                            id="newlast"
                            value={this.state.newlast}
                            invalid={this.state.validate.samenew === 'has-danger'}
                            onChange={event => {
                                this.handleChange({ newlast: event.target.value })
                            }}
                            placeholder="Nieuwe wachtwoord nog een keer ter validatie" />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}
