import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Alert } from 'reactstrap';
import Axios from '../../context/axios';
import { Questions } from "./questions";
import { AddToCart } from "../AddToCart";
import Spinner from '../Spinner';
import { FiArrowLeft } from "react-icons/fi";

export default function ProductDetails(props) {
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const [images, setImages] = useState([]);
    const [stringsku, setStringSku] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    useEffect(() => {
        async function init(id) {
            try {
                const response = await Axios(`/api/renting/${id}`);
                if (response.status === 200) {
                    setProduct(response.data);
                    let skuid = id;
                    setStringSku(skuid);
                    setImages(response.data.images);
                } else {
                    throw response;
                }
            } finally {
                setLoading(false);
            }
        }
        init(id, 0);
    }, [id]);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const imageSlides = images.map((m) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={m.id}
            >
                <img src={m} className="carousselcard" width="400" height="300" alt={product.name} />
            </CarouselItem>
        );
    });

    if (loading) return <Spinner />;

    return (
        <div>
            <Col id="colrefresh" className="colcontainer">
                {(product.promotion === 1) &&
                    <Alert id="promo" color="warning"><b>Aanbiedingsartikel, prijzen zijn exclusief BTW en zolang de voorraad strekt.</b></Alert>
                }
                <Link to="/renting" style={{ textDecoration: 'none' }}><Row className="colcontainer"><h4><FiArrowLeft />{" "}Terug naar Verhuurlijst</h4></Row></Link>
                <Row>
                    <Col>
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                        >
                            <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {imageSlides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>

                    </Col>
                    <Col>
                        <h2>{product.name}</h2>
                        <p><b>Omschrijving</b>{" : "}{product.description}</p>
                        <p><b>Categorie</b>{" : "}{product.type}{" / "}{product.category}</p>
                        <p><b>Merk</b>{" : "}{product.brand}</p>
                        <p><b>Service tarief</b>{" : "}€ {Number(product.price).toFixed(2)} per uur</p>
                        {" "}
                        <AddToCart productId={stringsku} productName={product.name} />
                    </Col>
                    <hr />
                </Row>

            </Col>
            <Col className="colcontainer">
                <hr />
                <h3>Product informatie</h3>
                <p>{product.description}</p>
            </Col>
            <Col className="colcontainer">
                <Questions />
            </Col>
        </div>
    );
}

