import React, { useState, useEffect } from "react";
import { Col, Button, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import Axios from '../../context/axios';
import { ToastContainer, toast } from 'react-toastify';

export default function MaintainUsers() {
    const [usersList, setUsersList] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [setError] = useState({});
    const [searchValueUser, setSearchValueUser] = useState("");

    useEffect(() => {
        async function init() {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            try {
                let runUrl = '/account/activeusers';
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                const response = await Axios.get(runUrl, axiosConfig);
                if (response.status === 200) {
                    setResponseData(response.data);
                } else {
                    throw response;
                }
            } catch (e) {

            } finally {
              // TODO document why this block is empty
            }
        }
        init();
    }, []);


    useEffect((e) => {
        if (responseData !== undefined) {
            setUsersList(responseData);
        }
    }, [responseData]);

    async function ActivationData(e) {
        if (usersList.length > 1) {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            const data = { "content": e.target.value, "token": `Bearer ${ud}` };
            let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
            try {
                const response = await Axios.post('api/maintain/userable', data, axiosConfig);
                if (response.status === 200) {
                    setUsersList(response.data);
                    toast.info('Gebruiker van Actieve status gewijzigd.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            } catch (e) {
                setError(e);
            } finally {
                // TODO document why this block is empty
            }
        }
    }

    async function ForgotPassword(e) {
        if (usersList.length > 1) {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            const data = { "content": e.target.value, "token": `Bearer ${ud}` };
            let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
            try {
                const response = await Axios.post('api/maintain/forgotpassword', data, axiosConfig);
                if (response.status === 200) {
                    toast.info('Gebruiker is een email gestuurd met een nieuw wachtwoord.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            } catch (e) {
                setError(e);
            } finally {
                // TODO document why this block is empty
            }
        }
    }

    async function ResendActivation(e) {
        if (usersList.length > 1) {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            const data = { "content": e.target.value, "token": `Bearer ${ud}` };
            let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
            try {
                const response = await Axios.post('api/maintain/resendactivation', data, axiosConfig);
                if (response.status === 200) {
                    toast.info('Gebruiker is een email gestuurd met een nieuwe activatie code.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            } catch (e) {
                setError(e);
            } finally {
                // TODO document why this block is empty
            }
        }
    }

    async function ResetSession(e) {
        if (usersList.length > 1) {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            const data = { "content": e.target.value, "token": `Bearer ${ud}` };
            let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
            try {
                const response = await Axios.post('api/maintain/resetses', data, axiosConfig);
                if (response.status === 200) {
                    toast.info('De gebruiker wordt uitgelogd en dient opnieuw in te loggen.', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            } catch (e) {
                setError(e);
            } finally {
              // TODO document why this block is empty
            }
        }
    }

    const complexFiltered = data => {
        let conditionLN = true;
        let conditionFN = true;
        let conditionEM = true;
        
        if (searchValueUser && !data.lastName.toLowerCase().includes(searchValueUser)) { conditionLN = false; }
        if (searchValueUser && !data.firstName.toLowerCase().includes(searchValueUser)) { conditionFN = false; }
        if (searchValueUser && !data.emailAddress.toLowerCase().includes(searchValueUser)) { conditionEM = false; }

        return conditionLN || conditionFN || conditionEM;
    } 


    const filteredUsers = usersList.filter(complexFiltered);


    if (filteredUsers !== null) {
        return (
            <Col>
                <Row className="colcontainer">
                    <Col>
                        <Form>
                            <FormGroup row>
                                <Label for="lblquestion" sm={2} ><b>Zoeken op achternaam</b></Label>
                                <Col sm={2}>
                                    <Input type="text" value={searchValueUser} onChange={e => { setSearchValueUser(e.target.value.toLowerCase()); }} />
                                </Col>
                                <Label for="lblfilteredusers" sm={3} >({filteredUsers.length} gevonden gebruikers). </Label>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row className="colcontainer" >
                    <table border={2} cellPadding={5}>
                        <thead>
                            <tr>
                                <td><b>Voornaam</b></td>
                                <td><b>Achternaam</b></td>
                                <td><b>Email</b></td>
                                <td><b>Actief</b></td>
                                <td><b>Login</b></td>
                                <td><b>Wachtwoord</b></td>
                                <td><b>Activatie</b></td>
                                <td><b>Session Reset</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredUsers.map((c) => (
                                    <tr key={c.id}>
                                        <td>{c.firstName}</td>
                                        <td>{c.lastName}</td>
                                        <td>{c.emailAddress}</td>
                                        <td>{c.active ? "Ja" : "Nee"}</td>
                                        <td>{c.active ? <Button color="danger" value={c.emailAddress} size="sm" onClick={(e) => { ActivationData(e); }}>De-Activeren</Button> : <Button color="warning" value={c.emailAddress} size="sm" onClick={(e) => { ActivationData(e); }}>Activeren</Button>}</td>
                                        <td><Button color="success" value={c.emailAddress} size="sm" onClick={(e) => { ForgotPassword(e); }}>Wachtwoord Reset</Button></td>
                                        <td>{(c.emailAddress.length === 50 && c.active === false) ? <Button color="danger" value={c.emailAddress} size="sm" onClick={(e) => { ResendActivation(e); }}>Stuur activatie code</Button> : <Button color="secondary" size="sm" disabled >Niet beschikbaar</Button>}</td>
                                        <td> <Button color="danger" value={c.emailAddress} size="sm" onClick={(e) => { ResetSession(e); }}>Reset Sessie</Button></td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    <br />
                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
                </Row>
            </Col>
        );
    }
    else {
        return <Navigate to='/login' replace />;
    }
}
