import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardImg, CardBody, CardText, CardTitle, Badge } from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';
import Axios from '../../context/axios';
import Spinner from '../../components/Spinner';
import '../../custom.css'
//import default128image from '../../assets/images/128x128.jpg';

export function MaintainProductList() {
    const [userDetails] = useState([]);
    const [validSession] = useState(true);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uniqueCategories, setuniqueCategories] = useState([], "");
    const [uniqueBrands, setuniqueBrands] = useState([], "");

    // Filter features
    const [searchValueTop, setSearchValueTop] = useState("");
    const [category, setCategory] = useState("");
    //const [color, setColor] = useState("");
    const [productType, setProductType] = useState("");
    const [brand, setBrand] = useState("");
    const [promotion, setPromotion] = useState("");
    const [restparty, setRestparty] = useState("");
    const [active, setActive] = useState("");

    useEffect(() => {
        async function init() {
            try {
                setLoading(true);

                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                //console.log(ud);
                const response = await Axios('/api/maintain', { headers: { "Authorization": `Bearer ${ud}` } });

                if (response.status === 200) {
                    setProducts(response.data.products);
                    setuniqueCategories([...new Set(response.data.products.map(product => product.category))]);
                    setuniqueBrands([...new Set(response.data.products.map(product => product.brand))]);
                    setLoading(false);
                } else {
                    throw response;
                }
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
            setLoading(false);
        }
        init();
    }, []);


    function renderProduct(p) {
        return (
          <Card key={p.id} className="productcard"  width="100%">
            {(p.promotion === 1) && <Badge color="warning">Aanbieding</Badge>}
            <Link to={`/maintain/${p.id}`} style={{ textDecoration: 'none', color: 'black' }}>
              
              <div className="text-center">
                <CardImg alt={p.name} src={p.images} top />
              </div>
              <CardBody>
                <CardTitle tag="h5" className="product-title"><b>{p.name}</b></CardTitle>
                <CardText className="product-description">{p.description}</CardText>
                </CardBody>
            </Link>
          </Card>
        );
      }
      //<CardImg alt={p.name} src={p.images} top />
  


    // Functions belonging to : MaintainProductsList

    const complexFiltered = (data) => {
        //let defaultImagesArray = ['https://topostatic.blob.core.windows.net/topo/materials.png', 'https://topostatic.blob.core.windows.net/topo/machines.png', 'https://topostatic.blob.core.windows.net/topo/pads.jpg', 'https://topostatic.blob.core.windows.net/topo/paper.png', 'https://topostatic.blob.core.windows.net/topo/plastics.png'];


        if (searchValueTop && !data.name.toLowerCase().includes(searchValueTop)) return false;
        if (category && data.category !== category) return false;
        if (brand && data.brand !== brand) return false;
        if (promotion && data.promotion !== (promotion === "1" ? 1 : 2)) return false;
        if (restparty && data.restParty !== (restparty === "1" ? 1 : 2)) return false;
        if (productType && data.productType !== productType) return false;
        return !(active && data.active !== (active === "1" ? 1 : 2));
    }

    /*
    function ProtectedFilteredSessions(data) {
        let result = [];
        setLoading(true);
        if (data) {
            result = data;
            setLoading(false);
        }
        return result;
    }
    */
   
    //const filteredProducts = ProtectedFilteredSessions(products);


    const filteredProducts = products.filter(complexFiltered);
   

    if (error) throw error;
    //if (loading) return <Spinner />;

    if ((userDetails !== null) && (validSession)) {
        return (
            <div>

                
                <Row className="colcontainer">
                    <Row>
                        {(loading) && <Spinner /> }
                        <Col>
                            <p><b>Zoeken</b></p>
                            <input id="searchValueTop" type="text" value={searchValueTop} onChange={(e) => setSearchValueTop(e.target.value.toLowerCase())} />
                        </Col>
                        <Col>
                            <p><b>Actief</b></p>
                            <select id="active"
                                value={promotion}
                                onChange={(e) => setActive(e.target.value)}
                            >
                                <option value="">Alle artikelen</option>
                                <option value="1">Ja</option>
                                <option value="2">Nee</option>
                            </select>
                        </Col>
                        <Col>
                            <p><b>Categoriëen</b></p>
                            <select id="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="">Alle categoriëen</option>
                                {uniqueCategories.map((category, key) => (
                                    <option key={key} value={category}>{category}</option>
                                ))}
                            </select>
                        </Col>
                        <Col>
                            <p><b>Merken</b></p>
                            <select id="brand"
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <option value="">Alle merken</option>
                                {uniqueBrands.map((brand, key) => (
                                    <option key={key} value={brand}>{brand}</option>
                                ))}
                            </select>
                        </Col>
                        <Col>
                            <p><b>Aanbieding</b></p>
                            <select id="promo"
                                value={promotion}
                                onChange={(e) => setPromotion(e.target.value)}
                            >
                                <option value="">Alle artikelen</option>
                                <option value="true">Ja</option>
                                <option value="false">Nee</option>
                            </select>
                        </Col>
                        <Col>
                            <p><b>Restpartij</b></p>
                            <select id="restparty"
                                value={restparty}
                                onChange={(e) => setRestparty(e.target.value)}
                            >
                                <option value="">Alle artikelen</option>
                                <option value="true">Ja</option>
                                <option value="false">Nee</option>
                            </select>
                        </Col>
                        <Col>
                            <p><b>Product Type</b></p>
                            <select id="productType"
                                value={productType}
                                onChange={(e) => setProductType(e.target.value)}>
                                <option value="">Alle Typen</option>
                                <option value="Product">Product</option>
                                <option value="Renting">Verhuur</option>
                                <option value="Service">Diensten</option>
                                <option value="Occasion">Occasion</option>
                            </select>
                        </Col>
                    </Row>
                </Row>
                <Row className="colcontainer">
                    <center><p>({filteredProducts.length} gevonden artikelen)</p></center>
                    <hr />
                </Row>
                <Row className="colcontainer">
                    {filteredProducts.map(renderProduct)}
                </Row>

                <Row className="colcontainer">
                    <br />
                </Row>
                <Row className="colcontainer">
                    <p>{" "}</p>
                </Row>
            </div>
        );

    }
    else {
        return <Navigate to='/login' replace />;
    }
}

/*
 * 
   <Col>
                            <p><b>Kleuren</b></p>
                            <select id="color"
                                value={color}
                                onChange={(e) => setColor(e.target.value)}
                            >
                                <option value="">Alle kleuren</option>
                                {uniqueColors.map((color, key) => (
                                    <option key={key} value={color}>{color}</option>
                                ))}
                            </select>
                        </Col>
*/