import React, { useState } from "react";
import { Alert, Col, Spinner, Button, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MdInput } from "react-icons/md";
import Axios from '../../context/axios';

export default function ResetPassword() {
    const [email, setEmail] = useState("");
    const [details, setDetails] = useState({});
    const [okReset, setOKReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errReset, setErrReset] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);

    async function CallResetPassword(e) {
        if (validateEmail(email) === false && email.length >= 5) {
            try {
                setLoading(true);
                const data = { "content": email, "token": "pwdrst" };
                const response = await Axios.post("/account/forgotpassword", data);
                if (response.status === 200) {
                    setDetails(response.data);
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    setLoading(false);
                    if (details === "failed") {
                        setErrReset(true);
                        setOKReset(false);
                    } else {
                        setOKReset(true);
                        setErrReset(false);
                    }
                    await new Promise(resolve => setTimeout(resolve, 3000));

                } else {
                    setErrReset(true);
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    setErrReset(false);
                }
            } finally {
                setLoading(false);
            }
        }
        else {
            setInvalidEmail(true);
            await new Promise(resolve => setTimeout(resolve, 5000));
            setInvalidEmail(false);
        }
    }

    function validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length >= 5) {
            if (emailRex.test(email)) {
                setInvalidEmail(false); return false;
            } else {
                setInvalidEmail(true);  return true;
            }
        }
        else
        {
            setInvalidEmail(true); return true;
        }
    }

    function handleChange(e) {
        setEmail(e.target.value);
    };

    return (
        <div>
            <Col className="colcontainer">
                <h4><b>Resetten van uw wachtwoord</b></h4>

                {details &&
                    <Col sm={8}>
                        <br />
                        <Label for="city" sm={8}>Uw email adress:</Label>
                        <Col sm={8}>
                            <Input type="text" className="form-control" onChange={handleChange} value={email} id="id" />
                            <br />
                        <Button color="primary" onClick={(e) => { CallResetPassword(e); }}>{" "}Resetten wachtwoord</Button>
                        </Col>
                        <br />
                    </Col>
                }

                {loading &&
                    <Col>
                        <br />
                        <Alert color="warning">We zijn uw wachtwoord aan het resetten. Een moment geduld alstublieft.</Alert>
                        <Spinner style={{ width: '3rem', height: '3rem', color: '#009FD6' }} />
                        <br />
                    </Col>
                }

                { invalidEmail ?
                    <Col>
                        <br />
                        <Alert color="danger">Opgegevens email adres is niet correct.</Alert>
                        <br />
                    </Col>
                    :
                    <Col></Col>
                }

                {!loading &&
                    <Col>
                        <br />
                        {errReset ?
                            <Col>
                                <Alert color="danger">Er is iets fout gegaan bij het resetten van uw wachtwoord. Probeert u het opnieuw over 1 minuut en neem anders contact op met TOPO.</Alert>
                            </Col> : <Col></Col>
                        }
                        {okReset ?
                            <Col>
                                <Alert color="success">Uw wachtwoord is succesvol gereset. Wacht op de email en druk op de <Link to="/login" className="alert-link"><b><MdInput /> Inloggen</b></Link> knop om opnieuw in te loggen.</Alert>
                            </Col> : <Col></Col>
                        }
                        <br />
                    </Col>
                }



            </Col>
        </div>
    );
}

