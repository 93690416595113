import React, { useState, useEffect } from "react";
import { Link, Navigate } from 'react-router-dom'; 
import { Card, CardBody, Form, FormGroup, Label, Input, Button, Alert, Col, Row} from 'reactstrap';
import { useAuth } from '../../context/auth';
import '../../custom.css'
import Axios from '../../context/axios';

export default function Login() {
    const existingTokens = JSON.parse(sessionStorage.getItem("tokens"));
    const [isError, setIsError] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [pushtoken, setPushtoken] = useState();
    const [loading, setLoading] = useState(false);
    const { setAuthTokens } = useAuth();

    async function postLogin() {

        if (existingTokens === null) {
            setLoading(true);
            
            let existingsession = sessionStorage.getItem('sessionId');
            Axios.post("/account/authenticate", {
                username,
                password,
                existingsession
            }).then(result => {
                if (result.status === 200) {
                    setPushtoken(result.data);
                    sessionStorage.setItem('tokens', JSON.stringify(result.data)); 
                } else {
                    setLoading(false);
                    setIsError(true);
                }
            }).catch(() => {
                setLoading(false);
                setIsError(true);
                
            });
            
        }
    }

    // Doing it in this manner avoids the rerendering before we get the proper answer back from the API on Azure.
    useEffect(() => {
        if (pushtoken !== undefined) {
            setAuthTokens(pushtoken);
            setLoading(false);
        }
    }, [setAuthTokens, pushtoken]);

    if (existingTokens !== null) {
        return <Navigate to="/" replace />;
    } else {
        return (
            <div>
                <Card className="loginbox">
                    <Col><b>Inloggen bij uw TOPO account</b></Col>
                    <CardBody>
                        {(isError === true) ? <Alert color="danger">Gebruikersnaam of wachtwoord zijn niet correct.</Alert> : <div></div>}
                        <Form onSubmit={() => { postLogin(); }}>
                            <FormGroup row>
                                <Col sm={2} >
                                    <Label for="lblUN"><b>Gebruikersnaam</b></Label>
                                </Col>
                                <Col>
                                    <Input type="text" autoComplete="username" value={username} onChange={e => { setUsername(e.target.value); }} placeholder="Voer hier uw gebruikersnaam in" />
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Col sm={2} >
                                    <Label for="lblPwf" ><b>Wachtwoord</b></Label>
                                </Col>
                                <Col>
                                    <Input type="password" autoComplete="current-password" value={password} onChange={e => { setPassword(e.target.value); }} placeholder="Voer hier uw Wachtwoord in" />
                                </Col>
                            </FormGroup>
                            {(loading === true) ?
                                <Row>
                                     <Label ><b>Even geduld aub...</b></Label>
                                </Row>
                                :
                                <Row>
                                    <Col sm={1}>
                                        <Button color="primary" type="submit" onClick={() => { postLogin() }}>Inloggen</Button>
                                    </Col>
                                </Row>
                                
                            }
                            <br />
                            <Row>
                                <p>Geen account bij TOPO, klik dan <Link to="/register">hier om uzelf te registeren</Link>.</p>
                                <p>Wachtwoord vergeten? Het kan de beste gebeuren, klik dan <Link to="/resetpassword">hier</Link>.</p>
                            </Row>
                        </Form>
                        
                    </CardBody>
                </Card>
                <br />
                
            </div>
        );
    }
    
}

    

