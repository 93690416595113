import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardImg, CardBody, CardText, CardTitle, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Axios from '../../context/axios';
import Spinner from '../Spinner';
import { MdAddShoppingCart, MdTrendingFlat, MdSettingsBackupRestore, MdBusiness } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../custom.css'

export function ProductList() {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uniqueCategories, setuniqueCategories]=useState([], "");
    const [uniqueBrands, setuniqueBrands]=useState([], "");
    const [uniqueColors, setuniqueColors] = useState([], "");


    // Filter features
    const [ searchValue, setSearchValue ] = useState("");
    const [ category, setCategory ] = useState("");
    const [ color, setColor ] = useState("");
    const [ brand, setBrand ] = useState("");
    const [ promotion, setPromotion] = useState("");
    
    useEffect(() => {
        async function init() {
            try {
                setLoading(true);
                const response = await Axios('/api/product/active');
                if (response.status === 200) {
                    setProducts(response.data.products);
                    setuniqueCategories([...new Set(response.data.products.map(product => product.category))]);
                    setuniqueBrands([...new Set(response.data.products.map(product => product.brand))]);
                    const getuniqueColors = ([...new Set(response.data.products.map(product => product.skus.find((s) => s.color)))]);
                    setuniqueColors([...new Set(getuniqueColors.map(skus => skus.color))]);
                } else {
                    throw response;
                }
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
            setLoading(false);
        }
        init();
    }, []);

    function setItemCount(addcnt) {
        let itemCount = 0;
        let itemAddCount = (isNaN(addcnt) ? 0 : parseInt(addcnt));
        itemCount = parseInt(sessionStorage.getItem("cartitems"));
        if (isNaN(itemCount)) {
            sessionStorage.setItem("cartitems", itemAddCount.toString());
        }
        else {
            itemCount += itemAddCount;
            sessionStorage.setItem("cartitems", itemCount.toString());
        }
    }

    async function AddToCart(e) {
        e.preventDefault();
        let request = { productId: Number(e.target.value), quantity: Number(1), action: "P" };
        let sessionId = sessionStorage.sessionId;
        if (sessionId) { request.sessionId = sessionId; }

        let result = await Axios.post('/api/cart', request);
        sessionStorage.sessionId = result.data.sessionId;
        setItemCount(1);
        
        toast.info('(weer) een artikel toegevoegd', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    }

    function renderProduct(p) {
        return (
          <Card key={p.id} className="productcard"  width="100%">
            <Link to={`/product/${p.id}`} style={{ textDecoration: 'none', color: 'black' }}>
              <div className="text-center">
                <CardImg alt={p.name} src={p.images[0]} top />
              </div>
              <CardBody>
                <CardTitle tag="h5" className="product-title"><b>{p.name}</b></CardTitle>
                <CardText className="product-description">{p.description}</CardText>
                </CardBody>
            </Link>
            {/* Button is positioned absolutely */}
            <Button
              color={p.promotion === 1 ? "warning" : "primary"}
              className="m-0" // Remove margin if it was set before
              value={p.id}
              onClick={(e) => { AddToCart(e); }}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%'
              }}
            >
                <MdAddShoppingCart />{" "}Toevoegen{" "}<MdTrendingFlat />{" "}€{" "}
                {Number(p.price).toFixed(2)}
            </Button>
          </Card>
        );
      }
      
      

    /*
original renderProduct 3 nov 2023

 function renderProduct(p) {
        return (
            <Card key={p.id} className="productcard">
                <CardBody style={{ padding: "8 16 16 0" }} >
                    <Link to={`/product/${p.id}`} style={{ textDecoration: 'none', color: "black" }}>
                        <CardImg top width="100%" center="true" className="product-img" src={p.images[0]} alt="Card image cap" />
                    <CardTitle><b>{p.name}</b></CardTitle>
                    <CardText color="black">{p.description}</CardText>
                    </Link>
                </CardBody>
                {p.promotion === 1?
                    <Button color="warning" width="75%" value={p.id} onClick={(e) => { AddToCart(e); }} ><MdAddShoppingCart />{" "}Toevoegen{" "}<MdTrendingFlat />{" "}€{" "}{Number(p.price).toFixed(2)}</Button>
                    :
                    <Button color="primary" width="75%" value={p.id} onClick={(e) => { AddToCart(e); }} ><MdAddShoppingCart />{" "}Toevoegen{" "}<MdTrendingFlat />{" "}€{" "}{Number(p.price).toFixed(2)}</Button>
                }
            </Card>
        );
    }


    */

    function FlipCategory(e)
    {
        if (category === "") { setCategory(e.target.value); }
        else {
            if (category === e.target.value) {
                setCategory(""); 
            } else { setCategory(e.target.value);  }
        }
    }

    const complexFiltered = data => {
        if (searchValue && !data.name.toLowerCase().includes(searchValue)) return false;
        if (category && data.category !== category) return false;
        if (brand && data.brand !== brand) return false;
        if (promotion && data.promotion !== (promotion === "true" ? 1 : 2)) return false;
        if (color && data.skus.find((s) => s.color !== color)) return false;
        return data.active !== 0;
    } 

    /*
    const complexPagination = () => {
        return true;
    }
    */

    const filteredProducts = products.filter(complexFiltered);
    //const paginatedProducts = filteredProducts.filter(complexPagination);
    //const paginatedProducts = filteredProducts.sort();

    const paginatedProducts = filteredProducts.sort(function (a, b) {
        if (a.sortingOrder < b.sortingOrder) { return 1; }
        if (a.sortingOrder > b.sortingOrder) { return -1; }
        return 0;
    });

    if (error) throw error;
    if (loading) return <Spinner />;

    return (
        <div>
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} />

            <Row >
                <h2 ><MdBusiness size="35" />{" "}Producten</h2><br />
                <p><b><i>TOPO Schoonmaak</i></b><i> Groothandel's middelen, materialen, machines en diversen andere artikelen voor al uw schoonmaak activiteiten.</i></p>
            </Row>
           
            <Row className="custom-col">
                <Row>
                    <br />
                </Row>
                <Row>
                    
                    <div className="bbar">
                        <Button value="" title="" className="topbuttonbar" outline color="success" size="lg"
                            onClick={() => setCategory("")} > <MdSettingsBackupRestore /></Button>

                        {uniqueCategories.map((category, key) => (
                            <Button key={key} value={category} title="" className="topbuttonbar" color="primary" size="lg" outline
                                onClick={(e) => FlipCategory(e) } >{category}</Button>
                        ))}
                    </div>
                    <br />
                    <br />
                </Row>
                <Row>
                    <Col>
                            <p><b>Zoeken</b></p>
                            <input id="searchValue" type="text" title="" value={searchValue} onChange={e => { setSearchValue(e.target.value.toLowerCase()); }} />
                    </Col>
                    <Col>
                        <p><b>Categoriëen</b></p>
                            <select id="category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="">Alle categoriëen</option>
                                {uniqueCategories.map((category, key) => (
                                    <option key={key} value={category}>{category}</option>
                                ))}
                            </select>
                    </Col>
                    <Col>
                            <p><b>Merken</b></p>
                            <select id="brand"
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <option value="">Alle merken</option>
                                {uniqueBrands.map((brand, key) => (
                                    <option key={key} value={brand}>{brand}</option>
                                ))}
                            </select>
                    </Col>
                    <Col>
                        <p><b>Kleuren</b></p>
                        <select id="color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                        >
                            <option value="">Alle kleuren</option>
                            {uniqueColors.map((color, key) => (
                                <option key={key} value={color}>{color}</option>
                            ))}
                        </select>
                    </Col>
                    <Col>
                        <p><b>Aanbieding</b></p>
                        <select id="promo"
                            value={promotion}
                            onChange={(e) => setPromotion(e.target.value)}
                        >
                            <option value="">Alle artikelen</option>
                            <option value="true">Ja</option>
                            <option value="false">Nee</option>
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>({filteredProducts.length} gevonden artikelen). Alle aanbiedingsartikelen zijn zolang de voorraad strekt. <b>Alle aangegeven prijzen zijn exclusief BTW.</b> </p>
                    </Col>
                </Row>
            </Row>
            

            <Row  className="custom-col">
                <Row>
                    <br />
                </Row>
                {paginatedProducts.map(renderProduct)}
                <Row>
                    <br />
                </Row>
            </Row>
            <Row>
                <br />
                <br />
                <br />
            </Row>
        </div>
    );
}
