import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Media, Alert } from 'reactstrap';
//import { Row, Col, Card, CardBody, CardImg, CardText, CardTitle, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Media, Alert, TabContent, NavLink, Nav, NavItem, TabPane } from 'reactstrap';
import Axios from '../../context/axios';
import { Questions } from "./questions";
import { AddToCart } from "../AddToCart";
import { FiArrowLeft } from "react-icons/fi";
//import { FiArrowLeft, FiFilm, FiCopy, FiBookOpen, FiVideo, FiPaperclip, FiCamera, FiAlertTriangle } from "react-icons/fi";
//import { MdAddShoppingCart } from "react-icons/md";
//import classnames from 'classnames';
import Spinner from '../Spinner';
import { ToastContainer } from 'react-toastify';

export default function ServicesDetails(props) {
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    //const [quantity, setQuantity] = useState(1);
    //const [sku, setSku] = useState([]);
    //const [selsku, setSelSku] = useState([]);
    const [images, setImages] = useState([]);
    //const [availablemedia, setAvailableMedia] = useState([]);
    const [availableInfo, setAvailableInfo] = useState([]);
    //const [relatedproducts, setRelatedproducts] = useState([]);
    const [stringsku, setStringSku] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    //const [activeTab, setActiveTab] = useState('1');
    const [animating, setAnimating] = useState(false);


    useEffect(() => {
        async function init(id) {
            try {
                const response = await Axios(`/api/services/${id}`);
                if (response.status === 200) {
                    console.log(response.data);
                    setProduct(response.data);
                    let skuid = id;
                    setStringSku(skuid);
                    setImages(response.data.images);
                    setAvailableInfo(response.data.largeDescription);
                    console.log(response.data);
                } else {
                    throw response;
                }
            } finally {
                setLoading(false);
            }
        }
        init(id, 0);
    }, [id]);
/*
    useEffect(() => {
        async function init(id) {
            try {
                //const response = await Axios(`/api/services/${id}`);

                let getUrl = `/api/services/${id}`;
                let ud = (JSON.parse(sessionStorage.getItem("tokens")) === null ? "" : JSON.parse(sessionStorage.getItem("tokens")).jwttoken);
                const xd = (JSON.parse(sessionStorage.getItem("tokens")) === null ? "" : JSON.parse(sessionStorage.getItem("tokens")).username);
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}`, "Username": `${xd}` } };
                const response = await Axios.get(getUrl, axiosConfig);

                if (response.status === 200) {
                    console.log(response.data);
                    setProduct(response.data);
                    let skuid = id;
                    setStringSku(skuid);
                    setImages(response.data.images);
                    setAvailableMedia(response.data.relatedMedia);
                    setRelatedproducts(response.data.relatedProducts);
                    setAvailableInfo(response.data.largeDescription);
                    
                    
                } else {
                    throw response;
                }
            } finally {
                setLoading(false);
            }
        }
        init(id, 0);
    }, [id]);
*/
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const imageSlides = images.map((m) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={m._id}
            >
                <img src={m} className="carousselcard" width="200" height="300" alt={product.name} />
            </CarouselItem>
        );
    });

    if (loading) return <Spinner />;

    return (
        <div>
            <Col id="colrefresh" className="colcontainer">
                {(product.promotion === 1) &&
                    <Alert id="promo" color="warning"><b>Aanbieding: prijzen zijn exclusief BTW en gekoppeld aan beschikbaarheid.</b></Alert>
                }
                <Link to="/services" style={{ textDecoration: 'none' }}><Row className="colcontainer"><h4><FiArrowLeft />{" "}Terug naar Dienstenlijst</h4></Row></Link>
                <Row>
                    <Col>
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                        >
                            <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {imageSlides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>

                    </Col>
                    <Col>
                        <h2>{product.name}</h2>
                        <p><b>Omschrijving</b>{" : "}{product.description}</p>
                        <p><b>Verzorgd door</b>{" : "}{product.brand}</p>
                        <p><b>Service tarief</b>{" : "}€ {Number(product.price).toFixed(2)} per uur</p>
                        {" "}
                        <AddToCart productId={stringsku} productName={product.name} />
                    </Col>
                    <hr />
                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
                </Row>
            </Col>
            <Col className="colcontainer">
                <Row>
                    
                    {(availableInfo !== null) ?
                        <Col>
                            {availableInfo.map((ai) => (
                                <Row key={ai.sequence}>
                                    {(ai.displayType === 0) && <br />}
                                    {(ai.displayType === 1) && <h6><b>{ai.infoText}</b></h6>}
                                    {(ai.displayType === 2) && <p>{ai.infoText}</p>}
                                    {(ai.displayType === 3) && <h6>{ai.infoText}</h6>}
                                    {(ai.displayType === 4) && <p><b>{ai.infoText}</b></p>}
                                    {(ai.displayType === 5) && <p>{" - "}{ai.infoText}</p>}
                                    {(ai.displayType === 6) && <p><i>{ai.infoText}</i></p>}
                                    {(ai.displayType === 20) && <Media object src={ai.infoImage} alt="img" />}
                                </Row>
                            ))}
                        </Col>
                        :
                        <Col>
                            <Row>
                                    <h6>Er is hier geen extra product informatie.</h6>
                            </Row>
                        </Col>
                    }
                   
                </Row>
                <center><p><i>Prijzen aangegeven op de TOPO website zijn altijd exclusief BTW.<br /></i></p></center>
                <br />   
                <Questions />
            </Col>
        </div>
    );
}



/*

        <Col className="colcontainer">
                     
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} ><b><FiBookOpen />{" "}Product informatie</b></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiCopy />{" "}Gerelateerde producten</b></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} ><b><FiFilm />{" "}Verdere informatie</b></NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="colcontainer">
                    <Row>
                        {(availableInfo.length > 0) ?
                            <Col>
                                    {availableInfo.map((ai) => (
                                        <Row key={ai.sequence}>
                                            {(ai.displayType === 0) && <br />}
                                            {(ai.displayType === 1) && <h6><b>{ai.infoText}</b></h6>}
                                            {(ai.displayType === 2) && <p>{ai.infoText}</p>}
                                            {(ai.displayType === 3) && <h6>{ai.infoText}</h6>}
                                            {(ai.displayType === 4) && <p><b>{ai.infoText}</b></p>}
                                            {(ai.displayType === 5) && <p>{" - "}{ai.infoText}</p>}
                                            {(ai.displayType === 6) && <p><i>{ai.infoText}</i></p>}
                                            {(ai.displayType === 20) && <Media object src={ai.infoImage} alt="img" />}
                                        </Row>
                                    ))}
                            </Col>
                            :
                            <Col>
                                <Row>
                                        <h6>Er is hier geen extra product informatie.</h6>
                                </Row>
                            </Col>
                        }
                    </Row>
                </TabPane>
                <TabPane tabId="2" className="colcontainer">
                    <Row>
                        {(relatedproducts.length > 0) ?
                                <Col>
                                    <h6>Producten die u in combinatie met of als vervanger voor dit product kunt gebruiken.</h6>

                                    <Row>
                                    {relatedproducts.map((rp) => (
                                        <Card key={rp.id} className="productcard">
                                            <CardBody >
                                            <Link to={`/product/${rp.relatedId}`} style={{ textDecoration: 'none', color: "black" }}>
                                                    <CardImg top width="100%" center="true" className="product-img" src={rp.relationImage} alt="Card image cap" />
                                                    <CardTitle><b>{rp.relationName}</b></CardTitle>
                                                    <CardText color="black">{rp.relationDescription}</CardText>
                                            </Link>
                                            </CardBody>
                                        </Card>
                                    ))}
                                    
                                </Row>
                            </Col>
                            :
                            <Row><Col>
                                <h6>Er zijn geen gerelateerde producten gekoppeld aan dit product.</h6>
                            </Col></Row>

                        }
                        
                    </Row>
                </TabPane>
                <TabPane tabId="3" className="colcontainer">
                        <Row>
                            {(availablemedia.length > 0) ?
                                <Col><Row>
                                    <h6>Handige informatie beelden, videos of documenten die u meer vertellen over dit product.</h6>
                                    <table border={0} cellPadding={10}>
                                        <thead>
                                            <tr>
                                                <td><b>Media</b></td>
                                                <td><b>Omschrijving</b> (drukken op het icoon opent een nieuw tabblad)</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                availablemedia.map((am) => (
                                                    <tr key={am.id}>
                                                        <td><center>
                                                            <a href={am.mediaLocation} target="_blank" rel="nooper noreferrer">
                                                                {(am.mediaType === 0) && <FiCamera size={50} />}
                                                                {(am.mediaType === 2) && <FiVideo size={50} />}
                                                                {(am.mediaType === 1) && <FiPaperclip size={50} />}
                                                                {(am.mediaType === 9) && <FiAlertTriangle size={50} />}
                                                            </a>
                                                        </center></td>
                                                        <td>{am.mediaName}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </Row>
                                </Col>
                                :
                                <Col>
                                <Row>
                                    <h6>Er zijn geen gerelateerde instructies of media gekoppeld aan dit product.</h6>
                                </Row>
                                </Col>
                            }
                        </Row>
                    </TabPane>
            </TabContent>
        </Col>
*/