import React, { useState, useEffect } from 'react';
import EmptyCart from './EmptyCart';
//import Survey from './Survey';
import DetailsCart from './DetailsCart';
import Axios from '../../context/axios';
//import { Row, Col, Button, Label, Input } from 'reactstrap';
//import Spinner from '../Spinner';
//import { ToastContainer, toast } from 'react-toastify';
//import { MdDelete } from "react-icons/md";

export default function Cart() {
    const [items, setItems] = useState([]);
    //const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(true);
    //const [notes, setNotes] = useState("");
    //const [validSession, setValidSession] = useState(sessionStorage.getItem('sessionId'));

    useEffect(() => {

        async function getCart() {
            if (updating) {
                let sID = sessionStorage.getItem('sessionId');
                if (sID !== null) {
                    if (sID.length >= 32) {
                        const response = await Axios(`/api/cart?sessionId=${sessionStorage.sessionId}`);
                        if (response.status === 200) {
                            setItems(response.data.items);
                            const numItemsInCart = items.reduce((total, item) => total + item.quantity, 0);
                            sessionStorage.setItem("cartitems", numItemsInCart);
                        } else {
                            setItems([]);
                        }
                    } else { setItems([]); }
                } else { setItems([]); console.log("Empty cart"); }
                setUpdating(false);
            }
            //setLoading(false);
        }
        getCart()
    }, [items, updating]);

/*    
    async function CheckOut() {
        //setLoading(true);
        if (sessionStorage.getItem('sessionId') !== null) {
            let txtaction = '@' + notes 
            let request = { productId: 0, skuId: 0, quantity: 0, action: txtaction };
            let sessionId = sessionStorage.sessionId;
            if (sessionId) { request.sessionId = sessionId; }
            let response = await Axios.post('/api/cart', request);
            if (response.status === 200) {
                //setLoading(false);
            }
        }

        //setLoading(false);
        //window.location.replace("/checkout");
    }
*/
    
    return (
        (items.length === 0
            ? 
            <EmptyCart />
            : 
            <DetailsCart />
        )
    );
}


/*
return (
        (items.length === 0
            ? 
            <EmptyCart />
            : 
            <DetailsCart />
        )
    );

*/



//                    <Col md={1}><FormGroup><Label><b>SKU</b></Label></FormGroup></Col>
//                        <Col md={1}><FormGroup className="cartform-row"><Input type="text" value={item.artNo} disabled /></FormGroup></Col>
//<Col md={1}></Col>