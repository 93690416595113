import React, { useState } from "react";
import { Alert, Col, Spinner, Button, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MdInput } from "react-icons/md";
import Axios from '../../context/axios';

export default function Activation() {
    const [actcode, setActcode] = useState("");
    const [details, setDetails] = useState({});
    const [okActivation, setOKActivation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errActivation, setErrActivation] = useState(false);

    async function ValidateActivation(e) {
        if (actcode.length >= 5) {
            try {
                setLoading(true);
                const response = await Axios(`account/${actcode}/activation`);
                if (response.status === 200) {
                    setDetails(response.data);
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    setLoading(false);
                    if (details.firstName === "first_failed") {
                        setErrActivation(true);
                        setOKActivation(false);
                    } else {
                        setOKActivation(true);
                        setErrActivation(false);
                    }
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    
                } else {
                    setErrActivation(true);
                    await new Promise(resolve => setTimeout(resolve, 10000));
                    setErrActivation(false);
                }
            } finally {
                setLoading(false);
            }
        }
        else {
            setErrActivation(true);
            await new Promise(resolve => setTimeout(resolve, 10000));
            setErrActivation(false);
        }
    }

    function handleChange(e) {
        setActcode(e.target.value);
    };

    return (
        <div>
            <Col className="colcontainer">
                <h4><b>Activeren van uw TOPO Account</b></h4>

                {details &&
                    <Col sm={8}>
                        <br />
                        <Label for="city" sm={8}>Uw activatie code: </Label>
                            <Col sm={8}>
                        <Input type="text" className="form-control" onChange={handleChange} value={actcode} id="id" />
                        <br />
                                <Button color="primary" onClick={(e) => { ValidateActivation(e); }}>{" "}Valideren Activatie code</Button>
                            </Col>
                        <br />
                    </Col> 
                }

                {loading &&
                    <Col>
                        <br />
                        <Alert color="warning">We zijn uw activatie aan het controleren. Een moment geduld alstublieft.</Alert>
                        <Spinner style={{ width: '3rem', height: '3rem', color: '#009FD6' }} />
                        <br />
                    </Col> 
                }

                
                {!loading &&
                    <Col>
                        <br />
                    {errActivation ?
                        <Col>
                            <Alert color="danger">Er is iets fout gegaan bij uw activatie. Probeert u het opnieuw over 1 minuut en neem anders contact op met TOPO.</Alert>
                        </Col> : <Col></Col> 
                    }
                    {okActivation ? 
                        <Col>
                            <Alert color="success"><h4 className="alert-heading">Welkom {details.firstName} {details.lastName},</h4><p>U bent succesvol geactiveerd. Druk op de <Link to="/login" className="alert-link"><b><MdInput /> Inloggen</b></Link> knop om in te loggen.</p></Alert>
                        </Col> : <Col></Col> 
                    }
                        <br />
                    </Col>
                }

                
                
            </Col>
        </div>
    );
}

