import React, { useState, useEffect } from "react";
import { Col, Button, Spinner } from 'reactstrap';
import MainUserAddressClass from './MainUserAddressClass';
import { toast } from 'react-toastify';
import Axios from '../../context/axios';


export default function MainUserAddress(props) {

    const [addresses, setAddresses] = useState([]);
    const [currentAddress, setCurrentAddress] = useState([]);
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [editmode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setAddresses(props.adrdata);
    }, [props.adrdata]);




    const selectAddress = (e) => {
        setCurrentAddress([]);
        let adrsku = addresses.filter(address => address.id === Number(e.target.value));
        let addressstring = adrsku[0].line1;
        setCurrentAddress(adrsku[0]);

        toast.info(`Adresgegevens van ${addressstring} geselecteerd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    };

    async function onCancelAddressData(e) {
        setLoading(true);
        // Update table by adjusting addresses....
        toast.info(`Adresgegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentAddress([]);
        setLoading(false);
    };


    async function onUpdateAddress(e) {
        if (loading === false) {
            setLoading(true);
            try {
                let updatedData = JSON.stringify({ currentAddress });

                var data = {
                    plu: updatedData,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'AU'
                }
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== null) && (userDetails !== null)) {
                    sessionStorage.sessionId = sessionId;
                    let result = await Axios.post('/api/prefuser', data, axiosConfig);
                    if (result.status === 200) {
                        toast.info(`Adresgegevens bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        setAddresses(result.data.addresses);
                        setLoading(false);
                    } else {
                        toast.error(`Adresgegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }

                // Update table by adjusting addresses....
                setCurrentAddress([]);
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                toast.error(`Er is een fout opgetreden bij het opslaan van adresgegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            setLoading(false);
        }
    };

    async function onCreateAddress(e) {
        if (loading === false) {
            setLoading(true);
            try {
                if (sessionStorage.sessionId !== undefined) {
                    sessionStorage.sessionId = "No Session yet";
                }
                var data = {
                    plu: e.target.value,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'AC'
                }
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== null) && (userDetails !== null)) {
                    sessionStorage.sessionId = sessionId;
                    let result = await Axios.post('/api/prefuser', data, axiosConfig);
                    if (result.status === 200) {
                        toast.info(`Nieuwe adresgegevens toegevoegd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        setAddresses(result.data.addresses);
                        setLoading(false);
                    } else {
                        toast.error(`Nieuwe adresgegevens niet toegevoegd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }
            } catch (ex) {
                console.log(ex);
                toast.error(`Er is een fout opgetreden bij het toevoegen van nieuwe adresgegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            setLoading(false);
        }
    };

    const deleteAddress = async (e) => {
        if (loading === false) {
            setLoading(true);
            if (addresses.length > 1) {
                try {
                    var data = {
                        plu: e.target.value,
                        sessionId: sessionStorage.sessionId,
                        username: userDetails.username,
                        token: userDetails.jwttoken,
                        action: 'AD'
                    }
                    let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                    let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                    let result = await Axios.post('api/prefuser', data, axiosConfig);
                    if (result.status === 200) {
                        toast.info(`Adresgegevens verwijderd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        setAddresses(result.data.addresses);
                        setLoading(false);
                    }
                    else {
                        toast.warning(`Probleem bij het verwijderen van adresgegevens. Raadpleeg uw Administrator`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }

                } catch (ex) {
                    console.log(ex);
                    toast.error(`Fout bij het verwijderen van adresgegevens. Raadpleeg uw Administrator`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            }
            else {
                toast.warning(`Er dient altijd één set adresgegevens te zijn.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            setLoading(false);
        }
    };

    function handleAddressDetailsUpdated(newAddressDetails) {
        setCurrentAddress(newAddressDetails);
    }

    return (
        <Col>
            <br />
            <h5>Beschikbare adressen </h5>

            <table border={2} cellPadding={5}>
                <thead>
                    <tr>
                        <td><b>Type</b></td>
                        <td><b>Voorkeur</b></td>
                        <td><b>Adres regel 1</b></td>
                        <td><b>Adres regel 2</b></td>
                        <td><b>Postcode</b></td>
                        <td><b>Stad</b></td>
                        <td><b>Actief</b></td>
                        <td><b>Functies</b></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        addresses.map((s) => (
                            <tr key={s.id}>
                                <td>{s.type === "Billing" ? "Factuuradres" : "Bezorgadres"}</td>
                                <td>{s.default === true ? "Ja" : "Nee"}</td>
                                <td>{s.line1}</td>
                                <td>{s.line2}</td>
                                <td>{s.postcode}</td>
                                <td>{s.city}</td>
                                <td>{s.active === true ? "Ja" : "Nee"}</td>
                                <td><Button color="danger" value={s.id} disabled={editmode} size="sm" onClick={(e) => { deleteAddress(e); }} >Verwijder</Button>{" "}-{" "}<Button color="warning" value={s.id} disabled={editmode} size="sm" onClick={(e) => { selectAddress(e); setEditMode(true); }} >Aanpassen</Button></td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <br />
            {currentAddress.length !== 0 &&
                <MainUserAddressClass editaddress={currentAddress} onChanged={handleAddressDetailsUpdated} />
            }

            {currentAddress.length !== 0 &&
                <div>
                {(loading === true) ?
                    <div>
                        <Spinner size="sm" color="secondary" /><p>Momentje geduld aub...</p>
                        <Button color="secondary" disabled>Inloggen</Button>
                    </div>
                :
                    <Col>
                            <Button color="primary" onClick={(e) => { onCancelAddressData(e); setEditMode(false); }} >Annuleren</Button>
                            {" "}
                            <Button color="primary" onClick={(e) => { onUpdateAddress(e); setEditMode(false); }} >Opslaan gewijzigde adres</Button>
                    </Col>
                    
                }
                </div>
            }
            {currentAddress.length === 0 &&
                <Button color="primary" onClick={(e) => { onCreateAddress(e); }} >Toevoegen nieuwe adres</Button>
            }

            
        </Col>
    );

}
