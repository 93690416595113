import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import '../../custom.css';

const StarRating = ({ question, onRating }) => {
  const [rating, setRating] = useState(0);

  // Handle click on star
  const handleClick = (index) => {
    setRating(index);
    onRating(index); // pass the rating value up to the parent component
  };

  return (
    <div className="starcontainer">
      <h4>{question}</h4>
      {[...Array(5)].map((_, index) => {
        return (
            <FaStar key={index}
                    color={index < rating ? "#F58B25" : "#e4e5e9"}
                    size = {40}
                    onClick={() => handleClick(index + 1)} />
            
        );
      })}
    </div>
  );
};

export default StarRating;


/*
<span 
            
            key={index} 
            className={`star ${index < rating ? "filled" : ""}`}
            onClick={() => handleClick(index + 1)}>
            &#9733;
          </span>
*/