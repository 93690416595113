import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import MainUserPreferences from './MainUserPreferences';

function LoggedOnUser() {
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));

    if (userDetails !== null) {
        return (
            <MainUserPreferences/> 
        )
    }
    else {
        return <Navigate to="/login" replace />;
    }
       
}

export default LoggedOnUser;
