import React, { useState, useEffect } from "react";
import { Col, Row, Label, Input } from 'reactstrap';
import Axios from "../../../context/axios";

export default function TopProductsList() {
    const [productAnalytics, setProductAnalytics] = useState([]);
    
    useEffect(() => {
        async function init() {
            try {
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl = '/api/prefadmin/toppro/' + xd;
                const response = await Axios.get(runUrl, axiosConfig);
                if (response.status === 200) {
                    setProductAnalytics(response.data);
                    //console.log("TopProductsList");
                    //console.log(response.data);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);

    return (
        <div>
            <Col>
                <Row>
                    <p>Op deze pagina vindt U een overzicht van de meest <b><u>bestelde</u></b> producten bij TOPO. Ook kunt u op deze pagina zien
                        <br />of deze producten via een ingelogde (en geregistreerde) klant is gedaan of door een klant die besteld heeft zonder in te loggen.
                        <br />Als niet ingelogde klanten veel bestellen, dan lopen ze handige zaken mis in de webshop waarmee we ze kunnen binden en het bestel-
                        <br />proces makkelijker kunnen maken.
                    </p>
                </Row>
                <Row >
                    <Col md={6}><Label><b>Product Gezien</b></Label></Col>
                    <Col md={1}><Label><b>Totaal</b></Label></Col>
                    <Col md={1}><Label><b>Anoniem</b></Label></Col>
                    <Col md={1}><Label><b>Aangemeld</b></Label></Col>
                </Row>
                {productAnalytics.map((sl) => (
                    <Row key={sl.product}>
                        <Col md={6}><Input type="text" value={sl.productName} disabled /></Col>
                        <Col md={1}><Input type="text" value={sl.hits} disabled /></Col>
                        <Col md={1}><Input type="text" value={sl.anom} disabled /></Col>
                        <Col md={1}><Input type="text" value={sl.auth} disabled /></Col>

                    </Row>
                ))
                }
            </Col>
        </div>
    );

}


