import React from "react";
import { Col, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";

export default class MainUserCustomerAdminClass extends React.Component {


    state = {
        id: this.props.cusdata.id,
        organisation: this.props.cusdata.organisation,
        commercenumber: this.props.cusdata.commerceNumber,
        customernumber: this.props.cusdata.customerNumber,
        vatnumber: this.props.cusdata.vatNumber,
        firstName: this.props.cusdata.firstName,
        lastName: this.props.cusdata.lastName,
        email: this.props.cusdata.email,
        default: this.props.cusdata.default,
        validate: {
            emailState: '',
            vatnumberState: '',
            commercenumberState: ''
        },
    };


    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateVATNumber(e) {
        const { validate } = this.state
        if (e.target.value.length >= 14) {
            validate.vatnumberState = 'has-success'
        } else {
            validate.vatnumberState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateCommerceNumber(e) {
        const { validate } = this.state
        if (e.target.value.length === 8) {
            validate.commercenumberState = 'has-success'
        } else {
            validate.commercenumberState = 'has-danger'
        }
        this.setState({ validate })
    }

    handleChange(newState) {
        this.setState(newState, () => {
            if (this.props.onChanged)
                this.props.onChanged(this.state);
        });
    }


    render() {
        return (
            <Form>

                <FormGroup row>
                    <Label for="organisation" sm={2}>Organisatie</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="organisation"
                            value={this.state.organisation}
                            onChange={event => this.handleChange({ organisation: event.target.value })}
                            placeholder="Organisatie" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="commercenumber" sm={2}>KvK</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="commercenumber"
                            value={this.state.commercenumber}
                            invalid={this.state.validate.commercenumberState === 'has-danger'}
                            onChange={(event) => {
                                this.validateCommerceNumber(event)
                                this.handleChange({ commercenumber: event.target.value })
                            }
                            }
                            placeholder="Kamer van Koophandel nummer" />
                        <FormFeedback invalid>
                            Kamer van Koophandel nummer is een verplicht veld en wordt gecontroleerd op correctheid.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="vatnumber" sm={2}>BTW</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="vatnumber"
                            value={this.state.vatnumber}
                            invalid={this.state.validate.vatnumberState === 'has-danger'}
                            onChange={(event) => {
                                this.validateVATNumber(event)
                                this.handleChange({ vatnumber: event.target.value })
                            }
                            }
                            placeholder="BTW Nummer" />
                        <FormFeedback invalid>
                            BTW nummer is een verplicht veld en wordt gecontroleerd op correctheid.
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="customernumber" sm={2}>Klant Nummer</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="customernumber"
                            value={this.state.customernumber}
                            onChange={(event) => this.handleChange({ customernumber: event.target.value })}
                            placeholder="Klant Nummer" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="firstName" sm={2}>Voornaam</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="firstName"
                            value={this.state.firstName}
                            onChange={event => this.handleChange({ firstName: event.target.value })}
                            placeholder="Voornaam" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="lastName" sm={2}>Achternaam</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="lastName"
                            value={this.state.lastName}
                            onChange={event => this.handleChange({ lastName: event.target.value })}
                            placeholder="Achternaam" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="email" sm={2}>Email</Label>
                    <Col sm={6}>
                        <Input
                            type="text"
                            id="email"
                            value={this.state.email}
                            invalid={this.state.validate.emailState === 'has-danger'}
                            onChange={(event) => {
                                this.validateEmail(event)
                                this.handleChange({ email: event.target.value })
                            }
                            }
                            placeholder="Email" />
                        <FormFeedback invalid>
                            Email veld mag niet leeg zijn en dient ook te voldoen aan de specificaties van een email adres.
                        </FormFeedback>
                    </Col>

                </FormGroup>
            </Form>
        )
    }
}
/*
 <FormGroup row>
                    <Label for="default" sm={2}>Voorkeur</Label>
                    <Col sm={6}>
                        <Input type="select" id="default" value={this.state.default}
                            onChange={event => this.handleChange({ default: event.target.value })}
                            placeholder="Voorkeur" >
                            <option value='true'>Ja</option>
                            <option value='false'>Nee</option>
                        </Input>
                    </Col>
                </FormGroup>
*/