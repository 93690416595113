import React from "react";
import { Col, Row } from 'reactstrap';
import SurveyForm from './SurveyForm';
import surveyimage from '../../assets/images/surveyimage.jpg';
import '../../custom.css';

export default function Survey() {

    return (
        <Col className="custom-col">
            <Row className="component-center">
                <br />
                <h1>Enquete</h1>
            </Row>
            <Row >
                
                <Col className="survey-left">
                    <Row className="component-center" >
                        
                        <img src={surveyimage} width="100px" alt="Customer Service" />
                        <h6>We willen u graag een paar korte vragen stellen om de gebruikerservaring van onze webshop te verbeteren.</h6>
                        <p></p>
                        <h6>Alvast bedankt voor uw medewerking.</h6>
                        <br />

                    </Row>
                </Col>
                <Col className="survey-right">
                    
                    <SurveyForm />
                </Col>
            </Row>
            <Row>
                <Col className="component-center">
                    <p> </p>   
                </Col>
            </Row>
        </Col>
    );
}
