import React from "react";
import AvailableDetails from '../About/AvailableDetails';


export function Questions() {

    return (
        <div>
            <h5>Toch nog verdere vragen over onze producten?</h5>
            <p>U kunt ons bereiken tijdens openingstijden via de volgende mogelijkheden:</p>
            <AvailableDetails/>
        </div>
    );

}