import React from 'react';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';

export default class MaintainProductDetailsSKUClass extends React.Component {

    state = {
        skuid: Number(this.props.editSku.skuid),
        skustring: this.props.editSku.skuString,
        skuproductdescription: this.props.editSku.skuProductDescription,
        color: this.props.editSku.color,
        price: this.props.editSku.price,
        volumedescription: this.props.editSku.volumeDescription,
        volumesize: Number(this.props.editSku.volumeSize),
        packaging: this.props.editSku.packaging,
        productsperpackage: Number(this.props.editSku.productsPerPackage),
        promotion: (this.props.editSku.promotion ? "true" : "false"),
        promotionprice: Number(this.props.editSku.promotionPrice),
        productid: Number(this.props.editSku.productId),
        instock: Number(this.props.editSku.inStock),
        active: (this.props.editSku.active ? "true" : "false"),
        errors: {
            skustring: '',
            skuproductdescription: '',
            price: '',
            promotionprice: '',
            volumesize: '',
            productsperpackage: '',
        },
        isFormValid: false
    };

    handleChange = (newState) => {
        if (newState !== undefined) {
            this.setState(newState, () => {
                this.validateForm();
                if (this.props.onChanged)
                    this.props.onChanged(this.state);
            });
        } else {
            this.validateForm();
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.handleChange({ [name]: value });
    }

    validateForm = () => {
        const { skustring, skuproductdescription, price, promotionprice, volumesize, productsperpackage } = this.state;
        let errors = {};
        let isValid = true;

        if (!skustring || !skustring.trim()) {
            errors.skustring = 'SKU is een verplicht veld. Voer aub een naam in.';
            isValid = false;
        }

        if (!skuproductdescription || !skuproductdescription.trim()) {
            errors.skuproductdescription = 'SKU Omschrijving is een verplicht veld. Voer aub een merk in.';
            isValid = false;
        }

        if (!price) {
            errors.price = 'Prijs is een verplicht veld';
            isValid = false;
        } else {
            // Price format validation (dot as decimal separator)
            const priceRegex = /^\d+(?:.\d{1,2})?$/;
            if (!priceRegex.test(price)) {
                errors.price = 'Voer een correcte prijs in (gebruik punt als decimaalteken, bijv. 10.99)';
                isValid = false;
            }
        }

        if (!promotionprice) {
            errors.promotionprice = 'Promotie Prijs is een verplicht veld';
            isValid = false;
        } else {
            // Price format validation (dot as decimal separator)
            const priceRegex = /^\d+(?:.\d{1,2})?$/;
            if (!priceRegex.test(promotionprice)) {
                errors.promotionprice = 'Voer een correcte promotie prijs in (gebruik punt als decimaalteken, bijv. 10.99)';
                isValid = false;
            }
        }

        if (!volumesize) {
            errors.volumesize = 'Volume grootte is een verplicht veld. Voer aub de volume grootte in. Bijvoorbeeld voor een kan is dat 5 liter.';
            isValid = false;
        } else {
            // Price format validation (dot as decimal separator)
            const priceRegex = /^\d+(?:.\d{1,2})?$/;
            if (!priceRegex.test(volumesize)) {
                errors.volumesize = 'Volume grootte is een verplicht veld. Voer aub de volume grootte in. Bijvoorbeeld voor een kan is dat 5 liter.';
                isValid = false;
            }
        }

        if (!productsperpackage) {
            errors.productsperpackage = 'Verpakkingseenheid is een verplicht veld. Voer aub het aantal eenheden in per verpakking. Bijvoorbeeld per doos 6 flessen, dan kies je 6.';
            isValid = false;
        } else {
            // Price format validation (dot as decimal separator)
            const priceRegex = /^\d+(?:.\d{1,2})?$/;
            if (!priceRegex.test(productsperpackage)) {
                errors.productsperpackage = 'Verpakkingseenheid is een verplicht veld. Voer aub het aantal eenheden in per verpakking. Bijvoorbeeld per doos 6 flessen, dan kies je 6.';
                isValid = false;
            }
        }


        this.setState({ errors, isFormValid: isValid });
    }



    render() {
        return (
            <Form >
                <FormGroup row className="slimFormRow">
                    <Label for="skustring" sm={3}>SKU</Label>
                    <Col sm={2}>
                        <Input type="text" id="skustring" value={this.state.skustring}
                            onChange={event => this.handleChange({ skustring: event.target.value })}
                            placeholder="SKU" 
                            required />
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="skuproductdescription" sm={3}>SKU Product Description</Label>
                    <Col sm={2}>
                        <Input type="text" id="skuproductdescription" value={this.state.skuproductdescription}
                            onChange={event => this.handleChange({ skuproductdescription: event.target.value })}
                            placeholder="SKU Product Description" 
                            required />
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="color" sm={3}>Kleur</Label>
                    <Col sm={6}>
                        <Input type="select" id="color" value={this.state.color}
                            onChange={event => this.handleChange({ color: event.target.value })}
                            placeholder="Kleur">
                            <option value="">Onbekend</option>
                            <option value="Blauw">Blauw</option>
                            <option value="Bruin">Bruin</option>
                            <option value="Geel">Geel</option>
                            <option value="Grijs">Grijs</option>
                            <option value="Groen">Groen</option>
                            <option value="Kleurloos">Kleurloos</option>
                            <option value="Maroon">Maroon</option>
                            <option value="Oranje">Oranje</option>
                            <option value="Paars">Paars</option>
                            <option value="Rood">Rood</option>
                            <option value="Wit">Wit</option>
                            <option value="Zwart">Zwart</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="price" sm={3}>Prijs</Label>
                    <Col sm={3}>
                        <Input type="number" id="price" value={this.state.price}
                            onChange={event => this.handleChange({ price: event.target.value })}
                            placeholder="Prijs (bijv. 10.99)"
                            required />
                        {this.state.errors.price && <div className="text-danger">{this.state.errors.price}</div>}
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="promotion" sm={3}>Aanbieding</Label>
                    <Col sm={3}>
                        <Input type="select" id="promotion" value={this.state.promotion}
                            onChange={event => this.handleChange({ promotion: event.target.value })} placeholder="Aanbieding" >
                            <option value="true">Ja</option>
                            <option value="false">Nee</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="promotionprice" sm={3}>Aanbiedingsprijs</Label>
                    <Col sm={3}>
                        <Input type="number" id="promotionprice" value={this.state.promotionprice}
                            onChange={event => this.handleChange({ promotionprice: event.target.value })}
                            placeholder="Aanbiedingsprijs (bijv. 10.99)"
                            required/>
                        {this.state.errors.promotionprice && <div className="text-danger">{this.state.errors.promotionprice}</div>}
                    </Col>
                </FormGroup>
                <Label for="vd1" ><b><i>Eerst Volume Inhoud kiezen kiezen en dan bijbehorende grootte</i></b></Label>
                <FormGroup row className="slimFormRow">
                    <Label for="volumedescription" sm={3}>Volume Inhoud</Label>
                    <Col sm={6}>
                        <Input type="select" id="volumedescription" value={this.state.volumedescription}
                            onChange={event => this.handleChange({ volumedescription: event.target.value })}
                            placeholder="Volume Inhoud" >
                            <option value="ml">milliliter (ml)</option>
                            <option value="lt">liter (lt)</option>
                            <option value="kg">kilo (kg)</option>
                            <option value="other">divers</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="volumesize" sm={3}>Volume grootte</Label>
                    <Col sm={3}>
                        <Input type="number" id="volumesize" value={this.state.volumesize}
                            onChange={event => this.handleChange({ volumesize: event.target.value })}
                            placeholder="Volume grootte" 
                            required />
                        {this.state.errors.volumesize && <div className="text-danger">{this.state.errors.volumesize}</div>}
                    </Col>
                </FormGroup>
                <Label for="vd" ><b><i>Verpakkingstype selecteren en dan aantal per verpakking</i></b></Label>
                <FormGroup row className="slimFormRow">
                    <Label for="packaging" sm={3}>Verpakking</Label>
                    <Col sm={6}>
                        <Input type="select" id="packaging" value={this.state.packaging}
                            onChange={event => this.handleChange({ packaging: event.target.value })}
                            placeholder="Verpakking">
                            <option value="Doos">Doos</option>
                            <option value="Baal">Baal</option>
                            <option value="Pak">Pak</option>
                            <option value="Pallet">Pallet</option>
                            <option value="Stuk">Stuk</option>
                            <option value="Fles">Fles</option>
                            <option value="kan">Kan</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="productsperpackage" sm={3}>Eenheden</Label>
                    <Col sm={3}>
                        <Input type="number" id="productsperpackage" value={this.state.productsperpackage}
                            onChange={event => this.handleChange({ productsperpackage: event.target.value })}
                            placeholder="Verpakkingseenheden" 
                            required />
                        {this.state.errors.productsperpackage && <div className="text-danger">{this.state.errors.productsperpackage}</div>}
                    </Col>
                </FormGroup>
               
            </Form>
        )
    }
}
