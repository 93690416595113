import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button } from 'reactstrap';
import Axios from '../../context/axios';
import { ToastContainer, toast } from 'react-toastify';
import MaintainSliderClass from "./MaintainSliderClass";

export default function MaintainSliders() {
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [sliders, setSliders] = useState([]);
    const [currentSlider, setCurrentSlider] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editmode, setEditMode] = useState(false);

    useEffect(() => {
        async function init() {
            try {
                setLoading(false);
                let runUrl = '/api/product/sliders';
                const response = await Axios(runUrl);
                if (response.status === 200) {
                    setSliders(response.data);
                    setLoading(false);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);


    async function onCancelSliderData(e) {

        setLoading(true);
        // Update table by adjusting addresses....
        toast.info(`Slider gegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentSlider([]);
        setLoading(false);
    };



    async function onUpdateSliderData(e) {
        if (loading === false) {
            setLoading(true);
            try {
                let updatedData = JSON.stringify({ currentSlider });
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let runUrl = '/api/prefadmin/' + xd;
                var data = {
                    plu: updatedData,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'SU'
                }
                //console.log("Sending to API: ", data)
                
                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== null) && (userDetails !== null)) {
                    sessionStorage.sessionId = sessionId;
                    let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                    let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                    let result = await Axios.post(runUrl, data, axiosConfig);
                    
                    if (result.status === 200) {
                        setCurrentSlider([]);
                        toast.info(`Slider gegevens bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        let runUrl = '/api/product/sliders';
                        const response = await Axios(runUrl);
                        if (response.status === 200) {
                            setSliders(response.data);
                            setLoading(false);
                        }

                    } else {
                        toast.error(`Slider gegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }
                
                // Update table by adjusting addresses....
                setEditMode(false);
                setLoading(false);

            } catch (ex) {
                //console.log(ex);
                toast.error(`Er is een fout opgetreden bij het opslaan van slider gegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
        }
    };

    const selectSliderData = (e) => {
        setEditMode(true);
        let cursku = sliders.filter(slider => slider.id === Number(e.target.value));
        let sliderstring = cursku[0].name;
        setCurrentSlider(cursku[0]);

        toast.info(`Gegevens van ${sliderstring} geselecteerd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    };

   
    function handleSliderDetailsUpdated(newSliderDetails) {
        setCurrentSlider(newSliderDetails);
        
    };

    /*
    function convertToDateString(datetimeStr) {
        const date = new Date(datetimeStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
*/

    return (
        <div>
            <Col>
                <br />
                <Row>
                    {(currentSlider.length === 0) ?
                        <Table cellPadding={10} >
                        <thead>
                            <tr>
                                <td><b>Referentie</b></td>
                                <td><b>Omschrijving</b></td>
                                <td><b>Preview</b></td>
                                
                                <td><b>Functies</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sliders.map((c) => (
                                    <tr key={c.id}>
                                        <td>{c.name}</td>
                                        <td>{c.description}</td>
                                        <td><img width="500px" height="200px" src={c.image} alt={c.name} /></td>

                                        <td><Button color="warning" value={c.id} disabled={editmode} size="sm" onClick={(e) => { selectSliderData(e); setEditMode(true); }}>Aanpassen</Button></td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    : 
                    <Col>
                        <h6><b>Onderhouden Slider gegevens</b></h6>
                    </Col>
                    }
                    <br />

                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
                    
                    <br />
                    {(currentSlider.length !== 0) ?
                        <MaintainSliderClass editslider={currentSlider} onChanged={handleSliderDetailsUpdated} />
                        :
                        <p></p>
                    }
                        
                    { ((currentSlider.length !== 0) && (editmode === true)) ?
                            <Col>
                                <br />
                                <Button color="primary" onClick={(e) => { onCancelSliderData(e); setEditMode(false); }} >Annuleren</Button>
                                {" "}
                                <Button color="primary" onClick={(e) => { onUpdateSliderData(e); setEditMode(false); }} >Opslaan Slider</Button>
                            </Col>
                        : <p></p>
                    }

                    { (editmode === false && (currentSlider.length !== 0)) ?
                            <Col>
                                <br />
                                <Button color="primary" disabled >Annuleren</Button>
                                {" "}
                                <Button color="primary" disabled >Opslaan Slider gegevens</Button>
                                <p><i>Momentje geduld aub. We werken de gegevens bij. Dit kan een paar seconden duren...<br />We geven een melding rechtsboven in het scherm als het gelukt is.</i></p>
                            </Col>
                        : <p></p>
                    }
                </Row>
                <Row>
                    <br />
                </Row>
            </Col>
            <Col>
                <br />
            </Col>
        </div>
    );

}

/*
<td><b>Datum Van</b></td>
<td><b>Datum Tot</b></td>
<td><b>Actief?</b></td>

<td>{c.startDate}</td>
<td>{c.endDate}</td>
<td>{c.Active}</td>
*/