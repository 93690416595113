import React, { useState, useEffect } from "react";

const OrderSummary = (props) => {

    const [items, setItems] = useState([]);
    const numItemsInCart = items.reduce((total, item) => total + item.quantity, 0);
    const TotalCostItemsInCart = items.reduce((total, item) => total + (item.quantity * item.price), 0);

    useEffect(() => {
        setItems(props.items);
    },[props.items]);

    return (
        <div>
            <h3>Uw order</h3>
            {numItemsInCart} artikelen <br />
            Total: € {TotalCostItemsInCart.toFixed(2)} <br />
            <br />
            <table>
                <thead>
                    <tr><th>Aantal</th><th>Artikel</th></tr>
                </thead>
                <tbody>
                {items.map((item) => (
                <tr key={item.skuId}>
                        <td>{item.quantity}{" x "}</td><td>{item.name}</td>
                </tr>    
                ))}
                </tbody>
            </table>
            <br />
            <p><i>Vermeldde totalen zijn exclusief BTW en verzendkosten</i></p>
            <br />

        </div>
    )

}
export default OrderSummary;