import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import AvailableDetails from '../About/AvailableDetails';
import logopioneer from '../../assets/images/dealers/pioneereclipse-logo.png';
import logodeb from '../../assets/images/dealers/deb-logo.png';
import logodeiss from '../../assets/images/dealers/deiss-logo.jpg';
import logonilfisk from '../../assets/images/dealers/nilfisk-logo.png';
import logopapernet from '../../assets/images/dealers/papernet-logo.jpg';
import './frontpage.css';


class BrandLogoSlider extends Component {
    render() {
        return (
            <Col className="custom-col p-3">
                
                <div className="inner-intro">
                    <center>
                        <h4> 
                            <strong>TOPO Schoonmaak Groothandel B.V.</strong> is geauthoriseerd dealer van oa.:
                        </h4>
                    </center>
                </div>
                
                <Row>
                    <Col>
                        <center>
                            <img className="img-fluid mx-auto logovendor-img" src={logopioneer} width="100%" height="100px" alt="clients-logo" />
                            <center><p><strong>dealer BeNeLux</strong></p></center>
                        </center>
                    </Col>
                    <Col>
                        <img className="img-fluid mx-auto logovendor-img" src={logodeb} width="100%" height="100px" alt="clients-logo" />
                    </Col>
                    <Col>
                        <img className="img-fluid mx-auto logovendor-img" src={logodeiss} width="100%" height="100px" alt="clients-logo" />
                    </Col>
                    <Col>
                        <img className="img-fluid mx-auto logovendor-img" src={logonilfisk} width="100%" height="100px" alt="clients-logo" />
                    </Col>
                    <Col>
                        <img className="img-fluid mx-auto logovendor-img" src={logopapernet} width="100%" height="100px" alt="clients-logo" />
                    </Col>
                </Row>
                <Col className="colcontainer">
                    <hr /> 
                    <AvailableDetails />
                </Col>

                
            </Col>
        )
    }
}

export default BrandLogoSlider;

/*
<Col>
                            <center><p><strong>dealer voor Nederland, Belgie en Luxemburg</strong></p></center>
                        </Col>
"img-fluid mx-auto logovendorsq-img"

<Row>
                        <Col>
                            <center><p><strong>dealer BeNeLux</strong></p></center>
                        </Col>
                        <Col>
                            <p></p>
                        </Col>
                        <Col>
                            <p></p>
                        </Col>
                        <Col>
                            <p></p>
                        </Col>
                        <Col>
                            <p></p>
                        </Col>
                    </Row>
*/