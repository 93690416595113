import React from 'react';
import { Col, Row } from 'reactstrap';
//import LocationMaps from './LocationMaps';
import LocationTopo from '../../assets/images/banners/VoorkantTOPO.jpg'
import ShopTopo from '../../assets/images/banners/OverzichtShowroom.jpg'
import ShowRoom from '../../assets/images/banners/ShowroomBalie.jpg'
import ShowroomKast from '../../assets/images/banners/ShowroomKast.jpg'
import AvailableDetails from './AvailableDetails';
import MandatoryDetails from '../MandatoryDetails';
import '../../custom.css'


export default function About() {
    return (
        <div >
            <Col className="custom-col">
                <Row>
                    <br />
                </Row>
                <Row>
                    <Col className='my-2 mx-2'>
                        <h2 className="font-bold" color="#009fd6">TOPO <b>Schoonmaak</b> Groothandel</h2>
                    </Col>
                </Row>
                
                <Row>
                    <Col className="width=50%">
                        <Col className='my-2 mx-2'>
                            <img src={LocationTopo} className="img-fluid custom-rounded-img" alt="" />
                        </Col>
                    </Col>
                    <Col className="mt-4 mt-lg-0 width=50%">
                        <br />
                        <h5 style={{ fontWeight: 'normal' }}>TOPO is een gespecialiseerde groothandel in schoonmaakproducten en biedt een uitgebreid assortiment aan schoonmaakmiddelen, materialen en machines. Als erkende dealer van gerenommeerde merken zoals Pioneer-Eclipse, Deiss, Papernet en Nilfisk, evenals Numatic, Cleanfix, Tana Werner en Metz, garanderen wij producten van hoge kwaliteit. <br />Wij nodigen u graag uit om een kijkje te nemen in onze webshop en ons complete aanbod te ontdekken.                        </h5>
                    </Col>
                </Row>
                <Row>
                    <br/>
                </Row>
                <Row>
                    <Col className="mt-4 mt-lg-0 width=50%">
                        <Col className='my-2 mx-2'>
                        <br />
                        <h5 style={{ fontWeight: 'normal' }}>Daarnaast omvat ons assortiment specialistische beschermingsmiddelen, zoals desinfectiezuilen, desinfectiemiddelen, beschermingspakken, handschoenen en mondkapjes. 
                                <br />
                            Wij kunnen uw organisatie volledig voorzien in alle schoonmaak- en beschermingsbehoeften.
                            </h5>
                        </Col>
                        
                    </Col>
                    <Col className="width=50%">
                        <Col className='my-2 mx-2'>
                            <img src={ShowRoom} className="img-fluid custom-rounded-img" alt="" />
                        </Col>

                    </Col>
                </Row>
                <Row>
                    <br />
                </Row>
                <Row>
                    <Col className="width=50%">
                        <Col className='my-2 mx-2'>
                            <img src={ShopTopo} className="img-fluid custom-rounded-img" alt="" />
                        </Col>
                    </Col>
                    <Col  className="mt-4 mt-lg-0 width=50%">
                        <Col className='my-2 mx-2'>
                            <br />
                            <h5 style={{ fontWeight: 'normal' }}>Verder leveren we natuurlijk ook: <li>zeep(incl.  automaten)</li>
                                <li>Desinfectie (incl. automaten)</li><li>Papier (incl. automaten)</li>
                                <li>Plasticzakken enz.</li><br />
                            </h5>
                        </Col>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} className="mt-4 mt-lg-0">
                        <Col className='my-2 mx-2'>
                            <br />
                            <h5 style={{ fontWeight: 'normal' }}>Vergeet ook niet een kijkje te nemen in onze afdeling <strong>aanbiedingen</strong>. <br />Daar is voor ieder wat wils tegen zeer scherpe prijzen.</h5>
                        </Col>
                    </Col>
                    <Col className="width=50%">
                        <Col className='my-2 mx-2'>
                            <img src={ShowroomKast} className="img-fluid custom-rounded-img" alt="" />
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <br />
                </Row>
            </Col>
            <Col className="custom-col">
                <Row>
                    <Col lg={5} className="mt-4 mt-lg-0">
                        <Col className='my-2 mx-2'>
                            <br />
                            <h4>Bezoekadres</h4>
                            <h6>Mon Plaisir 104, 4879 AT  Etten-Leur, Nederland</h6>
                        </Col>
                    </Col>
                    
                    {/* <LocationMaps /> */}
                    
                </Row>
                <Row>
                    <br />
                </Row>
                <Row>
                    
                    <Col lg={12} className="mt-4 mt-lg-0">
                        <Col className='my-2 mx-2'>
                            
                            <h4 className="font-bold" color="#009fd6">Openingstijden & Contactgegevens</h4>
                            
                        </Col>
                    </Col>
                    
                </Row>
                    <Col className='my-2 mx-2'>
                        <AvailableDetails />
                    </Col>
                <Row>
                    <br />
                </Row>
            </Col>
            <Col className="custom-col">
                <Row>
                    <br />
                </Row>
                <Row>
                    <Col className='my-3 mx-4'>
                        <h6 className='text-center'>Deze webshop (v24.8.1) wordt beheerd en is ontwikkeld door: <strong style= {{color:"#009fd6" }}><a href="https://www.lightbluegroup.com" style={{ textDecoration: 'none' }}>lightblue Applications and Consultancy</a></strong></h6>
                    </Col>
                </Row>
                <Row>
                    <br />
                </Row>
            </Col>
            <MandatoryDetails />
            <Row>
                <br />
                <br />
            </Row>
        </div>
    );

}
