import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { MaintainProduct } from './MaintainProducts';

function LoggedOnAdmin() {
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));

    if (userDetails !== null) {
        return (
            <MaintainProduct />
        )
    }
    else {
        return <Navigate to='/login' replace />;
    }

}

export default LoggedOnAdmin;
