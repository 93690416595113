import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardImg, CardBody, CardText, CardTitle, Button, Form, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import Axios from '../../context/axios';
import Spinner from '../Spinner';
import { MdAddShoppingCart, MdPlayArrow, MdStoreMallDirectory } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../custom.css'


export function RentingList() {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");


    useEffect(() => {
        async function init() {
            try {
                const response = await Axios('/api/renting');
                if (response.status === 200) {
                    setProducts(response.data.products);
                } else {
                    throw response;
                }
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        }
        init();
    }, []);

    function setItemCount(addcnt) {
        let itemCount = 0;
        let itemAddCount = (isNaN(addcnt) ? 0 : parseInt(addcnt));
        itemCount = parseInt(sessionStorage.getItem("cartitems"));
        if (isNaN(itemCount)) {
            sessionStorage.setItem("cartitems", itemAddCount.toString());
        }
        else {
            itemCount += itemAddCount;
            sessionStorage.setItem("cartitems", itemCount.toString());
        }
    }

    async function AddToCart(e) {
        e.preventDefault();
        let request = { productId: Number(e.target.value), quantity: Number(1) };
        let sessionId = sessionStorage.sessionId;
        if (sessionId) { request.sessionId = sessionId; }
        let result = await Axios.post('/api/cart', request);
        sessionStorage.sessionId = result.data.sessionId;
        setItemCount(1);
        toast.info('(weer) een dienst toegevoegd', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
    }

    function renderProduct(p) {
        return (
            <Card key={p.id} className="rentingcard">
                <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
                <CardBody >
                    <Link to={`/rent/${p.id}`} style={{ textDecoration: 'none', color: "black" }}>
                        <CardImg top width="100%" center="true" className="product-img" src={p.images[0]} alt="Card image cap" />
                        <CardTitle><b>{p.name}</b></CardTitle>
                        <CardText color="black">{p.description}</CardText>
                    </Link>
                </CardBody>
                <Button color="primary" width="75%" value={p.id} onClick={(e) => { AddToCart(e); }} ><MdAddShoppingCart />{" "}Toevoegen{" "}<br />{" "}{" "}{Number(p.price).toFixed(2)}{" "} EUR per dag</Button>
            </Card>
        );
    }



    const complexFiltered = data => {
        if (searchValue && !data.name.toLowerCase().includes(searchValue)) return false;
        if (data.active !== 1) { return false };

        return true;
    }

    const filteredProducts = products.filter(complexFiltered);
    const availableProductCount = filteredProducts.length;
    const availableProductText = (availableProductCount === 1 ? "gevonden artikel" : "gevonden artikelen");


    if (error) throw error;
    if (loading) return <Spinner />;

    return (
        <div>
            <Row >
                <h2 ><MdStoreMallDirectory size="35" />{" "}Verhuur</h2><br />
                <p><b><i>TOPO Schoonmaak</i></b><i> Groothandel verhuurt ook specialitische machines voor uw schoonmaak activiteiten.</i></p>
            </Row>
            <Row className="colcontainer">
                <Row>
                    <Col>
                        <Form inline>
                            <FormGroup>
                                <Label for="Zoeken" className="mr-sm-2"><b>Zoeken</b></Label>
                                <input id="searchValue" type="text" value={searchValue} onChange={e => { setSearchValue(e.target.value.toLowerCase()); }} />
                                <Label for="Results" className="mr-sm-2"> <MdPlayArrow />{" "} ({availableProductCount} {availableProductText})</Label>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Row>
            <Row className="colcontainer">
                {filteredProducts.map(renderProduct)}
            </Row>
            <Row className="colcontainer">
                <br />
            </Row>

        </div>
    );
}


