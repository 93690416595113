import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button } from 'reactstrap';
import MainUserCustomerClass from './MainUserCustomerClass';
import { ToastContainer, toast } from 'react-toastify';
import Axios from '../../context/axios';

export default function MainUserCustomer(props) {
    //const [portalOverride] = useState(JSON.parse(sessionStorage.getItem("portalswitch")));
    const [portalr] = useState(sessionStorage.getItem("portal"));
    const [customersList, setCustomersList] = useState(props.cusdata);
    const [currentCustomer, setCurrentCustomer] = useState([]);
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [editmode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
        setCustomersList(props.cusdata)

    }, [props.cusdata]);
    

    async function onCancelInvoiceData(e) {

        setLoading(true);
        // Update table by adjusting addresses....
        toast.info(`Facturatiegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentCustomer([]);
        setLoading(false);
    };


    async function onUpdateInvoiceData(e) {

        if (loading === false) {
            setLoading(true);
            try {
                let updatedData = JSON.stringify({ currentCustomer });
                //console.log(updatedData);
                var data = {
                    plu: updatedData,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'CU'
                }

                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== null) && (userDetails !== null)) {
                    sessionStorage.sessionId = sessionId;
                    let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                    const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                    let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                    console.log(portalr);
                    let runUrl = '/api/prefuser/';
                    if (portalr === "G") { runUrl = '/api/prefgroup/' + xd; }
                    const response = await Axios.post(runUrl, data, axiosConfig);
                    if (response.status === 200) {
                        setCustomersList(response.data.customer);
                        toast.info(`Facturatiegegevens bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });

                    } else {
                        toast.error(`Facturatiegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }

                // Update table by adjusting addresses....
                setCurrentCustomer([]);
                setLoading(false);

            } catch (ex) {
                console.log(ex);
                toast.error(`Er is een fout opgetreden bij het opslaan van facturatiegegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
        }
    };

    const selectInvoiceData = (e) => {
        let cursku = customersList.filter(customer => customer.id === Number(e.target.value));
        let customerstring = cursku[0].organisation;
        setCurrentCustomer(cursku[0]);
        
        toast.info(`Facturatiegegevens van ${customerstring} geselecteerd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    };

    async function onCreateInvoiceData(e) {
        if (loading === false) {
            setLoading(true);
            try {
                if (sessionStorage.sessionId !== undefined) {
                    sessionStorage.sessionId = "No Session yet";
                }

                var data = {
                    plu: e.target.value,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'CC'
                }
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== undefined) && (userDetails !== undefined)) {
                    sessionStorage.sessionId = sessionId;
                    let result = await Axios.post('/api/prefuser', data, axiosConfig);
                    if (result.status === 200) {
                        toast.info(`Nieuwe facturatiegegevens toegevoegd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        setCustomersList(result.data.customer);
                        setLoading(false);
                    } else {
                        toast.error(`Nieuwe facturatiegegevens niet toegevoegd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }
                //window.location.reload(reload);
                //setReload(!reload);


            } catch (ex) {
                console.log(ex);
                toast.error(`Er is een fout opgetreden bij het toevoegen van nieuwe facturatiegegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            setLoading(false);
        }
    };

    const deleteInvoiceData = async (e) => {
        if (loading === false) {
            setLoading(true);
            if (customersList.length > 1) {
                try {
                    var data = {
                        plu: e.target.value,
                        sessionId: sessionStorage.sessionId,
                        username: userDetails.username,
                        token: userDetails.jwttoken,
                        action: 'CD'
                    }
                    let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                    let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                    let result = await Axios.post('api/prefuser', data, axiosConfig);
                    if (result.status === 200) {
                        toast.info(`Facturatiegegevens verwijderd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        setCustomersList(result.data.customer);
                        setLoading(false);
                    }
                    else {
                        toast.warning(`Probleem bij het verwijderen van facturatiegegevens. Raadpleeg uw Administrator`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }

                } catch (ex) {
                    console.log(ex);
                    toast.error(`Fout bij het verwijderen van facturatiegegevens. Raadpleeg uw Administrator`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            }
            else {
                toast.warning(`Er dient altijd één set facturatiegegevens te zijn.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            setLoading(false);
        }
    };

    function handleCustomerDetailsUpdated(newCustomerDetails) {
        setCurrentCustomer(newCustomerDetails);
    }
    

    return (
        <div>
            <Col>
                <br />
                <Row>
                    <Table cellPadding={10} >
                        <thead>
                            <tr>
                                <td><b>Organisatie</b></td>
                                <td><b>Voornaam</b></td>
                                <td><b>Achternaam</b></td>
                                <td><b>BTW Nummer</b></td>
                                <td><b>Klant Nummer</b></td>
                                <td><b>Functies</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customersList.map((c) => (
                                    <tr key={c.id}>
                                        <td>{c.organisation}</td>
                                        <td>{c.firstName}</td>
                                        <td>{c.lastName}</td>
                                        <td>{c.vatNumber}</td>
                                        <td>{c.customerNumber}</td>
                                        <td><Button color="danger" value={c.id} disabled={editmode} size="sm" onClick={(e) => { deleteInvoiceData(e); setEditMode(true); }}>Verwijder</Button> {"  "}<Button color="warning" value={c.id} disabled={editmode} size="sm" onClick={(e) => { selectInvoiceData(e); setEditMode(true); }}>Aanpassen</Button></td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>

                    <br />

                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />

                    <br />
                    {(currentCustomer.length !== 0) ?
                        <MainUserCustomerClass cusdata={currentCustomer} onChanged={handleCustomerDetailsUpdated} />
                        :
                        <p></p>
                    }
                    {(currentCustomer.length !== 0) ?
                        <Col>
                            <br />
                            <Button color="primary" onClick={(e) => { onCancelInvoiceData(e); setEditMode(false); }} >Annuleren</Button>
                            {" "}
                            <Button color="primary" onClick={(e) => { onUpdateInvoiceData(e); setEditMode(false); }} >Opslaan facturatiegegevens</Button>
                        </Col>
                        :
                        <p></p>
                    }

                    {(portalr !== 'G' && currentCustomer.length === 0) ?
                        <Col>
                            <Button color="primary" onClick={(e) => { onCreateInvoiceData(e); }} >Toevoegen facturatiegegevens</Button>
                        </Col>
                        :
                        <p></p>
                    }

                </Row>
            </Col>
        </div>
    );

}
