import React from "react";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";

export default class MainUserAddressClass extends React.Component {

    state = {
        id: this.props.editaddress.id,
        line1: this.props.editaddress.line1,
        line2: this.props.editaddress.line2,
        city: this.props.editaddress.city,
        country: this.props.editaddress.country,
        postcode: this.props.editaddress.postcode,
        type: this.props.editaddress.type,
        active: this.props.editaddress.active,
        default: this.props.editaddress.default,
    };

    handleChange(newState) {
        this.setState(newState, () => this.props.onChanged(this.state));
    }

    render() {
        return (
            <Form>
                <FormGroup row>
                    <Label for="default" sm={2}>Voorkeur</Label>
                    <Col sm={6}>
                        <Input type="select" id="default" value={this.state.default}
                            onChange={event => this.handleChange({ default: event.target.value })}
                            placeholder="Voorkeur" >
                            <option value={true}>Ja</option>
                            <option value={false}>Nee</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="type" sm={2}>Type Adres</Label>
                    <Col sm={6}>
                        <Input type="select" id="type" value={this.state.type}
                            onChange={event => this.handleChange({ type: event.target.value })}
                            placeholder="Type" >
                            <option value="Billing">Factuuradres</option>
                            <option value="Delivery">Bezorgadres</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="line1" sm={2}>Adres 1</Label>
                    <Col sm={6}>
                        <Input type="text" id="line1" value={this.state.line1}
                            onChange={event => this.handleChange({ line1: event.target.value })}
                            placeholder="Adres 1" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="line2" sm={2}>Adres 2</Label>
                    <Col sm={6}>
                        <Input type="text" id="line2" value={this.state.line2}
                            onChange={event => this.handleChange({ line2: event.target.value })}
                            placeholder="Adres 2" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="postcode" sm={2}>Postcode</Label>
                    <Col sm={6}>
                        <Input type="text" id="postcode" value={this.state.postcode}
                            onChange={event => this.handleChange({ postcode: event.target.value })}
                            placeholder="Postcode" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="city" sm={2}>Stad</Label>
                    <Col sm={6}>
                        <Input type="text" id="city" value={this.state.city}
                            onChange={event => this.handleChange({ city: event.target.value })}
                            placeholder="Stad" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="country" sm={2}>Land</Label>
                    <Col sm={6}>
                        <Input type="text" id="country" value={this.state.country}
                            onChange={event => this.handleChange({ country: event.target.value })}
                            placeholder="Land" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="active" sm={2}>Actief</Label>
                    <Col sm={6}>
                        <Input type="select" id="active" value={this.state.active}
                            onChange={event => this.handleChange({ active: event.target.value })}
                            placeholder="Actief" >
                            <option value={true}>Ja</option>
                            <option value={false}>Nee</option>
                        </Input>
                    </Col>
                </FormGroup>
                <br />
            </Form>
        )
    }

}
