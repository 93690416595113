// <reference path="maintainmediaclass.js" />
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Button, Card, CardBody, CardTitle, CardImg, CardText, TabContent, NavLink, Nav, NavItem, TabPane, Media, Label, Input, Form, Spinner } from 'reactstrap';
//import UploadFiles from './UploadFiles';
import MaintainProductDetailClass from '../Products/MaintainProductDetailClass';
import MaintainProductDetailsSKUClass from '../Products/MaintainProductDetailsSku';
import MaintainMediaClass from './MaintainMediaClass';
import MaintainInfoClass from './MaintainInfoClass';
import { Link, Navigate } from 'react-router-dom';
import Axios from '../../context/axios';
import '../../custom.css'
import { ToastContainer, toast } from 'react-toastify';
import { FiArrowLeft, FiLayers, FiImage, FiFilm, FiCopy, FiBookOpen, FiVideo, FiPaperclip, FiCamera, FiAlertTriangle } from "react-icons/fi";
import classnames from 'classnames';

export default function MaintainDetails(props) {
    const {id} = useParams();
    const [product, setProduct] = useState([]);
    const [orgproduct, setOrgProduct] = useState([]);
    const [sku, setSku] = useState([]);
    const [searchValueProduct, setSearchValueProduct] = useState("");
    const [images, setImages] = useState([]);
    const [imageList, setImageList] = useState(images);
    const [availablemedia, setAvailableMedia] = useState([]);
    const [currentMedia, setCurrentMedia] = useState([]);
    const [availableInfo, setAvailableInfo] = useState([]);
    const [currentInfo, setCurrentInfo] = useState([]);
    const [relatedproducts, setRelatedproducts] = useState([]);
    const [searchedproducts, setSearchedproducts] = useState([]);
    const [currentSku, setCurrentSku] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [editmode, setEditMode] = useState(false);
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [activeTab, setActiveTab] = useState('1');
    const [newFile, setNewFile] = useState();
    const [fileName, setFileName] = useState();
    const [draggedImage, setDraggedImage] = useState(null);
    const [enableSave, setEnableSave] = useState(true);
    const [mainFormValidated, setMainFormValidated] = useState(true);


    //const [uniqueBrands, setuniqueBrands] = useState([], "");

    const safetyFiltered = data => {
        if (data.mediaType !== 9) return false;
        return true;
    }

    const safetyMedia = availablemedia.filter(safetyFiltered);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        async function init(id) {
            try {
                setLoading(true);
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                const result = await Axios.get(`/api/maintain/${id}`, axiosConfig);
                if (result.status === 200) {
                    //setuniqueBrands(result.data.brands);
                    setSku(result.data.product.skus);
                    setImages(result.data.product.images);
                    setImageList(result.data.product.images);
                    setAvailableMedia(result.data.product.relatedMedia);
                    setRelatedproducts(result.data.product.relatedProducts);
                    setAvailableInfo(result.data.product.largeDescription);
                    setOrgProduct(result.data.product);
                    setProduct(result.data.product);
                    setEnableSave(true);
                }
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
        init(id,0);
    }, [id]);


    // --------------- Product --------------------------/// 

    function handleProductDetailsUpdated(newDetails, isValid)  {

        setProduct({ newDetails });   
        validateForm(newDetails) ;
        if (isValid) {
            setEnableSave(true);
            
        } else {
            setEnableSave(false);
        }
        
    }

   
    function validateForm(e) 
    {
        let isValid = true;
        if (!e.name || !e.name.trim()) {
            isValid = false;
        }

        if (!e.brand || !e.brand.trim()) {
            isValid = false;
        }

        if (!e.price || e.price === "") {
            isValid = false;
        } else {
            const priceRegex = /^\d+(\.\d{1,2})?$/;
            if (!priceRegex.test(e.price)) {
                isValid = false;
            }
        }
        setMainFormValidated(isValid);
        setEnableSave(isValid);
    }



    async function onUpdateProduct(e) {
        let updatedData = JSON.stringify({ product });
        let orgData = JSON.stringify({ orgproduct });
        
        if (mainFormValidated) {

            if (updatedData !== undefined) {
                if (updatedData.length > 50) {
                    var data = {
                        plu: updatedData,
                        sessionId: sessionStorage.sessionId,
                        username: userDetails.username,
                        token: orgData,
                        action: 'PU'
                    }
                    callMaintFunction("Product", "PU", id, data)
                }
                else {
                    console.log("Issue with onUpdateProduct");
                }
            }
        }

    }

    async function CopyProduct(e) {
        var data = {
            plu: id,
            sessionId: sessionStorage.sessionId,
            username: userDetails.username,
            token: id,
            action: 'PC'
        }
        
        callMaintFunction("Product", "PC", id, data)
    }

    async function onDeleteImage(e) {
        e.preventDefault();
        const linkdata = {
            productid: id,
            imgname: e.target.value
        }

        let updatedData = JSON.stringify({ linkdata });
        
        var data = {
            plu: updatedData,
            sessionId: sessionStorage.sessionId,
            username: userDetails.username,
            token: userDetails.jwttoken,
            action: 'XD'
        }
        
        callMaintFunction("Beeld", "XD", id, data)    
    }

    const uploadFile = async (e) => {
        
        let currentFileName = fileName

        try {
            if (typeof newFile === 'undefined') return;
            setLoading(true);
            const formData = new FormData();

            formData.append('FormFile', newFile);
            formData.append('FileName', fileName);
            formData.append('Plu', id);
            formData.append('SessionId', sessionStorage.sessionId);
            formData.append('UserName', userDetails.username);
            formData.append('Action', 'FA');

            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            let axiosConfig = { headers: { 'Authorization': `Bearer ${ud}` } };
            let response = await Axios.post('api/image', formData, axiosConfig);
            if (response.status === 200) {
                toast.info(`Beeld ${currentFileName} opgeladen.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                setImageList(response.data.images);
            }
            else {
                toast.warning(`Probleem bij opladen.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }

            

        } catch (ex) {
            console.log(ex);
            toast.error(`Er is een fout opgetreden bij het opladen van beeld ${currentFileName}.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        }
        
        // CleanUp droparea
        setDraggedImage(null);
        setNewFile(null);
        setFileName('');
        setLoading(false);
    };


    // ---------------- SKU ------------------------------///
    const updateSKU = (e) => {
        let cursku = sku.filter(sku => sku.skuid === Number(e.target.value));
        setCurrentSku(cursku[0]);
    };

    async function onCancelSKU(e) {
        toast.info(`Product SKU gegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentSku([]);
    };

    function handleSkuDetailsUpdated(newSkuDetails) {
        setCurrentSku(newSkuDetails);
    }

    async function onUpdateSKU(e) {
        let updatedData = JSON.stringify({ currentSku });
        var data = { plu: updatedData, sessionId: sessionStorage.sessionId, username: "", token: userDetails.jwttoken, action: 'SU' }
        callMaintFunction("Sku", "SU", id, data)
        setCurrentSku([]);
    };

    async function onCreateSKU(e) {
        var data = { plu: id, sessionId: sessionStorage.sessionId, username: "", token: "", action: 'SC' }
        callMaintFunction("Sku", "SC", id, data);
        setCurrentSku([]);
    };


    async function copySKU(e) {
        var data = { plu: e.target.value, sessionId: sessionStorage.sessionId, username: userDetails.username, token: "", action: 'SK' }
        callMaintFunction("Sku", "SK", id, data);
        setCurrentSku([]);
    };
    


    const deleteSKU = async (e) => {
        if (sku.length > 1) {
            var data = { plu: e.target.value, sessionId: sessionStorage.sessionId, username: userDetails.username, token: "", action: 'SD' }
            callMaintFunction("Sku", "SD", e.target.value, data)
            setCurrentSku([]);
        }
    };


    // --------------- Media ----------------------------/// 
    function handleMediaDetailsUpdated(newMediaDetails) {
        setCurrentMedia({ newMediaDetails });
    }

    const selectMedia = (e) => {
        setCurrentMedia([]);
        let currmedialink = availablemedia.filter(media => media.id === Number(e.target.value));
        setCurrentMedia(currmedialink[0]);
    };

    async function onCancelMedia(e) {
        toast.info(`Product Instructie gegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentMedia([]);
    };

    const onUpdateMedia = (e) => {
        let updatedData = JSON.stringify({ currentMedia });
        var data = { plu: updatedData, sessionId: sessionStorage.sessionId, username: "", token: "", action: 'MU' }
        callMaintFunction("Instructie", "MU", id, data)
        setCurrentSku([]);
        setCurrentMedia([]);
    };

    const onCreateMedia = (e) => {
        var data = {  plu: id,  sessionId: sessionStorage.sessionId, username: "", token: "", action: "MC" }
        callMaintFunction("Instructie", "MC", id, data)
        setCurrentMedia([]);
    };

    const deleteMedia = (e) => {
        if (e.target.value.length > 0) {
            var data = { plu: e.target.value, sessionId: sessionStorage.sessionId, username: "", token: "", action: 'MD' }
            callMaintFunction("Instructie", "MD", e.target.value, data)
            setCurrentMedia([]);
        }
    };
    

    // --------------- Info ----------------------------/// 

    function handleInfoDetailsUpdated(newDetails) {
        setCurrentInfo({ newDetails });
    }

    const selectInfo = (e) => {
        setCurrentInfo([]);
        let adrInfo = availableInfo.filter(info => info.id === Number(e.target.value));
        setCurrentInfo(adrInfo[0]);
    };

    async function onCancelInfo(e) {
        toast.info(`Product Informatie gegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentInfo([]);
    };


    const deleteInfo = (e) => {
        if (e.target.value.length > 0) {
            var data = { plu: e.target.value, sessionId: sessionStorage.sessionId, username: "", token: "", action: 'ID' }
            callMaintFunction("Informatie", "ID", e.target.value, data)
            setCurrentInfo([]);
        }
    };

    const onUpdateInfo = (e) => {
        
        let updatedData = JSON.stringify({ currentInfo });
        var data = { plu: updatedData, sessionId: sessionStorage.sessionId, username: "", token: "", action: 'IU' }
        callMaintFunction("Informatie", "IU", id, data)
        setCurrentInfo([]);
    };

    const onCreateInfo = (e) => {
        var data = { plu: id, sessionId: sessionStorage.sessionId, username: "", token: "", action: "IC" }
        callMaintFunction("Informatie", "IC", id, data)
        setCurrentInfo([]);
    };

    const onUpwardInfo = (e) => {
        if (e.target.value.length > 0) {
            var data = { plu: e.target.value, sessionId: id, username: "", token: "", action: 'I+' }
            callMaintFunction("Informatie", "I+", id, data)
            setCurrentInfo([]);
        }
    };

    const onDownwardInfo = (e) => {
        if (e.target.value.length > 0) {
            var data = { plu: e.target.value, sessionId: id, username: "", token: "", action: 'I-' }
            callMaintFunction("Informatie", "I-", id, data)
            setCurrentInfo([]);
        }
    };


    // --------------- Related Product ----------------------------/// 

    const deleteRelated = (e) => {
        if (e.target.value.length > 0) {
            var data = { plu: e.target.value, sessionId: id, username: "", token: "", action: 'RD' }
            callMaintFunction("gerelateerd product", "RD", id, data)
            setSearchedproducts([]);
        }
    };

    const addRelated = (e) => {
        if (e.target.value.length > 0) {
            var data = { plu: e.target.value, sessionId: id, username: "", token: "", action: 'RC' }
            callMaintFunction("gerelateerd product", "RC", id, data)
            setSearchedproducts([]);
        }
    };

    const searchRelated = (e) => {
        if (e.target.value.length > 2) {
            var data = { plu: e.target.value, sessionId: id, username: "", token: "", action: 'RQ' }
            callMaintFunction("gerelateerd product", "RQ", id, data)
        }
        
    };

    // --------------------------------------------------------///


    async function callMaintFunction(name, action, id, data) 
    {
        try {
            const tokens = JSON.parse(sessionStorage.getItem("tokens"));
            if (!tokens || !tokens.jwttoken) {
                throw new Error("No JWT token found");
            }
            
            const ud = tokens.jwttoken;
            const axiosConfig = { headers: { "Authorization": `Bearer ${ud}` }};
            const sessionId = sessionStorage.getItem("sessionId");

            //console.log("callMaintFunction:", { ud, action, data, axiosConfig });

            if (!sessionId || !userDetails) {
                if (!sessionId) {
                    sessionStorage.setItem("sessionId", "");
                }
                else {
                    throw new Error("Session ID or user details missing");
                }
            }

            const actionMap = {
                'U': 'geupdate',
                '+': 'geupdate',
                '-': 'geupdate',
                'D': 'verwijderd',
                'Q': 'gezocht',
                'C': 'gekopieerd',
                'K': 'gekopieerd'
            };
            const actionname = actionMap[action.charAt(1)] || 'toegevoegd';
            sessionStorage.setItem("sessionId", sessionId);

            const result = await Axios.post('/api/maintain', data, axiosConfig);
            if (result.status === 200) {
                const updateFunctions = {
                    'S': () => setSku(result.data.skus),
                    'M': () => setAvailableMedia(result.data.media),
                    'R': () => action.charAt(1) === 'Q' ? setSearchedproducts(result.data.related) : setRelatedproducts(result.data.related),
                    'I': () => setAvailableInfo(result.data.info),
                    'X': () => {
                                    setImageList(result.data.images) 
                                    console.table(result.data.images);
                                },
                    'P': () => action.charAt(1) === 'U' && setProduct(result.data)
                };

                const updateFunction = updateFunctions[action.charAt(0)];
                if (updateFunction) updateFunction();

                toast.info(`${name} ${actionname}.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                console.log("Code: " + result.status);
                throw new Error(`${name} niet ${actionname}.`);
            }
            
        } catch (ex) {
            console.error(ex);
            const actionMap = {
                'U': 'updaten',
                '+': 'updaten',
                '-': 'updaten',
                'D': 'verwijderen',
                'Q': 'zoeken',
                'C': 'kopieren',
                'K': 'kopieren'
            };
            const actionVerb = actionMap[action.charAt(1)] || 'toevoegen';
            const errorMessage = `Er is een fout opgetreden bij het ${actionVerb} van een ${name}.`;
            
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
            
        }
    };


    // Image Features Drag-n-drop
    const handleDrop = (e) => {
        e.preventDefault();
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        const file = e.dataTransfer.files[0];
        //setNewFile(e.dataTransfer.files[0]);
    
        if (file && allowedTypes.includes(file.type)) {
            setNewFile(file);
            setFileName(file.name);
    
            const reader = new FileReader();
            reader.onload = (e) => {
                setDraggedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            console.log("Not allowed: " + (file ? file.type : "No file dropped"));
        }
    };

    const resetDropBox = () => {
        setDraggedImage(null);
        setNewFile(null);
        setFileName('');
    };
    
    const allowDrop = (e) => {
        e.preventDefault();
    };

    // --------------------------------------------------------///

   

    if (loading) return <Spinner />;

    if (userDetails !== null) {
        return (
            <Col className="colcontainer">
                <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
                <Link to={'/maintenance/'} style={{ textDecoration: 'none' }}>
                    <Row><h4><FiArrowLeft />{" "}Terug naar Productenlijst</h4></Row>
                </Link>
                <hr />
                <h3>Onderhouden bestaand product</h3>
                <Row>{" "}
                </Row>

                <Nav tabs className="navtabsleft">
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} ><b><FiLayers />{" "}Product Details</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiLayers />{" "}SKU Configuraties</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} ><b><FiImage />{" "}Geselecteerde beelden</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }} ><b><FiBookOpen />{" "}Product informatie</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }} ><b><FiCopy />{" "}Gerelateerde producten</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }} ><b><FiFilm />{" "}Instructie informatie</b></NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }} ><b><FiAlertTriangle style={{ color: 'red' }} />{" "}Veiligheidsblad</b></NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="colcontainer">
                        <Row>
                            <MaintainProductDetailClass editProduct={product} onChanged={handleProductDetailsUpdated} />
                        </Row>
                        <Row>
                            <Col><Button color="primary" onClick={(e) => { onUpdateProduct(e); }} disabled={enableSave}>Opslaan</Button></Col>
                            <Col></Col>
                            <Col><Button color="warning" onClick={(e) => { CopyProduct(e); }}>Kopieer Product</Button></Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2" className="colcontainer">
                        <Row>
                            <Col>
                                <h6>SKU (Product Configuraties)</h6>

                                <table border={2} cellPadding={5}>
                                    <thead>
                                        <tr>
                                            <td>SKU</td>
                                            <td>SKU Omschrijving</td>
                                            <td>Kleur</td>
                                            <td>Prijs</td>
                                            <td>Aanbieding</td>
                                            <td>Aanbiedingsprijs</td>
                                            <td>Volume</td>
                                            <td>Volume Grootte</td>
                                            <td>Verpakking</td>
                                            <td>Eenheden</td>
                                            <td>Functies</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sku.map((s) => (
                                                <tr key={s.skuid}>
                                                    <td>{s.skuString}</td>
                                                    <td>{s.skuProductDescription}</td>
                                                    <td>{s.color}</td>
                                                    <td>{Number(s.price).toFixed(2)}</td>
                                                    <td>{s.promotion ? "Ja" : "Nee"}</td>
                                                    <td>{Number(s.promotionPrice).toFixed(2)}</td>
                                                    <td>{s.volumeDescription}</td>
                                                    <td>{s.volumeSize}</td>
                                                    <td>{s.packaging}</td>
                                                    <td>{s.productsPerPackage}</td>
                                                    <td><Button color="danger" value={s.skuid} disabled={editmode} size="sm" onClick={(e) => { deleteSKU(e); }}>Verwijder</Button>{" "}-{" "}
                                                        <Button color="warning" value={s.skuid} disabled={editmode} size="sm" onClick={(e) => { updateSKU(e); setEditMode(true); }}>Aanpassen</Button>{" "}-{" "}
                                                        <Button color="success" value={s.skuid} disabled={editmode} size="sm" onClick={(e) => { copySKU(e); }}>Kopie</Button></td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <br />
                                {currentSku.length !== 0 &&
                                    <MaintainProductDetailsSKUClass editSku={currentSku} onChanged={handleSkuDetailsUpdated} />
                                }
                                {currentSku.length !== 0 &&
                                    <Col>
                                        <Button color="primary" onClick={(e) => { onCancelSKU(e); setEditMode(false); }} >Annuleren</Button>
                                        {"  "}
                                        <Button color="primary" onClick={(e) => { onUpdateSKU(e); setEditMode(false); }} >Opslaan SKU</Button>
                                    </Col>
                                }
                                {(currentSku.length === 0) &&
                                    <Button color="primary" onClick={(e) => { onCreateSKU(e); }} >Toevoegen SKU</Button>
                                }
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3" className="colcontainer">
                        <Row>
                            <Col>
                                <h6>De volgende beelden zijn gekoppeld aan dit product. </h6>
                                {(imageList.length > 0) ?
                                    <Col>
                                        <Row>
                                            {
                                                imageList.map((image) => (
                                                    <div key={image} className="maintcard">
                                                        <img width="100%" height="225" src={image} alt="product img" />
                                                        <Button color="danger" size="sm" value={image} block onClick={(e) => { onDeleteImage(e); }}>Verwijderen</Button>
                                                        
                                                    </div>
                                                ))}
                                        </Row>
                                    </Col>
                                    :
                                    <Col>
                                        <Row>
                                            <h5>... Nog geen beelden gekoppeld aan dit product. </h5>
                                        </Row>
                                    </Col>
                                }
                            </Col>
                            <Col>
                            <Form>
                                <h6>Toevoegen van een beelden / plaatjes</h6>
                                <Label color="muted"><i>Alleen .gif, .png en .jpg bestanden zijn toegestaan. </i></Label>
                            </Form>
                            <div onDrop={handleDrop} onDragOver={allowDrop} style={{ border: '1px solid black', minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {draggedImage ? <img src={draggedImage} alt="DroppedImage" style={{ maxWidth: '75%', maxHeight: '200px' }} /> : 'Sleep een plaatje in deze rechthoek.'}
                            </div>
                            <br />
                            <Button color="primary" disabled={!fileName} onClick={(e) => { uploadFile(e); }} >Voeg het nieuwe beeld toe </Button>
                            <Button color="secondary" onClick={resetDropBox}>Reset Rechthoek hierboven</Button>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="4" className="colcontainer">
                        <Row>
                            {(availableInfo.length > 0) ?
                                <Col>
                                    <table border={2} cellPadding={10}>
                                        <thead>
                                            <tr>
                                                <td className={"w-300"}><b>Informatie</b></td>
                                                <td className={"w-50"}><b>Functies</b></td>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {availableInfo.map((ai) => (
                                                <tr key={ai.id}>
                                                    <td className={"w-300"}>
                                                        {(ai.displayType === 0) && <br />}
                                                        {(ai.displayType === 1) && <h6><b>{ai.infoText}</b></h6>}
                                                        {(ai.displayType === 2) && <p>{ai.infoText}</p>}
                                                        {(ai.displayType === 3) && <h6>{ai.infoText}</h6>}
                                                        {(ai.displayType === 4) && <p><b>{ai.infoText}</b></p>}
                                                        {(ai.displayType === 5) && <p>{" - "}{ai.infoText}</p>}
                                                        {(ai.displayType === 6) && <p><i>{ai.infoText}</i></p>}
                                                        {(ai.displayType === 20) && <Media object src={ai.infoImage} alt="img" />}
                                                    </td>
                                                    <td className={"w-50"}>
                                                        <Button color="danger" value={ai.id} disabled={editmode} size="sm" onClick={(e) => { deleteInfo(e); }} >Verwijder</Button>{" "}<Button color="warning" value={ai.id} disabled={editmode} size="sm" onClick={(e) => { selectInfo(e); setEditMode(true); }} >Aanpassen</Button>{" "}
                                                        <Button color="success" value={ai.id} disabled={editmode} size="sm" onClick={(e) => { onUpwardInfo(e); }} >{" "}Omhoog{" "}</Button>{" "}<Button color="info" value={ai.id} disabled={editmode} size="sm" onClick={(e) => { onDownwardInfo(e); }} >{" "}Omlaag{" "}</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <br />
                                    {currentInfo.length !== 0 &&
                                        <MaintainInfoClass editinfo={currentInfo} onChanged={handleInfoDetailsUpdated} />
                                    }
                                    {currentInfo.length !== 0 &&
                                        <Col>
                                            <Button color="primary" onClick={(e) => { onCancelInfo(e); setEditMode(false); }} >Annuleren</Button>
                                            {"  "}
                                            <Button color="primary" onClick={(e) => { onUpdateInfo(e); setEditMode(false); }} >Opslaan gewijzigde Product Informatie</Button>
                                        </Col>
                                    }
                                    {currentInfo.length === 0 &&
                                        <Button color="primary" onClick={(e) => { onCreateInfo(e); }} >Toevoegen </Button>
                                    }


                                </Col>
                                :
                                <Col>
                                    <Row>
                                        <Col>
                                            <h6>Er is hier geen extra product informatie.</h6>
                                            <br />
                                        </Col>

                                    </Row>
                                    {currentInfo.length === 0 &&
                                        <Button color="primary" onClick={(e) => { onCreateInfo(e); }} >Toevoegen </Button>
                                    }
                                </Col>
                            }
                        </Row>
                    </TabPane>
                    <TabPane tabId="5" className="colcontainer">
                        <Row>
                            {(relatedproducts.length > 0) ?
                                <Col>
                                    <Row>
                                        <h6>Producten die u in combinatie met of als vervanger voor dit product kunt gebruiken.</h6>
                                        {relatedproducts.map((rp) => (
                                            <Card key={rp.id} className="productcard">
                                                <div className="text-center">
                                                    <CardImg top className="product-img" src={rp.relationImage} alt="Card image cap" />
                                                </div>
                                                <CardBody >
                                                    <CardTitle><b>{rp.relationName}</b></CardTitle>
                                                    <Button color="danger" size="sm" value={rp.id} block onClick={(e) => { deleteRelated(e); }}>Verwijderen</Button>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </Row>
                                </Col>
                                :
                                <Col><Row><Col>
                                    <h6>Er zijn op dit moment geen gerelateerde producten gekoppeld aan dit product.</h6>
                                </Col></Row></Col>
                            }

                        </Row>
                        <Row>
                            <br />

                            <Col>
                                <h6>Toevoegen van nieuwe gerelateerde producten:</h6>
                                <Form>
                                    <Label for="Zoek op gedeelte van (naam, tag, plu)" className="mr-sm-2">Zoektekst (druk na het ingeven van de zoektekst <u>niet</u> op de <b>Enter-toets</b> maar click op de <b>Zoeken</b> knop hieronder)</Label>
                                    <Row className="row-cols-lg-auto g-0 align-items-center">
                                        <Col><Button color="primary"  value={searchValueProduct} onClick={(e) => { searchRelated(e); }}>Zoeken</Button></Col>
                                        <Col><Input id="searchValueProduct" type="text" value={searchValueProduct} onChange={e => { setSearchValueProduct(e.target.value.toLowerCase()); }} /></Col>
                                    </Row>
                                    <br />
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <hr />
                            <br />
                        </Row>
                        <Row>
                            {(searchedproducts.length > 0) &&
                                <Row>
                                    <h6>Resultaten:</h6>
                                    {searchedproducts.map((sp) => (
                                        <Card key={sp.id} className="productcard">
                                            <div className="text-center">
                                                <CardImg top className="product-img" src={sp.relationImage} alt="Card image cap" />
                                            </div>
                                            <CardBody >
                                                <CardTitle><b>{sp.relationName}</b></CardTitle>
                                                <CardText color="black">{sp.relationDescription}</CardText>
                                                <Button color="warning" size="sm" value={sp.id} onClick={(e) => { addRelated(e); }}>Toevoegen</Button>
                                            </CardBody>
                                        </Card>
                                    ))}
                                </Row>
                            }
                        </Row>

                    </TabPane>
                    <TabPane tabId="6" className="colcontainer">
                        <Row>
                            {(availablemedia.length > 0) ?
                                <Col>
                                    <h6>Handige informatie beelden, videos of documenten die u meer vertellen over dit product.</h6>
                                    <table border={2} cellPadding={10}>
                                        <thead>
                                            <tr>
                                                <td><b>Media</b></td>
                                                <td><b>Omschrijving</b> (drukken op het icoon opent een nieuw tabblad)</td>
                                                <td><b>Functies</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                availablemedia.map((am) => (
                                                    <tr key={am.id}>
                                                        <td><center>
                                                            <a href={am.mediaLocation} target="_blank" rel="noreferrer">
                                                                {(am.mediaType === 0) && <FiCamera size={50} />}
                                                                {(am.mediaType === 2) && <FiVideo size={50} />}
                                                                {(am.mediaType === 1) && <FiPaperclip size={50} />}
                                                                {(am.mediaType === 9) && <FiAlertTriangle size={50} />}
                                                            </a>
                                                        </center></td>
                                                        <td>{am.mediaName}</td>
                                                        <td><Button color="danger" value={am.id} disabled={editmode} size="sm" onClick={(e) => { deleteMedia(e); }} >Verwijder</Button>{" "}<Button color="warning" value={am.id} disabled={editmode} size="sm" onClick={(e) => { selectMedia(e); setEditMode(true); }} >Aanpassen</Button></td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>

                                    <br />
                                    {currentMedia.length !== 0 &&
                                        <MaintainMediaClass editmedia={currentMedia} onChanged={handleMediaDetailsUpdated} />
                                    }
                                    {currentMedia.length !== 0 &&
                                        <Col>
                                            <Button color="primary" onClick={(e) => { onCancelMedia(e); setEditMode(false); }} >Annuleren</Button>
                                            {"  "}
                                            <Button color="primary" onClick={(e) => { onUpdateMedia(e); setEditMode(false); }} >Opslaan gewijzigde Instructie</Button>
                                        </Col>

                                    }
                                    {currentMedia.length === 0 &&
                                        <Button color="primary" onClick={(e) => { onCreateMedia(e); }} >Toevoegen </Button>
                                    }

                                    <br />

                                </Col>
                                :
                                <Col>
                                    <Row><Col>
                                        <h6>Er zijn geen gerelateerde instructies of media gekoppeld aan dit product.</h6>
                                        <br />
                                    </Col></Row>
                                    {currentMedia.length === 0 &&
                                        <Button color="primary" onClick={(e) => { onCreateMedia(e); }} >Toevoegen </Button>
                                    }
                                </Col>
                            }
                        </Row>
                    </TabPane>
                    <TabPane tabId="7" className="colcontainer">
                        <Row>
                            {(safetyMedia.length > 0) ?
                                <Col>
                                    <p><i>Drukken op het icoon of tekst opent het veiligheidsblad in een nieuw tabblad.</i></p>
                                    <table border={0} cellPadding={10}>
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                safetyMedia.map((sf) => (
                                                    <tr key={sf.id}>
                                                        <td><center>
                                                            <a href={sf.mediaLocation} target="_blank" rel="noreferrer">
                                                                <FiAlertTriangle size={50} style={{ color: 'red' }} /></a>
                                                        </center></td>
                                                        <td>
                                                            <a href={sf.mediaLocation} target="_blank" rel="noreferrer">
                                                                <h3 style={{ color: 'red' }}>{sf.mediaName}</h3>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>

                                    <br />

                                </Col>
                                :
                                <Col>
                                    <Row><Col>
                                        <h6>Er is geen veiligheidsblad gekoppeld aan dit product.</h6>
                                    </Col></Row>
                                </Col>
                            }
                        </Row>
                    </TabPane>
                </TabContent>

                <br />
                <hr />
            </Col>


        );
    }
    else {
        return (<Navigate to='/login' replace />);
    }

        
}

// <UploadFiles product={product.plu} />