import React from 'react';
import { RentingList } from './RentingList';

export default function RentingPage () {

    return (
        <div>
            <RentingList />
        </div>
    );
}

