import React from "react";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";

export class Address extends React.Component {

    state = {
        line1: (this.props.default !== undefined ? this.props.default.line1 : ''),
        line2: (this.props.default !== undefined ? this.props.default.line2 : ''),
        city: (this.props.default !== undefined ? this.props.default.city : ''),
        country: (this.props.default !== undefined ? this.props.default.country : ''),
        postcode: (this.props.default !== undefined ? this.props.default.postcode : ''),
        id: (this.props.default !== undefined ? this.props.default.id : ''),
    };

    handleChange(newState) {
        this.setState(newState, () => this.props.onChanged(this.state));
    }

    render() {
        return (
            <Form>
                
                <FormGroup row>
                    <Label for="line1" sm={2}>Adres 1</Label>
                    <Col sm={6}>
                        <Input type="text" id="line1" value={this.state.line1}
                            onChange={event => this.handleChange({ line1: event.target.value })}
                            placeholder="Adres 1" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="line2" sm={2}>Adres 2</Label>
                    <Col sm={6}>
                        <Input type="text" id="line2" value={this.state.line2}
                            onChange={event => this.handleChange({ line2: event.target.value })}
                            placeholder="Adres 2" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="postcode" sm={2}>Postcode</Label>
                    <Col sm={6}>
                        <Input type="text" id="postcode" value={this.state.postcode}
                            onChange={event => this.handleChange({ postcode: event.target.value })}
                            placeholder="Postcode" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="city" sm={2}>Stad</Label>
                    <Col sm={6}>
                        <Input type="text" id="city" value={this.state.city}
                            onChange={event => this.handleChange({ city: event.target.value })}
                            placeholder="Stad" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="country" sm={2}>Land</Label>
                    <Col sm={6}>
                        <Input type="text" id="country" value={this.state.country}
                            onChange={event => this.handleChange({ country: event.target.value })}
                            placeholder="Land" />
                    </Col>
                </FormGroup>
            </Form>
        )
    }

}
