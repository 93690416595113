import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Axios from '../../context/axios';
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, Alert, Spinner } from 'reactstrap';

function Register() {

    //const referer = '/login'; //props.location.state.referer || '/';
    const [isError, setIsError] = useState(false);
    const [isUNPWError, setUNPWIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPasswordFirst] = useState("");
    const [passwordsecond, setPasswordSecond] = useState("");
    const [loading, setLoading] = useState(false);

    function postRegister() {
        setLoading(true);
        if (password === passwordsecond && userName !== password) {
            setIsSuccess(true);
            Axios.post("/account/register", {
                firstName,
                lastName,
                userName,
                password
            }).then(result => {
                if (result.status === 200) {
                    
                    setIsSuccess(true);
                } else {
                    setIsError(true);
                }
            }).catch(e => {
                setIsError(true);
            });
        } else {
            if (userName === password) { setUNPWIsError(true); }
            else { if (password === passwordsecond) { setIsError(true); } }
            
        }
        setLoading(false);
    }

    return (
        <div>
            <Col>
                <Card className="loginbox">
                    <CardHeader><b>Een TOPO account aanmaken</b></CardHeader>
                    <Alert color="light">Door het aanmaken van en inloggen met een TOPO account kunt u gegevens automatisch laten invullen bij bestellingen.</Alert>

                    <CardBody>
                        {isError && <Alert color="danger">Wachtwoorden zijn niet gelijk.</Alert>}
                        {isUNPWError && <Alert color="danger">Uw gebruikersnaam en wachtwoord mag niet gelijk zijn.</Alert>}
                        {!isSuccess && <div>
                        <Form onSubmit={postRegister} action='#'>
                            <FormGroup>
                                <Label for="lblFN"><b>Voornaam</b></Label>
                                <Input type="firstname" value={firstName} onChange={e => { setFirstName(e.target.value); }} placeholder="Voer hier uw voornaam in" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lblLN"><b>Achternaam</b></Label>
                                <Input type="lastname" value={lastName} onChange={e => { setLastName(e.target.value); }} placeholder="Voer hier uw achternaam in" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lblUN"><b>Email adres</b></Label>
                                    <Input type="username" value={userName} onChange={e => { setUserName(e.target.value); }} placeholder="Voer hier uw email adres in" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lblPwf"><b>Wachtwoord (1e keer)</b></Label>
                                <Input type="password" value={password} onChange={e => { setPasswordFirst(e.target.value); }} placeholder="Voer hier uw wachtwoord voor eerste keer in" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lblPwf"><b>Wachtwoord (2e keer)</b></Label>
                                <Input type="password" value={passwordsecond} onChange={e => { setPasswordSecond(e.target.value); }} placeholder="Voer hier uw wachtwoord een tweede keer in ter bevestiging." />
                            </FormGroup>
                                {!isSuccess &&
                                    <div>
                                        {(loading === true) ?
                                            <div>
                                                <Spinner size="sm" color="secondary" /><p>Momentje geduld aub...</p>
                                                <Button color="secondary" disabled>Versturen</Button>
                                            </div>
                                            :
                                            <Button color="primary" active >Versturen</Button>
                                            }
                                    </div>
                                 }
                            </Form>
                        </div>}
                        {isSuccess && <Alert color="success">Uw TOPO account is aangemaakt, hou uw email in de gaten voor de activatie code. Ga daarna naar de activatie pagina <Link to="/activatie">hier</Link>.</Alert>}
                        {!isSuccess && <div><hr/><p>Al een account bij TOPO? Klik dan <Link to="/login">hier</Link>.</p></div>}
                    </CardBody>
                </Card>
            </Col>
        </div>
    );
}

export default Register;

