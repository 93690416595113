//import React, { Component } from "react";
import React, { useState } from "react";
import Axios from '../context/axios';
import { MdAddShoppingCart } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
    
export function AddToCart(props) {
    const [quantity, setQuantity] = useState(1);

    async function onSubmit(e) {
        e.preventDefault();
        let iskuid = (isNaN(props.skuId) ? 0 : Number(props.skuId));
        let request = { productId: Number(props.productId), skuId: Number(iskuid), quantity: Number(quantity), action: "A" };
        //let prodId = props.productId;
        let prodName = props.productName;
        let qty = (quantity != null ? quantity : Number(1));
        let sessionId = sessionStorage.sessionId;
        if (sessionId) { request.sessionId = sessionId; }
        let result = await Axios.post('/api/cart', request);
        if (result.status === 200) {

            sessionStorage.sessionId = result.data.sessionId;
            toast.info(`${qty}x ${prodName} toegevoegd`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            setItemCount(qty);
        } else {
            toast.warning(`Product niet toegevoegd!`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        }
        
    }

    function setItemCount(addcnt) {
        let itemCount = 0;
        let itemAddCount = (isNaN(addcnt) ? 0 : parseInt(addcnt));
        itemCount = parseInt(localStorage.getItem("cartitems"));
        if (isNaN(itemCount)) {
            localStorage.setItem("cartitems", itemAddCount);
        }
        else {
            itemCount += itemAddCount;
            localStorage.setItem("cartitems", itemCount);
        }
    }
    
    function handleChange(e) {
        e.persist(); // persist the event
        setQuantity(e.target.value);
          return { quantity };
        };

    return ( 
        <form onSubmit={(e) => onSubmit(e) }>
            <br />
            <div className="form-row">
                <input type="number" className="form-control col-sm-2 mr-2" onChange={handleChange} value={quantity} id="quantity" />
                <button className="btn btn-primary form-control col-sm-3" type="submit"><MdAddShoppingCart />{" "}Toevoegen</button>
            </div>
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
        </form>
    )
}

