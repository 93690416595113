import React, {useState, useEffect} from 'react';
import { CustomerDetails } from './CustomerDetails';
import { Address } from './Address';
import { Form, FormGroup, Label, Input, Button, Col, Row, Card, CardText, CardTitle, Spinner} from 'reactstrap';
//import CheckoutSuccess from './CheckoutResult';
import OrderSummary from './OrderSummary';
import Axios from '../../context/axios';
import { ToastContainer, toast } from 'react-toastify';
import Survey from '../Cart/Survey';

export default function Checkout() {
    const [cartitems, setCartItems] = useState([]);
    const [cartBillingAddresses, setBillingCartAddresses] = useState([]);
    const [cartShippingAddresses, setShippingCartAddresses] = useState([]);
    const [cartCustomers, setCartCustomers] = useState([]);
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [deliverToBillingAddress, setDeliverToBillingAddress] = useState(true);
    const [checkoutDone, setCheckoutDone] = useState(false);
    const [selCustomer, setSelCustomer] = useState(0);
    const [selBillingAddress, setSelBillingAddress] = useState(0);
    const [selShippingAddress, setSelShippingAddress] = useState(0);
    const [customer, setCustomer] = useState([]);
    const [billingAddress, setBillingAddress] = useState([]);
    const [shippingAddress, setShippingAddress] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        async function getCart() {
            if (sessionStorage.getItem('sessionId') !== null) {
                const response = await Axios(`/api/cart?sessionId=${sessionStorage.sessionId}`);
                if (response.status === 200)
                {
                    setCartCustomers(response.data.customer);
                    setBillingCartAddresses(response.data.billingAddresses);
                    setShippingCartAddresses(response.data.shippingAddresses)
                    setCartItems(response.data.items);

                }
                else { setCartItems([]); setCartCustomers([]); setBillingCartAddresses([]); setShippingCartAddresses([]);}
            }
            else { setCartItems([]); setCartCustomers([]); setBillingCartAddresses([]); setShippingCartAddresses([]); }
        }
        getCart()
    }, []);


    const handleFormCustomerDetailsUpdated = (newDetails) => {
        setSelCustomer(0);
        setCustomer(newDetails);
    }

    const handleFormBillingAddressUpdated = (newDetails) => {
        setSelBillingAddress(0);
        setBillingAddress(newDetails);
    }

    const handleFormShippingAddressUpdated = (newDetails) => {
        setSelShippingAddress(0);
        setShippingAddress(newDetails);
    }
    
    const handleCustomerDetailsUpdated = (newDetails) => {
        if ((newDetails.selCustomer !== 0) && (newDetails.selCustomer !== undefined) && (newDetails.selCustomer !== "0")) {
            let preFillCustomer = cartCustomers.find(cus => cus.id === Number(newDetails.selCustomer));
            if ((preFillCustomer !== null) && (preFillCustomer !== undefined)) {
                setCustomer(preFillCustomer);
                setSelCustomer(newDetails.selCustomer);
            }
        } else {
            if (Number(newDetails.selCustomer) === 0) {
                // empty customer details
                setCustomer([]);
                setSelCustomer(0);
            }
        }
    }

    const handleBillingComboChange = (newState) => {
        if (cartBillingAddresses !== null) {
            const preFillAddress = cartBillingAddresses.find(address => address.id === Number(newState.selectedAddress));
            if ((preFillAddress !== null) && (preFillAddress !== undefined)) { setBillingAddress(preFillAddress); setSelBillingAddress(newState.selectedAddress); }
            else { if (Number(newState.selectedAddress) === 0) { setBillingAddress([]); setSelBillingAddress(0); } }
        }
    }

    const handleShippingComboChange = (newState) => {
        if (cartShippingAddresses !== null) {
            const preFillAddress = cartShippingAddresses.find(address => address.id === Number(newState.selectedAddress));
            if ((preFillAddress !== null) && (preFillAddress !== undefined)) { setShippingAddress(preFillAddress); setSelShippingAddress(newState.selectedAddress);
            } else {  if (Number(newState.selectedAddress) === 0) { setShippingAddress([]); setSelShippingAddress(0); } }
        }
    }

    const toggleUseBillingAddress = () => {
        if (deliverToBillingAddress === true) { setShippingAddress([]); setDeliverToBillingAddress(false); }
        else { setDeliverToBillingAddress(true); }
    }

    // make async
    async function handlePaymentMethodChanged(token) {
        

        if (token) {
            setLoading(true);
            let data = {
                "sessionId": sessionStorage.sessionId,
                "customer": { "organisation": customer.organisation, "firstName": customer.firstName, "lastName": customer.lastName, "email": customer.email, "vatNumber": customer.vatNumber, "commerceNumber": customer.commerceNumber},
                "billingAddress": { "line1": billingAddress.line1, "line2": billingAddress.line2, "city": billingAddress.city, "country": billingAddress.country, "postcode": billingAddress.postcode },
                "deliveryAddress": (shippingAddress !== null ? { "line1": shippingAddress.line1, "line2": shippingAddress.line2, "city": shippingAddress.city, "country": shippingAddress.country, "postcode": shippingAddress.postcode } : { "line1": "", "line2": "", "city": "", "country": "", "postcode": "" }),
                "paymentToken": "tknPayment",
                "deliverToBillingAddress": deliverToBillingAddress
            };
            // build validation on the data properly filled!!
            //const bearerToken = await this.props.auth.getTokenSilently();
            let result = await Axios.post("/api/checkout/cart", data);

            if (result.status === 200) {
                toast.info(`Bestelling verwerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                setLoading(false);
                setCheckoutDone(true);
            }
            else {
                setLoading(false);
                toast.error(`Bestelling niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            
        }
        setLoading(false);
    };

    /*
    const validateNew(e) {
        const { validate } = this.state
        if (this.state.newfirst === this.state.newlast) {
            validate.samenew = 'has-success'
        } else {
            validate.samenew = 'has-danger'
        }
        this.setState({ validate })
    };
    */

    return (
        <div>
            {!checkoutDone ?
                <div>
                    <Row >
                        <p><i>TOPO Schoonmaak is een groothandel, wij verkopen en leveren alleen aan bedrijven en alle prijzen op de website zijn exclusief BTW.</i></p><br />
                    </Row>
                {(userDetails !== null) ?
                        <Row>
                            
                        <Col md={8}>
                                <h6>Contactgegevens: <strong>{userDetails.firstName}{" "}{userDetails.lastName}</strong></h6>
                                <br />

                                {cartCustomers &&
                                <FormGroup row>
                                    <Label for="selectedCustomer" sm={2}><b>Voorkeur</b></Label>
                                    <Col sm={6}>
                                        <Input type="select" id="selectedCustomer" value={selCustomer}
                                            onChange={event => handleCustomerDetailsUpdated({ selCustomer: event.target.value })}
                                            placeholder="Voorkeur" >
                                            <option key={0} value={0}>Nieuwe contactgegevens</option>
                                            {cartCustomers.map((s) => (
                                                <option key={s.id} value={s.id}>{s.organisation} - {s.firstName} {s.lastName} - {s.vatNumber}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </FormGroup>
                                }
                                {(selCustomer === 0) ?
                                    <CustomerDetails onChanged={handleFormCustomerDetailsUpdated} />
                                    :
                                    <Row>
                                        <Col sm="8">
                                            <Card body>
                                                <CardTitle tag="h5">{customer.organisation}</CardTitle>
                                                <CardText>Contact: {customer.firstName} {customer.lastName}</CardText>
                                                <CardText>BTW: {customer.vatNumber}</CardText>
                                                <CardText>KVK: {customer.commerceNumber}</CardText>
                                            </Card>
                                            <br />
                                        </Col>
                                    </Row>
                                    
                                }

                                <h4>Factuur adres</h4>
                                {cartBillingAddresses &&
                                    <FormGroup row>
                                        <Label for="selectedBAddress" sm={2}><b>Voorkeur</b></Label>
                                        <Col sm={6}>
                                        <Input type="select" id="selectedBAddress" value={selBillingAddress}
                                            onChange={event => handleBillingComboChange({ selectedAddress: event.target.value })}
                                                placeholder="Voorkeur" >
                                                <option key={0} value={0}>Nieuwe adresgegevens</option>
                                                {cartBillingAddresses.map((s) => (
                                                    <option key={s.id} value={s.id}>{s.line1} - {s.postcode} {s.city}</option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                }
                                {(selBillingAddress === 0) ?
                                    <Address default={billingAddress} onChanged={handleFormBillingAddressUpdated} />
                                    :
                                    <Row>
                                        <Col sm="8">
                                            <Card body>
                                                <CardText>{billingAddress.line1}</CardText>
                                                <CardText>{billingAddress.line2}</CardText>
                                                <CardText>{billingAddress.postcode}{"  "}{billingAddress.city}</CardText>
                                                <CardText>{billingAddress.country}</CardText>
                                            </Card>
                                            <br />
                                        </Col>
                                    </Row>

                                }
                                
                                <Form>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" checked={deliverToBillingAddress}
                                                onChange={toggleUseBillingAddress} /> Bezorgen op factuuradres?
                                    </Label>
                                    </FormGroup>
                                </Form>


                            {!deliverToBillingAddress &&
                                <div>

                                {cartShippingAddresses &&
                                    <FormGroup row>
                                        <Label for="selectedDAddress" sm={2}><b>Bezorgen</b></Label>
                                        <Col sm={6}>
                                            <Input type="select" id="selectedDAddress" value={selShippingAddress}
                                                onChange={event => handleShippingComboChange({ selectedAddress: event.target.value })}
                                                placeholder="Voorkeur" >
                                                <option key={0} value={0}>Nieuw bezorgadres</option>
                                                {cartShippingAddresses.map((s) => (
                                                    <option key={s.id} value={s.id}>{s.line1} - {s.postcode} {s.city}</option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                }
                                    {(selShippingAddress === 0) ?
                                        <Address default={shippingAddress} onChanged={handleFormShippingAddressUpdated} />
                                    :
                                    <Row>
                                        <Col sm="8">
                                            <Card body>
                                                <CardText>{shippingAddress.line1}</CardText>
                                                <CardText>{shippingAddress.line2}</CardText>
                                                <CardText>{shippingAddress.postcode}{"  "}{shippingAddress.city}</CardText>
                                                <CardText>{shippingAddress.country}</CardText>
                                            </Card>
                                            <br />
                                        </Col>
                                    </Row>

                                }

                                </div>
                            }

                            {(loading === true) ?
                                    <div className='checkout'>
                                        <Spinner size="sm" color="secondary" /><p>Momentje geduld aub...</p>
                                        <Button color="secondary" block disabled>Bevestig en<br />Verstuur Order</Button>
                                    </div>
                                :
                                    <div className='checkout'>
                                        <Button color='primary' onClick={handlePaymentMethodChanged} block >Bevestig en<br />Verstuur Order</Button>
                                    </div>
                            }
                        </Col>
                        <Col md={3}>
                            <OrderSummary items={cartitems} />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col md={8}>
                            <h4>Uw contactgegevens</h4>
                            <CustomerDetails onChanged={newDetails => setCustomer(newDetails)} />

                            <h4>Factuuradres</h4>
                            <Address onChanged={newAddress => setBillingAddress(newAddress)} />

                            <Form>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked={deliverToBillingAddress}
                                            onChange={toggleUseBillingAddress} /> Bezorgen op factuuradres?
                            </Label>
                                </FormGroup>
                            </Form>


                            {!deliverToBillingAddress &&
                                <div>
                                    <h4>Afleveradres</h4>
                                    <Address onChanged={newAddress => setShippingAddress(newAddress)} />
                                </div>
                            }

                            <div className='checkout'>

                            {(loading === true) ?
                                <div>
                                    <Spinner size="sm" color="secondary" /><p>Momentje geduld aub... Uw order wordt verstuurd.</p>
                                    <Button color="secondary" block disabled >Bevestig en Verstuur Order</Button>
                                </div>
                                :
                                <Button color='primary' onClick={handlePaymentMethodChanged} block >Bevestig en<br />Verstuur Order</Button>
                            }
                               
                            </div>
                        </Col>
                        <Col md={3}>
                            <OrderSummary items={cartitems} />
                        </Col>
                    </Row>
                    }
                </div>
                :
                <Survey/> 
                    
            }
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />

            </div>


    )

}


/*
    
*/ 
