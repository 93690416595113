import React from 'react';
import { Col, Row } from 'reactstrap';


export default function MandatoryDetails() {
    return (
        <Col >
            <Row>
                <br />
                <Col >
                    <center>
                        <br />
                        <p><b>TOPO Schoonmaak B.V.</b>{" - "}Adres : Mon Plaisir 104, 4879 AT  Etten-Leur, Nederland - <strong>KvK</strong> : 81194153, <strong>BTW nr.</strong> : NL861980426B01<br /></p>
                    </center>
                </Col>
            </Row>    
            <Row>
                <br />
            </Row>
        </Col>        
    );
}
