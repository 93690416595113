import React from 'react';
import { Navigate } from 'react-router-dom';
import Axios from '../../context/axios';

function Logout(props) {
    const existingTokens = JSON.parse(sessionStorage.getItem("tokens"));
    if (existingTokens !== null) {

        let data = {
            sessionData: existingTokens.username
        }; 
        
        Axios.post('/account/logout', data).then(result => {
            if (result.status === 200) {
                sessionStorage.removeItem('tokens');
                sessionStorage.removeItem('portal');
                sessionStorage.clear();
                window.location.reload()
                return (<Navigate to="/" replace />);
            } else {
                //console.Log(result);
            
            }
        }).catch(e => {
            
        });
        
    }
    return (<Navigate to="/" replace />);

   
}
export default Logout;
