import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import StarRating from './StarRating';
import Axios from '../../context/axios';
import '../../custom.css'

export default function SurveyForm() {
    const [feedback, setFeedback] = useState("");
    const [ratings, setRatings] = useState({
        question1: 0,
        question2: 0,
        question3: 0
    });
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleRating = (question, rating) => {
        setRatings(prev => ({ ...prev, [question]: rating }));
        // If rating is provided, clear error for that question
        if (rating > 0) {
        setErrors(prevErrors => ({ ...prevErrors, [question]: false }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        let isInvalid = true;
        // Check each question for a rating
        Object.keys(ratings).forEach(question => {
        if (ratings[question] === 0) {
            newErrors[question] = true; // Mark as error if no rating
            isInvalid = false;
        }
        });
        setErrors(newErrors);
        return isInvalid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        let transformedData = Object.keys(ratings).map((key, index) => {
            return {
                Id: index + 1,
                QuestionId: `Q${index + 1}`,
                Rating: ratings[key]
            };
        });
    
        let sessionId = sessionStorage.getItem('sessionId');
        if (!sessionId) {
            return;
        }
    
        let txtaction = 'SRV'; 
        let orderinfo = JSON.stringify(transformedData) + "@@@" + feedback;
        let request = { order: orderinfo, sessionId, action: txtaction };
    
        try {
            let response = await Axios.post('/api/cart/survey', request);
            if (response.status === 200) {
                toast.info('Resultaten opgeslagen, dank U voor uw tijd.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
    
                navigate('/checkoutsuccess', { replace: true });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle the error (e.g., show an error toast)
        }
    };

    return (
        <div className="survey-container">
        
        <form onSubmit={handleSubmit}>
            {/* Repeat for each question */}
            <StarRating question="Had u voldoende informatie bij de product(en) die u zocht?" onRating={(rating) => handleRating('question1', rating)} />
            {errors.question1 && <p className='errorsubmit'>Een score geven is verplicht. 1 is slecht, 3 is neutraal en 5 is absoluut.</p>}
            
            <br />

            <StarRating question="Hoe was uw ervaring op onze webshop?" onRating={(rating) => handleRating('question2', rating)} />
            {errors.question2 && <p className='errorsubmit'>Een score geven is verplicht. 1 is slecht, 3 is neutraal en 5 is geweldig.</p>}
            
            <br />

            <StarRating question="Zou u ons aanbevelen bij uw relaties en/of kennissen?" onRating={(rating) => handleRating('question3', rating)} />
            {errors.question3 && <p className='errorsubmit'>Een score geven is verplicht. 1 is slecht, 3 is neutraal en 5 is absoluut.</p>}
            
            <br />
            <br />

            <label><h5>Opmerkingen</h5></label>
            <textarea  name="feedback" 
            className="textwide"
            placeholder="Mocht u nog verdere opmerkingen hebben, dan kunt u die hier kwijt." 
            value={feedback} 
            onChange={(e) => setFeedback(e.target.value)} 
            width="auto"
            rows={4} 
            />

            <br />
            <br />

            <Button color="primary" type="submit">Versturen</Button>

        </form>
        <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
        </div>
    );
};

