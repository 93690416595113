import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Row, Col} from 'reactstrap';
import {FiHome} from "react-icons/fi";
//import checkoutimages from "../../assets/images/workingonit.png";
import checkoutimages from "../../assets/images/topovan.jpg";
import '../../custom.css';

export default function CheckoutSuccess() {
    return (
        <Col className="custom-col">
            {/* Use justify-content-center for horizontal centering */}
            <Row className="component-center">
                <br />
            </Row>
            <Row className="text-center">
                <h1>Dank u voor uw bestelling</h1>
            </Row>
            <Row className="component-center">
                <br />
            </Row>
            <Row className="text-center">
                <h5>Wij gaan nu uw bestelling bekijken en voorbereiden.</h5>
                <h5>Bij twijfel nemen we altijd contact met u op via de bij ons bekende gegevens.</h5>
            </Row>
            <Row className="component-center">
                <br />
            </Row>
            <Row className="text-center">
                <h5 style={{ color: 'red' }}>Mocht u binnen 5 minuten geen bevestiging hebben gekregen in uw email,<br />
                dan adviseren wij u in uw "ongewenste email" of "spam folder" te kijken.</h5>
            </Row>
            <Row className="component-center">
                <Col className="text-center">
                    <img src={checkoutimages} className="img-shadowdrop-checkout" alt="" />
                </Col>
            </Row>
            <Row className="component-center">
                <br />
            </Row>
            <Row className="text-center">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button color='primary'>Terug naar <FiHome /> hoofdpagina</Button>
                </Link>
                <Col ></Col>
            </Row>
            <Row className="component-center">
                <br />
            </Row>
        </Col>
    );
}