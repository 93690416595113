import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button } from 'reactstrap';
import Axios from '../../context/axios';
import { ToastContainer, toast } from 'react-toastify';
import MaintainCategoryClass from "./MaintainCategoryClass";
import Spinner from '../../components/Spinner';

export default function MaintainCategories() {
    //const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editmode, setEditMode] = useState(false);

    useEffect(() => {
        async function init() {
            try {
                setLoading(true);
                let runUrl = '/api/product/categories';
                const response = await Axios(runUrl);
                if (response.status === 200) {
                    setCategories(response.data);
                    setLoading(false);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);


    async function onCancelCategoryData(e) {

        setLoading(true);
        // Update table by adjusting addresses....
        toast.info(`Categorie gegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentCategory([]);
        setLoading(false);
    };

    /*
    async function onUpdateCategoryData(e) {
        if (loading === false) {
            setLoading(true);
            try {
                let updatedData = JSON.stringify({ currentCategory });
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let runUrl = '/api/prefadmin/' + xd;
                var data = {
                    plu: updatedData,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'VU'
                }

                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== null) && (userDetails !== null)) {
                    sessionStorage.sessionId = sessionId;
                    let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                    let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                    let result = await Axios.post(runUrl, data, axiosConfig);
                    
                    if (result.status === 200) {
                        setCurrentCategory([]);
                        toast.info(`Categorie gegevens bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        setCategories(result.data.categories);
                    } else {
                        toast.error(`Categorie gegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }
                
                // Update table by adjusting addresses....
                setEditMode(false);
                setLoading(false);

            } catch (ex) {
                console.log(ex);
                toast.error(`Er is een fout opgetreden bij het opslaan van categorie gegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
        }
    };
    */

    const selectCategoryData = (e) => {
        setEditMode(true);
        let cursku = categories.filter(category => category.id === Number(e.target.value));
        let categorystring = cursku[0].name;
        setCurrentCategory(cursku[0]);

        toast.info(`Gegevens van ${categorystring} geselecteerd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    };

   
    function handleCategoryDetailsUpdated(newCategoryDetails) {
        setCurrentCategory(newCategoryDetails);
        
    };



    return (
        <div>
            <Col>
                <br />
                <Row>
                    {(loading) && <Spinner /> }
                    {(currentCategory.length === 0) ?
                        <Table cellPadding={10} >
                            <thead>
                                <tr>
                                    <td><b>Categorie</b></td>
                                    <td><b>Plaatje</b></td>
                                    <td><b>Actief?</b></td>
                                    <td><b>Acties</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categories.map((c) => (
                                        <tr key={c.id}>
                                            <td>{c.name}</td>
                                            <td><img width="150px" height="150px" src={c.image} alt={c.name} /></td>
                                            <td>{c.Active}</td>
                                            <td><Button color="warning" value={c.id} disabled={editmode} size="sm" onClick={(e) => { selectCategoryData(e); setEditMode(true); }}>Bekijken</Button></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        :
                        <Col>
                            <h6><b>Onderhouden Categorie gegevens</b></h6>
                        </Col>

                    }
                    <br />

                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />

                    <br />
                    {(currentCategory.length !== 0) ?
                        <MaintainCategoryClass editcategory={currentCategory} onChanged={handleCategoryDetailsUpdated} />
                        :
                        <p></p>
                    }

                        
                    { ((currentCategory.length !== 0) && (editmode === true)) ?
                            <Col>
                                <br />
                                <Button color="primary" onClick={(e) => { onCancelCategoryData(e); setEditMode(false); }} >Terug</Button>
                                {" "}
                            </Col>
                        : <p></p>
                    }

                    { (editmode === false && (currentCategory.length !== 0)) ?
                            <Col>
                                <br />
                                <Button color="primary" disabled >Terug</Button>
                                {" "}
                            </Col>
                        : <p></p>
                    }
                </Row>
                <Row>
                    <br />
                </Row>
            </Col>
            <Col>
                <br />
            </Col>
            
        </div>
    );

}


/*
Removed Edit mode capabilities.

{ ((currentCategory.length !== 0) && (editmode === true)) ?
                            <Col>
                                <br />
                                <Button color="primary" onClick={(e) => { onCancelCategoryData(e); setEditMode(false); }} >Annuleren</Button>
                                {" "}
                                <Button color="primary" onClick={(e) => { toast.info(`Opslaan functie nog niet actief.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, }); }} >Opslaan Categorie</Button>
                            </Col>
                        : <p></p>
                    }

{ (editmode === false && (currentCategory.length !== 0)) ?
                            <Col>
                                <br />
                                <Button color="primary" disabled >Annuleren</Button>
                                {" "}
                                <Button color="primary" disabled >Opslaan Categorie gegevens</Button>
                                <p><i>Momentje geduld aub. We werken de gegevens bij. Dit kan een paar seconden duren...<br />We geven een melding rechtsboven in het scherm als het gelukt is.</i></p>
                            </Col>
                        : <p></p>
                    }



*/



/*
toast.info(`functie nog niet actief.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });


                                <Button color="primary" onClick={(e) => { onCancelCategoryData(e); setEditMode(false); }} >Annuleren</Button>
                                {" "}
                                <Button color="primary" onClick={(e) => { onUpdateCategoryData(e); setEditMode(false); }} >Opslaan Categorie</Button>
 

*/


/*
<td><Button color="danger" value={c.id} disabled={editmode} size="sm" onClick={(e) => { deleteInvoiceData(e); setEditMode(true); }}>Verwijder</Button> {"  "}<Button color="warning" value={c.id} disabled={editmode} size="sm" onClick={(e) => { selectInvoiceData(e); setEditMode(true); }}>Aanpassen</Button></td>



<br />
                    {(currentCustomer.length !== 0) ?
                        <MainUserCustomerClass cusdata={currentCustomer} onChanged={handleCustomerDetailsUpdated} />
                        :
                        <p></p>
                    }
                    {(currentCustomer.length !== 0) ?
                        <Col>
                            <br />
                            <Button color="primary" onClick={(e) => { onCancelInvoiceData(e); setEditMode(false); }} >Annuleren</Button>
                            {" "}
                            <Button color="primary" onClick={(e) => { onUpdateInvoiceData(e); setEditMode(false); }} >Opslaan facturatiegegevens</Button>
                        </Col>
                        :
                        <p></p>
                    }

                    {(portalr !== 'G' && currentCustomer.length === 0) ?
                        <Col>
                            <Button color="primary" onClick={(e) => { onCreateInvoiceData(e); }} >Toevoegen facturatiegegevens</Button>
                        </Col>
                        :
                        <p></p>
                    }



*/