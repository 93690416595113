import React, { useState, useEffect } from "react";
import { Col, Row, TabContent, NavLink, Nav, NavItem, TabPane, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Axios from '../../../context/axios';
import { FiBriefcase, FiUser, FiArchive, FiList, FiLoader } from "react-icons/fi";
import { ToastContainer } from 'react-toastify';
import MaintainSliders from '../MaintainSliders';
import MaintainCategories from '../MaintainCategories';
import TopProductsList from "./TopProducts";
//import TopVisitorsList from "./TopVisitors";
import TopSearchedList from "./TopSearched";
import CustomerList from "./CustomerList";
import AdminSessionsList from "./AdminSessionList";
import HistoricalOrdersList from "../HistoricalOrdersList";
import classnames from 'classnames';

export default function MainAdminPreferences() {
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [loading, setLoading] = useState(true);
    const [infoX, setInfoX] = useState("");
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    useEffect(() => {
        async function init() {
            try {
                setLoading(false);
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl = '/api/prefadmin/nfoxch/' + xd;
                const response = await Axios.get(runUrl, axiosConfig);
                if (response.status === 200) {
                    setInfoX(response.info);
                    setLoading(false);
                }
            } catch (e) {} 
            finally {
            }
        }
        init();
    }, []);

    function Portal() {
        return (
            <div>
                <Col className="colcontainer">
                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} />

                    <Col>
                        <Row>
                            <h5>Welkom beste {infoX === "Admin" && <i>(admin)</i>} <b>{userDetails.firstName}{" "}{userDetails.lastName}</b>{"  "}</h5>
                            <br />
                        </Row>
                        <Row>
                            <p>Vanuit deze pagina kunt u de basisgegevens van de webshop onderhouden. Vergeet u ook niet een goed idee met ons te delen om uw ervaring nog prettiger te maken.</p>
                        </Row>
                        <Row>
                            <p>Klik hier om naar naar <b><Link to="/maintenance" style={{ textDecoration: 'none' }}>Onderhouden Producten</Link></b> te gaan.</p>
                            <br />
                            <br />
                        </Row>
                    </Col>
                    <Col>
                        <Nav tabs className="navtabsleft">
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} ><b><FiBriefcase />{" "}Onderhouden</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiArchive />{" "}Recente orders</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} ><b><FiList />{" "}Top Producten</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }} ><b><FiLoader />{" "}Zoek Producten</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }} ><b>{" "}Advertenties</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '6' })} onClick={() => { toggle('6'); }} ><b>{" "}Categoriëen</b></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }} ><b><FiUser />{" "}Contact & Sessies</b></NavLink>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1" className="colcontainer">
                                <Row>
                                    <CustomerList />
                                </Row>
                            </TabPane>
                            <TabPane tabId="2" className="colcontainer">
                                <Row>
                                    <Col>
                                        <Row>
                                            <HistoricalOrdersList />
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3" className="colcontainer">
                                <Row>
                                    <Col>
                                        <Row>
                                            <TopProductsList />
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4" className="colcontainer">
                                <Row>
                                    <Col>
                                        <Row>
                                            <TopSearchedList />
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="5" className="colcontainer">
                                    <Row>
                                        <Col>
                                            <Row>
                                                <MaintainSliders />
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="6" className="colcontainer">
                                    <Row>
                                        <Col>
                                            <Row>
                                                <MaintainCategories />
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="7" className="colcontainer">
                                <Row>
                                    <Col>
                                        <AdminSessionsList />

                                    </Col>
                                </Row>
                            </TabPane>
                            

                        </TabContent>

                        <br />
                        <hr />
                    </Col>
                </Col>
            </div>
        );
    }

    return (
        <div>            
            <Col>
                {(loading) ?
                    <Row>
                        <Link color="primary" to="/user_portal" className="btn btn-primary">Ophalen informatie</Link>
                        <Spinner size="sm" color="secondary" /><p>We halen uw gegevens op, een momentje geduld aub...</p>
                    </Row>
                    :
                    <Portal />
                }
            </Col>
            
            
            
        </div>
    );
}
/*

<ToastContainer position="bottom-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
*/