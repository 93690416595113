import React, { useState, useEffect } from 'react';
import { Col, Row, TabContent, NavLink, Nav, NavItem, TabPane } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import { MaintainProductList } from './MaintainProductList';
import MaintainUsers from './MaintainUsers';
//import { MaintainSourceData } from './MaintainSourceData';
import { FiGrid, FiUsers } from "react-icons/fi";
import Axios from '../../context/axios';
import classnames from 'classnames';

export function MaintainProduct() {

    const [userDetails, setUserDetails] = useState([]);
    const [validSession, setValidSession] = useState(true);
    const [activeTab, setActiveTab] = useState('1');

    useEffect(() => {
        async function init() {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            const response = await Axios.get('/account/activeusers', { headers: { "Authorization": `Bearer ${ud}` } })

            // review code... old code above, new code below
            //const response = await Axios.get('/account/activeusers');
            if (response.status === 200) {
                setUserDetails(JSON.parse(sessionStorage.getItem("tokens")));
                setValidSession(true);
            } else {
                setValidSession(false);
            }
        }
        init();
    }, []);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    if ((userDetails !== null) && (validSession)) {
        return (
            <div>
                <Col className="colcontainer">
                    <Row >
                        <p className='link-danger mx-2'><b><i>Let op!</i></b><i> U bent nu in de onderhoudsfuncties van TOPO Schoonmaak Groothandel.</i></p>
                        <hr />
                    </Row>
                </Col>
                <Col className="colcontainer">
                    <Nav tabs className="navtabsleft">
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} ><b><FiGrid />{" "}Onderhoud Producten</b></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiUsers />{" "}Onderhoud Gebruikers</b></NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="colcontainer">
                            <Row>
                                <Col>
                                    <h5>Product Onderhoud</h5>
                                    <MaintainProductList/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2" className="colcontainer">
                            <Row>
                                <Col>
                                    <MaintainUsers />
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    <br />
                    
                </Col>
                
            </div>
        );
    }
    else {
        return <Navigate to='/login' replace />;
    }

}


/* Restore 
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiArchive />{" "}Toevoegen Stamdata</b></NavLink>
                        </NavItem>
                   <TabPane tabId="2" className="colcontainer">
                            <Row>
                                <Col>
                                    <MaintainSourceData />
                                </Col>
                            </Row>
                        </TabPane>    
*/