import React from "react";
import { Col, Row, Form, FormGroup, Input, Label } from "reactstrap";

export default class MaintainCategoryClass extends React.Component {

    state = {
        id: this.props.editcategory.id,
        name: this.props.editcategory.name,
        image: this.props.editcategory.image,
        active: this.props.editcategory.active
    };


    handleChange(newState) {
        this.setState(newState, () => this.props.onChanged(this.state));
    }

    handleDrop(e) {
        e.preventDefault();

        let allowedTypes = ['image/jpeg']; // Allowed file types
        const file = e.dataTransfer.files[0];
        if (allowedTypes.includes(file.type))
        {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.handleChange('image', e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }

    render() {
        return (
            <Form>
                <Row>
                    <Col sm={6}>
                    
                        <FormGroup row>
                            <Label for="name" sm={3}>Naam</Label>
                            <Col sm={6}>
                                <Input type="text" id="name" value={this.state.name}
                                    onChange={event => this.handleChange({ name: event.target.value })}
                                    placeholder="Naam" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={3}>Omschrijving</Label>
                            <Col sm={6}>
                                <Input type="text" id="description" value={this.state.description}
                                    onChange={event => this.handleChange({ description: event.target.value })}
                                    placeholder="Omschrijving" />
                            </Col>
                        </FormGroup>
                        
                        <FormGroup row>
                            <Label for="active" sm={3}>Actief</Label>
                            <Col sm={6}>
                                <Input type="select" id="active" value={this.state.active}
                                    onChange={event => this.handleChange({ active: event.target.value })}
                                    placeholder="Actief" >
                                    <option value={true}>Ja</option>
                                    <option value={false}>Nee</option>
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <div onDrop={this.handleDrop} onDragOver={this.allowDrop} style={{ border: '1px solid black', minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {this.state.image ? <img src={this.state.image} alt="Slider" style={{ maxWidth: '100%', maxHeight: '200px' }} /> : 'Drop an image here'}
                        </div>
                    </Col>
                </Row>
            </Form>
        )
    }

}


/*
<img  src={this.state.image} alt={this.state.image} />

                                <Input type="text" id="startdate" value={this.state.startdate}
                                    onChange={event => this.handleChange({ startdate: event.target.value })}
                                    placeholder="Start Datum" />

                     <Input type="text" id="enddate" value={this.state.enddate}
                                    onChange={event => this.handleChange({ enddate: event.target.value })}
                                    placeholder="Eind Datum" />               

*/