import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardImg } from 'reactstrap';
import { Link } from 'react-router-dom';
import Axios from '../../context/axios';
import {  MdBusiness } from "react-icons/md";
import '../../custom.css'

export function ProductCategoryList() {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        async function init() {
            try {
                let getUrl = `/api/product/categories`;
                const response = await Axios(getUrl);
                if (response.status === 200) {
                    setCategories(response.data || []); // Ensure it's always an array
                } else {
                    throw response;
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);

    
    function renderCategories(p) {
        return (
            <Card key={p.id} className="categorycard" width="50%"  >
                <Link to={`/product/category/${p.name.toLowerCase()}`} style={{ textDecoration: 'none', color: "black" }}>
                    <Col width="50%">
                        <div className="text-center">
                            <br />
                            <CardImg alt={p.name} src={p.image} top height="175px" width="100%"  />
                        </div>
                    </Col>
                    <Col width="50%">
                        <div className="text-center">
                            <h4 className="topoH4"><b>{p.name}</b></h4>
                        </div>
                    </Col>
                </Link>
            </Card>
        );
    }

    return (
        <div>
            <Row >
                <h2 ><MdBusiness size="35" />{" "}Product Categoriëen</h2><br />
            </Row>
            
            <Row >
                {categories.map(renderCategories)}
            </Row>
            
            <Row >
                <br />
            </Row>
        </div>
    );
}
