import React, { Component } from 'react';
import { ServicesList } from './ServicesList';

export default class ServicesPage extends Component {

    render() {
        return (
            <div>
                <ServicesList />
            </div>
        );
    }
}