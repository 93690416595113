import React from 'react';
import BrandLogoSlider from './BrandSlider';
import TopSlider from './TopSlider';

export default function Home()  {

    return (
        <div>
            <TopSlider />
            <BrandLogoSlider />
        </div>
    );

}
