import React, { Component } from 'react';
import { PromotionList } from './PromotionList';

export default class ProductPage extends Component {
    
    render() {
        return (
            <div>
                <PromotionList />
            </div>
        );
    }
}