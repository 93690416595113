import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button } from 'reactstrap';
import Axios from "../../../context/axios";
import { ToastContainer, toast } from 'react-toastify';
import MainUserCustomerAdminClass from '../MainUserCustomerAdminClass';

export default function CustomerList() {
    const [portalOverride] = useState(JSON.parse(sessionStorage.getItem("portalswitch")));
    const [customersList, setCustomersList] = useState([]);
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    const [currentCustomer, setCurrentCustomer] = useState([]);
    const [editmode, setEditMode] = useState(false);
    //const [firstmode, setFirstMode] = useState(true);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        async function init() {
            try {
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl = '/api/prefadmin/precus/' + xd;
                const response = await Axios.get(runUrl, axiosConfig);
                if (response.status === 200) {
                    setCustomersList(response.data);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);

    /*
    <!-- MainUserCustomerAdmin cusdata={prefCustomerDetails} / -->
    */
    async function onCancelInvoiceData(e) {

        setLoading(true);
        // Update table by adjusting addresses....
        toast.info(`Facturatiegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentCustomer([]);
        setLoading(false);
    };

    async function onUpdateInvoiceData(e) {

        if (loading === false) {
            setLoading(true);
            try {
                let updatedData = JSON.stringify({ currentCustomer });

                var data = {
                    plu: updatedData,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'CU'
                }

                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== null) && (userDetails !== null)) {
                    sessionStorage.sessionId = sessionId;
                    let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                    const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                    let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                    let runUrl = '/api/prefadmin/' + xd;
                    const response = await Axios.post(runUrl, data, axiosConfig);
                    if (response.status === 200) {
                        //setFirstMode(false);
                        setCustomersList(response.data.customer);
                        toast.info(`Facturatiegegevens bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });

                    } else {
                        toast.error(`Facturatiegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }

                // Update table by adjusting addresses....
                setCurrentCustomer([]);
                setLoading(false);

            } catch (ex) {
                console.log(ex);
                toast.error(`Er is een fout opgetreden bij het opslaan van facturatiegegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
        }
    };

    const selectInvoiceData = (e) => {
        let cursku = customersList.filter(customer => customer.id === Number(e.target.value));
        let customerstring = cursku[0].organisation;
        setCurrentCustomer(cursku[0]);

        toast.info(`Facturatiegegevens van ${customerstring} geselecteerd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    };

    async function onCreateInvoiceData(e) {
        if (loading === false) {
            setLoading(true);
            try {
                if (sessionStorage.sessionId !== undefined) {
                    sessionStorage.sessionId = "No Session yet";
                }

                var data = {
                    plu: e.target.value,
                    sessionId: sessionStorage.sessionId,
                    username: userDetails.username,
                    token: userDetails.jwttoken,
                    action: 'CC'
                }
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let sessionId = sessionStorage.sessionId;
                if ((sessionId !== undefined) && (userDetails !== undefined)) {
                    sessionStorage.sessionId = sessionId;
                    let result = await Axios.post('/api/prefuser', data, axiosConfig);
                    if (result.status === 200) {
                        toast.info(`Nieuwe facturatiegegevens toegevoegd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        //setFirstMode(false);
                        setCustomersList(result.data.customer);
                        setLoading(false);
                    } else {
                        toast.error(`Nieuwe facturatiegegevens niet toegevoegd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }
                }
            } catch (ex) {
                console.log(ex);
                toast.error(`Er is een fout opgetreden bij het toevoegen van nieuwe facturatiegegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            setLoading(false);
        }
    };

    const deleteInvoiceData = async (e) => {
        if (loading === false) {
            setLoading(true);
            if (customersList.length > 1) {
                try {
                    var data = {
                        plu: e.target.value,
                        sessionId: sessionStorage.sessionId,
                        username: userDetails.username,
                        token: userDetails.jwttoken,
                        action: 'CD'
                    }
                    let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                    let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                    let result = await Axios.post('api/prefuser', data, axiosConfig);
                    if (result.status === 200) {
                        //setFirstMode(false);
                        toast.info(`Facturatiegegevens verwijderd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                        setCustomersList(result.data.customer);
                        setLoading(false);
                    }
                    else {
                        toast.warning(`Probleem bij het verwijderen van facturatiegegevens. Raadpleeg uw Administrator`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                    }

                } catch (ex) {
                    console.log(ex);
                    toast.error(`Fout bij het verwijderen van facturatiegegevens. Raadpleeg uw Administrator`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            }
            else {
                toast.warning(`Er dient altijd ��n set facturatiegegevens te zijn.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            setLoading(false);
        }
    };

    function handleCustomerDetailsUpdated(newCustomerDetails) {
        setCurrentCustomer(newCustomerDetails);
    }




    return (
        <div>
            <Col>
                <Row>
                    <p>Hieronder kunt u klantgegevens bijwerken als admin. Bijvoorbeeld als u een klant nummer wil toekennen.
                    </p>
                </Row>
                <br />
                <Row>
                    {(currentCustomer.length === 0) ?
                        <Table cellPadding={10} >
                            <thead>
                                <tr>
                                    <td><b>Organisatie</b></td>
                                    <td><b>Voornaam</b></td>
                                    <td><b>Achternaam</b></td>
                                    <td><b>BTW Nummer</b></td>
                                    <td><b>Klant Nummer</b></td>
                                    <td><b>Functies</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customersList.map((c) => (
                                        <tr key={c.id}>
                                            <td>{c.organisation}</td>
                                            <td>{c.firstName}</td>
                                            <td>{c.lastName}</td>
                                            <td>{c.vatNumber}</td>
                                            <td>{c.customerNumber}</td>
                                            <td><Button color="danger" value={c.id} disabled={editmode} size="sm" onClick={(e) => { deleteInvoiceData(e); setEditMode(true); }}>Verwijder</Button>{" "}-{" "}<Button color="warning" value={c.id} disabled={editmode} size="sm" onClick={(e) => { selectInvoiceData(e); setEditMode(true); }}>Aanpassen</Button></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    :
                        <Col>
                            <h6><b>Onderhouden klantgegevens</b></h6>
                        </Col>
                    }
                    <br />

                    <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />

                    <br />
                    {(currentCustomer.length !== 0) ?
                        <MainUserCustomerAdminClass cusdata={currentCustomer} onChanged={handleCustomerDetailsUpdated} />
                        :
                        <p></p>
                    }

                        
                    { ((currentCustomer.length !== 0) && (editmode === true)) ?
                            <Col>
                                <br />
                                <Button color="primary" onClick={(e) => { onCancelInvoiceData(e); setEditMode(false); }} >Annuleren</Button>
                                {" "}
                                <Button color="primary" onClick={(e) => { onUpdateInvoiceData(e); setEditMode(false); }} >Opslaan facturatiegegevens</Button>
                            </Col>
                        : <p></p>
                    }

                    { (editmode === false && (currentCustomer.length !== 0)) ?
                            <Col>
                                <br />
                                <Button color="primary" disabled >Annuleren</Button>
                                {" "}
                                <Button color="primary" disabled >Opslaan facturatiegegevens</Button>
                                <p><i>Momentje geduld aub. We werken de gegevens bij. Dit kan een paar seconden duren...<br />We geven een melding rechtsboven in het scherm als het gelukt is.</i></p>
                            </Col>
                        : <p></p>
                    }

                    {(portalOverride === 'U' && currentCustomer.length === 0) ?
                        <Button color="primary" onClick={(e) => { onCreateInvoiceData(e); }} >Toevoegen facturatiegegevens</Button> : <p></p>
                    }

                </Row>
            </Col>
        </div>
    );

}


/*

<Col>
                                        
    {(prefCustomerDetails) ?

        <Row>
            < br />
            
            <CustomerList />
        </Row>
        :
        <Row>
            < br />
            <h5>Geen voorkeurs factuurgegevens. Heeft u al eens bij ons besteld ?</h5>
        </Row>
    }
    
</Col>
*/


