import { React, createContext, useContext } from 'react';
import jwt from "jsonwebtoken";
import Axios from '../context/axios';
import { Navigate } from 'react-router-dom';

export const AuthContext = createContext();

export function useAuth() {
    function init() {
        setInterceptors();
    }
    init();

    function setInterceptors() {
        const token = sessionStorage.getItem('tokens');
        if (token) { Axios.defaults.headers.common['jwttoken'] = sessionStorage.getItem("tokens").jwttoken; }
    }


    // Request Interceptor
    Axios.interceptors.request.use(async (req) => {

        //const baseURL = 'https://previewwebshoptopoapi.azurewebsites.net';
        //const baseURL = 'https://localhost:5000';
        //const baseURL = 'https://topoapi.azurewebsites.net';
        const baseURL = 'https://webshoptopoapi.azurewebsites.net';
        


        // Below code is to check if the JWT token has not expired. If expired, refresh it via a fetch before continuing.
        const token = sessionStorage.getItem('tokens');
        if (token) {
            let tokenjwt = JSON.parse(sessionStorage.getItem("tokens"));
            let urlRefreshToken = baseURL + '/account/refresh-token';
            let decodedToken = jwt.decode(tokenjwt.jwttoken, { complete: true });
            let dateNow = Math.floor(+new Date() / 1000);
            //let date = new Date(decodedToken.payload.exp * 1000);
            //console.log("DueDate token: " + date);

            if (dateNow > decodedToken.payload.exp) {
                console.log('token expired, refreshing...')
                await fetch(urlRefreshToken,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'token': tokenjwt.refreshToken
                        }
                    })
                    .then(async (response) => {
                        console.log('token refresh request success')
                        const resData = await response.json();
                        console.log(resData);
                        if (response.status === 200) {
                            const token = resData.jwttoken;
                            sessionStorage.setItem('tokens', JSON.stringify(resData));
                            req.headers.Authorization = `Bearer ${token}`;
                            //console.log('token refresh response success')
                            console.log('S - aftr - TReq');
                        }
                        else {
                            console.log('It was not possible to renew the token')
                            console.log('S else - aftreq - TR');
                            sessionStorage.removeItem('tokens');
                            sessionStorage.clear();
                            window.location.reload()
                            return (<Navigate to="/" replace />);
                        }
                    },
                        (error) => {
                            console.log('token refresh error')

                        })
                    .catch((error) => {
                        console.log('Caught token refresh error')
                        sessionStorage.removeItem('tokens');
                        sessionStorage.clear();
                        window.location.reload();
                        return (<Navigate to="/login" replace />);   
                    })
            }
        }
        return req;

    });

    return useContext(AuthContext);
}


Axios.interceptors.response.use(

    response => {
      // If the request succeeds, just return the response
      return response;
    },
    error => {
      // If the request fails with a 401 error, redirect to login
      if (error.response.status === 401) {
        
        //const baseURL = 'https://previewwebshoptopoapi.azurewebsites.net';
        //const baseURL = 'https://localhost:5000';
        //const baseURL = 'https://topoapi.azurewebsites.net';
        const baseURL = 'https://webshoptopoapi.azurewebsites.net';
        

        // Below code is to check if the JWT token has not expired. If expired, refresh it via a fetch before continuing.
        const token = sessionStorage.getItem('tokens');
        if (token) {
            let tokenjwt = JSON.parse(sessionStorage.getItem("tokens"));
            let urlRefreshToken = baseURL + '/account/refresh-token';
            let decodedToken = jwt.decode(tokenjwt.jwttoken, { complete: true });
            let dateNow = Math.floor(+new Date() / 1000);
            //let date = new Date(decodedToken.payload.exp * 1000);
            //console.log("DueDate token: " + date);

            if (dateNow > decodedToken.payload.exp) {
                console.log('token expired, refreshing...')
                fetch(urlRefreshToken,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'token': tokenjwt.refreshToken
                        }
                    })
                    .then(async (response) => {
                        console.log('token refresh request success')
                        const resData = await response.json();
                        console.log(resData);
                        if (response.status === 200) {
                            sessionStorage.setItem('tokens', JSON.stringify(resData));
                            //console.log('token refresh response success')
                            console.log('S - aftr - TResp');
                        }
                        else {
                            console.log('It was not possible to renew the token')
                            console.log('S else - aftrwap - TR');
                            sessionStorage.removeItem('tokens');
                            sessionStorage.clear();
                            window.location.reload()
                            return (<Navigate to="/" replace />);
                        }
                    },
                        (error) => {
                            console.log('token refresh error')

                        })
                    .catch((error) => {
                        console.log('Caught token refresh error')
                        sessionStorage.removeItem('tokens');
                        sessionStorage.clear();
                        window.location.reload();
                        return (<Navigate to="/login" replace />);   
                    })
            }
        }
        //return response;
      }

      return Promise.reject(error);
    }
  );
  