import React from 'react';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';

export default class MaintainInfoClass extends React.Component {

    state = {
        id: Number(this.props.editinfo.id),
        productid: Number(this.props.editinfo.productId),
        displaytype: Number(this.props.editinfo.displayType),
        sequence: Number(this.props.editinfo.sequence),
        infokey: this.props.editinfo.infoKey,
        infotext: this.props.editinfo.infoText,
        infoimage: this.props.editinfo.infoImage
    };

    handleChange(newState) {
        this.setState(newState, () => {
            if (this.props.onChanged)
                this.props.onChanged(this.state);
        });
    }

    render() {
        return (
            <Form >
                <FormGroup row className="slimFormRow">
                    <Label for="displaytype" sm={3}>Type</Label>
                    <Col sm={6}>
                        <Input type="select" id="displaytype" value={this.state.displaytype}
                            onChange={event => this.handleChange({ displaytype: event.target.value })}
                            placeholder="Type">
                            <option value="0">Lege regel</option>
                            <option value="1">Koptekst Vet gedrukt</option>
                            <option value="3">Koptekst</option>
                            <option value="4">Tekst Vet gedrukt</option>
                            <option value="2">Tekst</option>
                            <option value="5">Tabel (voorbeeld: - Waarde)</option>
                            <option value="6">Tekst schuingedrukt(</option>
                            <option value="20">Foto/Plaatje</option>
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="infotext" sm={3}>Tekst</Label>
                    <Col sm={6}>
                        <Input type="text" id="infotext" value={this.state.infotext}
                            onChange={event => this.handleChange({ infotext: event.target.value })}
                            placeholder="Tekst" />
                    </Col>
                </FormGroup>
                <FormGroup row className="slimFormRow">
                    <Label for="infoimage" sm={3}>Link Foto</Label>
                    <Col sm={6}>
                        <Input type="text" id="infoimage" value={this.state.infoimage}
                            onChange={event => this.handleChange({ infoimage: event.target.value })}
                            placeholder="link naar foto" />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}