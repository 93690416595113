import React, { Component } from 'react';
import { ProductCategoryList } from './ProductCategoryList';

export default class ProductCategoriesPage extends Component {
    
    render() {
        return (
            <div>
                <ProductCategoryList />
            </div>
        );
    }
}