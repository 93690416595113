import React from 'react';
import Checkout from './Checkout';

export default class CheckoutPage extends React.Component {

    render() {
        return (
            <Checkout auth={this.props.auth} />
        )
    }

}
