import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'reactstrap';

export default function Footer () {
    const prevScrollY = useRef(0);
    const [isScroll, setIsScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (prevScrollY.current !== currentScrollY) {
                setIsScroll(true);
            }
            if (currentScrollY === 0) {
                setIsScroll(false);
            }
        };
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, [isScroll]);



    
    return (
        <div>
            { !isScroll &&
            <div className="footer">
                <Container className="footercontainer">
                    <center><h6> © Copyright 2024 - <b>TOPO Schoonmaak</b> - Adres: Mon Plaisir 104, 4879 AT  Etten-Leur, Nederland - KvK: 81194153, BTW nr.: NL861980426B01</h6></center>
                </Container>
            </div>
            }
        </div>
    )
}

