import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { Row, Col, Card, CardImg, CardBody, CardText, CardTitle, Button } from 'reactstrap';
import Axios from '../../context/axios';
import Spinner from '../Spinner';
import { MdAddShoppingCart, MdTrendingFlat, MdBusiness } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../custom.css'

export default function PerCategoryList() {
    const { id } = useParams();
    const [title, setTitle] = useState("");
    
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uniqueBrands, setuniqueBrands]=useState([], "");
    const [uniqueColors, setuniqueColors] = useState([], "");


    // Filter features
    const [ searchValue, setSearchValue ] = useState("");
    const [ color, setColor ] = useState("");
    const [ brand, setBrand ] = useState("");
    const [ promotion, setPromotion] = useState("");
    
    useEffect(() => {
        async function init(id) {
            try {
                setLoading(true);
                
                let getUrl = `/api/product/category/${id.toLowerCase()}`;
                setTitle(capitalizeFirstCharacter(id));
                //console.log(getUrl);
                const response = await Axios(getUrl);
                if (response.status === 200) {
                    //console.log(response.data);
                    setProducts(response.data.products);
                    setuniqueBrands([...new Set(response.data.products.map(product => product.brand))]);
                    const getuniqueColors = ([...new Set(response.data.products.map(product => product.skus.find((s) => s.color)))]);
                    setuniqueColors([...new Set(getuniqueColors.map(skus => skus.color))]);
                } else {
                    throw response;
                }
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
            setLoading(false);
        }
        init(id, 0);
    }, [id]);

    function setItemCount(addcnt) {
        let itemCount = 0;
        let itemAddCount = (isNaN(addcnt) ? 0 : parseInt(addcnt));
        itemCount = parseInt(sessionStorage.getItem("cartitems"));
        if (isNaN(itemCount)) {
            sessionStorage.setItem("cartitems", itemAddCount.toString());
        }
        else {
            itemCount += itemAddCount;
            sessionStorage.setItem("cartitems", itemCount.toString());
        }
    }

    function capitalizeFirstCharacter(str) {
        if (!str) return str; // Return the original string if it's falsy (e.g., null, undefined, empty)
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

    async function AddToCart(e) {
        e.preventDefault();
        let request = { productId: Number(e.target.value), quantity: Number(1), action: "P" };
        let sessionId = sessionStorage.sessionId;
        if (sessionId) { request.sessionId = sessionId; }

        let result = await Axios.post('/api/cart', request);
        sessionStorage.sessionId = result.data.sessionId;
        setItemCount(1);
        
        toast.info('(weer) een artikel toegevoegd', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    }


    function ViewCategoryProducts() {
        return (
          <Row>
            { paginatedProducts.map((p) => (
                <Card key={p.id} className="productcard">
                <Link to={`/product/${p.id}`} style={{ textDecoration: 'none', color: 'black' }}>
                  <div className="text-center">
                    <CardImg alt={p.name} src={p.images[0]} top />
                  </div>
                  <CardBody>
                    <CardTitle tag="h5" className="product-title"><b>{p.name}</b></CardTitle>
                    <CardText className="product-description">{p.description}</CardText>
                    </CardBody>
                </Link>
                {/* Button is positioned absolutely */}
                <Button
                  color={p.promotion === 1 ? "warning" : "primary"}
                  className="m-0" // Remove margin if it was set before
                  value={p.id}
                  onClick={(e) => { AddToCart(e); }}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '90%'
                  }}
                >
                  <MdAddShoppingCart />{" "}Toevoegen{" "}<MdTrendingFlat />{" "}€{" "}
                  {Number(p.price).toFixed(2)}
                  
                </Button>
              </Card>
            
            ))}
          </Row>      
        );
      }
      
    const complexFiltered = data => {
        if (searchValue && !data.name.toLowerCase().includes(searchValue)) return false;
        //if (category && data.category !== category) return false;
        if (brand && data.brand !== brand) return false;
        if (promotion && data.promotion !== (promotion === "true" ? 1 : 2)) return false;
        if (color && data.skus.find((s) => s.color !== color)) return false;
        return data.active !== 0;
    } 

    const filteredProducts = products.filter(complexFiltered);

    const paginatedProducts = filteredProducts.sort(function (a, b) {
        if (a.sortingOrder < b.sortingOrder) { return 1; }
        if (a.sortingOrder > b.sortingOrder) { return -1; }
        return 0;
    });

    if (error) throw error;
    if (loading) return <Spinner />;



    return (
        <div>
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} />

            <Row >
                <h2 ><MdBusiness size="35" />{" "}{title}</h2><br />
                <p><b><i>TOPO Schoonmaak</i></b><i> Groothandel's middelen, materialen, machines en diversen andere artikelen voor al uw schoonmaak activiteiten.</i></p>
            </Row>
            <Row className="custom-col">
                <Row>
                    <Col>
                            <p><b>Zoeken</b></p>
                            <input id="searchValue" type="text" title="" value={searchValue} onChange={e => { setSearchValue(e.target.value.toLowerCase()); }} />
                    </Col>
                    <Col>
                            <p><b>Merken</b></p>
                            <select id="brand"
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <option value="">Alle merken</option>
                                {uniqueBrands.map((brand, key) => (
                                    <option key={key} value={brand}>{brand}</option>
                                ))}
                            </select>
                    </Col>
                    <Col>
                        <p><b>Kleuren</b></p>
                        <select id="color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                        >
                            <option value="">Alle kleuren</option>
                            {uniqueColors.map((color, key) => (
                                <option key={key} value={color}>{color}</option>
                            ))}
                        </select>
                    </Col>
                    <Col>
                        <p><b>Aanbieding</b></p>
                        <select id="promo"
                            value={promotion}
                            onChange={(e) => setPromotion(e.target.value)}
                        >
                            <option value="">Alle artikelen</option>
                            <option value="true">Ja</option>
                            <option value="false">Nee</option>
                        </select>
                    </Col>
                    <br />
                </Row>
                <Row >
                    <Col>
                        <p>({filteredProducts.length} gevonden artikelen). Alle aanbiedingsartikelen zijn zolang de voorraad strekt. <b>Alle aangegeven prijzen zijn exclusief BTW.</b> </p>
                    </Col>
                </Row>
                
                
            </Row>
           
            <Row  className="custom-col">
                <Row>
                    <br />
                </Row>
                {(filteredProducts.length === 0) ?
                    <Row className="custom-row"><h5>Oeps. We hebben hiervoor geen artikelen gevonden.</h5> </Row>
                    :
                    <ViewCategoryProducts />
                }
                <Row>
                    <br />
                </Row>
            </Row>
            <Row>
                    <br />
                    <br />
                    <br />
            
            </Row>
        </div>
    );
}
