import React from "react";
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import NothingInCartImage from '../../assets/images/empty-search.jpg';
import { ProductCategoryListSmall } from '../Products/ProductCategoriesListSmall';
//'../Products/ProductCategoryListSmall';
import '../../custom.css';

export default function EmptyCart() {

    return (
        <Col className="custom-col">
            <Row>
                <br />
            </Row>
            <Row className="component-center">
                <Col className="component-center">
                    <h4>Geen producten in uw mandje.</h4>
                    
                </Col>
            </Row>
            <Row>
                <Col className="component-center">
                    <p><i> Hier vindt u de koppelingen naar onze aanbiedingen, producten en diensten.</i></p>
                    <br />
                </Col>
            </Row>
            <Row>
                
                <Col className="component-center">
                    <img src={NothingInCartImage} className="img-fluid center" alt="NothingInCartImage" />
                </Col>
                
            </Row>
            <Row>
                <br />
            </Row>
            
            <Row>
                < ProductCategoryListSmall />
                <Col className="component-center">
                    <p> </p>   
                </Col>
            </Row>
        </Col>
    );
}


/*

<Row className="component-center">
                <Col className="component-center">
                    <Link to="/products"><Button color="primary">Zoeken op producten in de TOPO shop</Button></Link>
                </Col>
                
            </Row>
*/