import React, { useState, useEffect } from "react";
import { Col, Button, Alert} from 'reactstrap';
import MainUserContactClass from './MainUserContactClass';
import MainUserContactpwd from './MainUserContactpwd';
import { toast } from 'react-toastify';
import Axios from '../../context/axios';


export default function MainUserContact(props) {
    //const [isError, setIsError] = useState(false);
    //const [error, setError] = useState(false);
    const [isUNPWError, setUNPWError] = useState(false);
    const [isNSPWError, setNSPWError] = useState(false);
    const [contact, setContact] = useState([]);
    const [chgpwd, setChgPwd] = useState(false);
    const [conpw, setConPW] = useState([]);
    const [editmode, setEditMode] = useState(false);
    const [currentContact, setCurrentContact] = useState([]);
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));

    useEffect(() => {
        setContact(props.cactdata);
    }, [props.cactdata]);

    const selectContactData = (e) => {
        let contactstring = contact.firstName;
        setCurrentContact(contact);
        toast.info(`Contactgegevens van ${contactstring} geselecteerd.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
    };


    async function onCancelContactData(e) {
        toast.info(`Klantregistratie gegegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        setCurrentContact([]);
    };


    async function UpdatePassword(e) {
        setUNPWError(false); setNSPWError(false);

        if (userDetails.username === conpw.newfirst)
        {
            setUNPWError(true);
        }
        if (conpw.newfirst !== conpw.newlast)
        {
            setNSPWError(true);
        } 

        if ((isUNPWError === false) && (isNSPWError === false)) {
            
            //check code in registering a new users with the checks there and add them here.
            const data = { "content": userDetails.username, "curpw": conpw.existing, "newpw": conpw.newfirst, "token": `Bearer ${userDetails.jwttoken}` };
            let axiosConfig = { headers: { "Authorization": `Bearer ${userDetails.jwttoken}` } };
            const response = await Axios.post('api/maintain/updatepassword', data, axiosConfig);
            if (response.status === 200) {
                toast.info('Gebruiker is een email gestuurd met een nieuw wachtwoord.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
            else {
                toast.error('Wijzigen van het wachtwoord is mislukt. Probeer het aub opnieuw.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            }
        }
    };
  
    async function onUpdateContactData(e) {
        e.preventDefault();

        try {
            let updatedData = JSON.stringify({ currentContact });
            var data = {
                plu: updatedData,
                sessionId: sessionStorage.sessionId,
                username: userDetails.username,
                token: userDetails.jwttoken,
                action: 'TU'
            }


            let sessionId = sessionStorage.sessionId;
            if ((sessionId !== null) && (userDetails !== null)) {
                sessionStorage.sessionId = sessionId;
                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                let result = await Axios.post('/api/prefuser', data, { headers: { "Authorization": `Bearer ${ud}` } });
                if (result.status === 200) {
                    toast.info(`Contactgegevens bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                } else {
                    toast.error(`Contactgegevens niet bijgewerkt`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            }

            setContact(currentContact);
            setCurrentContact([]);

        } catch (ex) {
            console.log(ex);
            toast.error(`Er is een fout opgetreden bij het opslaan van Contactgegevens.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        }

    };

    function handleCustomerDetailsUpdated(newContactDetails) {
        setCurrentContact(newContactDetails);
    }

    function handlePWDetailsUpdated(newPWDetails) {
        setConPW(newPWDetails);
    }
    
    return (
        <Col>
            <br />
            
            <h5>Beschikbare klant registraties  </h5>
            { !contact &&
                <h6>Geen data gevonden</h6>
            }
            { contact &&
            <table border={2} cellPadding={5}>
                <thead>
                    <tr>
                        <td><b>Voornaam</b></td>
                        <td><b>Achternaam</b></td>
                        <td><b>Email</b></td>
                        <td><b>Telefoon Nummer</b></td>
                        <td><b>Functies</b></td>
                    </tr>
                </thead>
                <tbody >
                    <tr>
                        <td>{contact.firstName}</td>
                        <td>{contact.lastName}</td>
                        <td>{contact.email}</td>
                        <td>{contact.phone}</td>
                            <td><Button color="warning" value={contact.id} disabled={editmode} size="sm" onClick={(e) => { selectContactData(e); setEditMode(true); }}>Aanpassen</Button></td>
                    </tr>
                </tbody>
            </table>
            }
            <br />

            {currentContact.length !== 0 &&
                <MainUserContactClass contactdata={currentContact} onChanged={handleCustomerDetailsUpdated} />
            }
            {currentContact.length !== 0 &&
                <Col>
                    <Button color="primary" onClick={(e) => { onCancelContactData(e); setEditMode(false); }} >Annuleren</Button>
                    {" "}
                    <Button color="primary" onClick={(e) => { onUpdateContactData(e); setEditMode(false); }} >Opslaan klant registratie</Button>
                </Col>
                
            }

            { (!chgpwd && currentContact.length === 0) &&
                <Button color="primary" onClick={(e) => { setChgPwd(true); }} >Wijzigen Wachtwoord</Button>
            }
            
            { chgpwd &&
                <Col>
                    <MainUserContactpwd onChanged={handlePWDetailsUpdated} />
                    <Button color="primary" onClick={(e) => { UpdatePassword(e) }} >Doorvoeren wijziging</Button> 
                </Col>
            }
            {(isNSPWError === true) &&
                <Col>
                    <br />
                    <Alert color="danger">Nieuwe wachtwoorden zijn niet gelijk.</Alert>
                </Col>
                }
            {(isUNPWError === true) &&
                <Col>
                    <br />
                    <Alert color="danger">Uw gebruikersnaam en wachtwoord mag niet gelijk zijn.</Alert>
                </Col>
            }
            
        </Col>
    );

}
/* 
 
 */