import React, { useState, useEffect } from "react";
import { Col, Row, Table, Button, Label, Collapse } from 'reactstrap';
import Axios from '../../context/axios';
import { MdContentCopy, MdShoppingCart } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../../components/Spinner';

export default function HistoricalOrdersList() {
    const [userDetails] = useState(JSON.parse(sessionStorage.getItem("tokens")));
    //const [portalOverride, setPortalOverride] = useState(JSON.parse(sessionStorage.getItem("portalswitch")));
    const [prefOrders, setprefOrders] = useState([]);
    //const [orderList, setOrderList] = useState([]);
    //const [responseData, setResponseData] = useState([]);
    //const [copiedOrderList, setCopiedOrderList] = useState(false);
    const [copiedOrder, setCopiedOrder] = useState(false);
    const [loading, setLoading] = useState(true);

    
    // Tokenrefresh... an example.
    // https:/ / stackoverflow.com / questions / 51646853 / automating - access - token - refreshing - via - interceptors -in -axios / 66288792#66288792

    useEffect(() => {
        async function init() {
            try {
                setLoading(false);

                let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
                const xd = JSON.parse(sessionStorage.getItem("tokens")).username;
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
                let runUrl = '/api/prefadmin/preord/' + xd;
                const response = await Axios.get(runUrl, axiosConfig);
                if (response.status === 200) {
                    setprefOrders(response.data);
                    //setLoading(false);
                }
            } catch (e) {
            } finally {
            }
        }
        init();
    }, []);

/*
    useEffect((e) => {
        setLoading(true);
        if (responseData !== undefined) {
            setOrderList(responseData.templateOrders);
            //setInfoX(responseData.info);
            setLoading(false);
        }
    }, [responseData]);
*/




    // TODO to fix the part below!!!!
    async function onCopyToBasket(e) {
        setCopiedOrder(true);
        let orderNo = e.target.value;

        if (orderNo !== undefined) {
            console.log(`Copy order ${orderNo} to cart.`);

            var data = {
                Order: orderNo,
                SessionId: sessionStorage.sessionId,
                Action: 'H'
            }
            callOrderFunction(`Historische order ${orderNo}`, data);

        } else {
            setCopiedOrder(false);
        }
        setCopiedOrder(false);
    }

    async function callOrderFunction(name, data) {
        try {
            let ud = JSON.parse(sessionStorage.getItem("tokens")).jwttoken;
            let axiosConfig = { headers: { "Authorization": `Bearer ${ud}` } };
            let sessionId = sessionStorage.sessionId;

            if ((userDetails !== null)) {
                let actionname = "gekopieerd";

                sessionStorage.sessionId = sessionId;
                let result = await Axios.post('/api/cart/order', data, axiosConfig);
                if (result.status === 200) {
                    sessionStorage.sessionId = result.data.sessionId;
                    toast.info(`${name} ${actionname}.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                } else {
                    toast.error(`${name} niet ${actionname}.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
                }
            }
        } catch (ex) {
            console.log(ex);
            let actionname = "Probleem bij het kopieeren van";
            toast.error(`${actionname} ${name}.`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        }
    };



    // Functions belonging to : HistoricalGroupOrdersList
    function ProtectedReduceItems(data) {
        let result = [];
        if (data) { result = data.reduce((total, item) => total + item.items, 0); }
        return result;
    }

    function ProtectedReduceTotals(data) {
        let result = [];
        if (data) { result = data.reduce((total, item) => total + item.total, 0); }
        return result;
    }

    function ProtectedFilteredOrders(data) {
        let result = [];
        if (data) {
            result = data
        }
        return result;
    }

    const filteredOrders = ProtectedFilteredOrders(prefOrders);
    const numItemsInSelection = ProtectedReduceItems(filteredOrders);
    const TotalCostItemsInSelection = ProtectedReduceTotals(filteredOrders);
   
    const [toggleQuestion, setToggequestion] = useState(0); //1 is the default id to be opened by default


    return (
        <div>
            <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} />
            <Row>
                <p>Hieronder vindt U de laatste bestellingen die bij TOPO zijn gedaan. Klik op de regel voor de details van de bestelling.<br />Achter iedere regel staat een knop waarmee u de bestelling inhoud kunt inkijken.</p>
            </Row>
            <Row >
                <Col md={2}><Label><b>Order Nummer</b></Label></Col>
                <Col md={3}><Label><b>Besteldatum</b></Label></Col>
                <Col md={1}><Label><b>Artikel(en)</b></Label></Col>
                <Col md={2}><Label><b>Totaal in EUR</b></Label></Col>
                <Col md={3}><Label><b>Acties</b></Label></Col>

            </Row>
            {(loading) && <Spinner /> }
            {filteredOrders.map((ord) => (
                <Row key={ord.id}>
                    <Col md={2}><Label>{ord.orderNumber}</Label></Col>
                    <Col md={3}><Label>{ord.orderDate}</Label></Col>
                    <Col md={1}>{ord.items}</Col>
                    <Col md={2}><Label>{Number(ord.total).toFixed(2)}</Label></Col>
                    <Col md={3}><Button color="primary" size="sm" onClick={() => {
                        if (Number(ord.id) === toggleQuestion) { setToggequestion(0); }
                        else { setToggequestion(Number(ord.id)); }
                    }}>{toggleQuestion !== Number(ord.id) ? "Toon details" : "Verberg details"}</Button>{" "}
                        <Button color="warning" size="sm" value={ord.orderNumber} disabled={copiedOrder} onClick={(e) => { onCopyToBasket(e) }} ><MdContentCopy /> naar <MdShoppingCart /></Button>
                    </Col>

                    <Collapse className="orderdetails" isOpen={toggleQuestion === Number(ord.id) ? true : false}>
                        <Col >

                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>PLU / Naam</th>
                                        <th>Aantal</th>
                                        <th>Prijs</th>
                                        <th>Totaal in EUR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ord.orderLine.map((item) => (
                                        <tr key={item.id} >
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{`${Number(item.items).toFixed(0)}`}</td>
                                            <td>{`${Number(item.price).toFixed(2)}`}</td>
                                            <td>{`${Number(item.subtotal).toFixed(2)}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <p>
                                {(ord.notes.length > 1) && < i ><b>Order notities:</b><br />{ord.notes}</i>}
                                <br />
                                <i><b>Besteld door:</b> {ord.orderer}<br />
                                    <b>Factuuradres:</b> {ord.orderBilling}<br />
                                    <b>Bezorgadres:</b> {ord.orderDelivery}</i></p><br />
                        </Col>
                    </Collapse>
                </Row>
            ))}
            
            <Row >
                <Col md={2}></Col>
                <Col md={2}><Label><b>Totalen over alle orders</b></Label></Col>
                <Col md={1}></Col>
                <Col md={1}><Label><b>{`${Number(numItemsInSelection).toFixed(0)}`}</b></Label></Col>
                <Col md={2}><Label><b>{`${Number(TotalCostItemsInSelection).toFixed(2)}`} EUR</b></Label></Col>
                <Col md={2}></Col>
            </Row>
        </div>
    );
}
